import { ApiErrorInternalCode } from 'store/api'
import { TranslationKeys } from 'localisation/translations'

// TODO: needs localisation
export const getDefaultErrorMessage = (supportEmail: string) =>
  `We are sorry, but we've had an unexpected problem. Please notify our support team at ${supportEmail} if this keeps happening.`
export const DEFAULT_SUPPORT_EMAIL = 'support@pactum.com'

type ErrorKeys = ApiErrorInternalCode | 'NETWORK_ERROR' | 'SERVER_ERROR' | 'NETWORK_ERROR_SHORT'
export const ERROR_TRANSLATION_KEYS: Record<ErrorKeys, TranslationKeys> = {
  STATE_STALE: 'err_state_stale',
  STATE_MISSING: 'err_state_missing',
  NEGOTIATION_EXPIRED: 'err_negotiation_expired',
  NEGOTIATION_DELETED: 'err_negotiation_deleted',
  NEGOTIATION_NOT_FOUND: 'err_negotiation_not_found',
  NETWORK_ERROR_SHORT: 'err_network_error_short',
  NETWORK_ERROR: 'err_network_error',
  SERVER_ERROR: 'err_server_error',
  ACCESS_KEY_INVALID: 'err_access_key_invalid',
} as const
