import { createSlice } from '@reduxjs/toolkit'
import { baseApi, ChatParamsResponse } from '../api'

const chatParamsSlice = createSlice({
  name: 'chatParams',
  initialState: null as ChatParamsResponse | null,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(baseApi.endpoints.getChatParams.matchFulfilled, (state, action) => {
        return action.payload
      })
      .addMatcher(baseApi.endpoints.getContactConfirmation.matchFulfilled, (state, action) => {
        return action.payload.negotiationParams
      })
      .addMatcher(baseApi.endpoints.getNegotiationParams.matchFulfilled, (state, action) => {
        return action.payload.chatParams
      })
  },
})

export default chatParamsSlice.reducer
