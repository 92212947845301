import React, { KeyboardEvent } from 'react'
import { Typography } from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import { SubmitButton } from '@containers/ChatInput/SubmitButton'
import { ErrorMessage, SimpleInputContainer, StyledTextField } from './ChatInput'
import { SubmitButtonWithProgress } from './SubmitButtonWithProgress'
import { ChatDataTestId } from '@typedef/testIDs'
import { SimpleTextUserInputStep } from '@typedef/chatSteps/SimpleUserInputStep'
import useTranslations from '../../localisation/useTranslations'
import { TEXT_INPUT_CHAR_LIMIT, TEXTAREA_INPUT_CHAR_LIMIT } from '@constants/input'

interface TextInputProps {
  inputValue: string
  setInputValue: React.Dispatch<React.SetStateAction<string>>
  sendValue: () => void
  lastChatObject: SimpleTextUserInputStep
  error?: string
}

export const TextInput = ({
  inputValue,
  setInputValue,
  sendValue,
  lastChatObject,
  error,
}: TextInputProps) => {
  const localise = useTranslations()
  const { format, properties } = lastChatObject

  const isTextareaInput = format === 'textarea'

  const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      if (isTextareaInput) {
        return
      }

      const minChars = properties?.minChars ?? 0
      if (format === 'text' && minChars > 0 && inputValue.length < minChars) {
        return
      }

      sendValue()
    }
  }

  const SubmitButtonComponent = () => {
    const minChars = properties?.minChars ?? 0

    if (minChars > 0) {
      return (
        <SubmitButtonWithProgress
          inputValue={inputValue}
          onSubmit={sendValue}
          format={format}
          minChars={minChars}
        />
      )
    }

    return (
      <SubmitButton onClick={sendValue}>
        {isTextareaInput ? localise('textareaSubmitBtnText') : <SendIcon />}
      </SubmitButton>
    )
  }

  const maxCharLimit =
    properties?.maxChars ?? (format === 'text' ? TEXT_INPUT_CHAR_LIMIT : TEXTAREA_INPUT_CHAR_LIMIT)

  return (
    <>
      {!!error && <ErrorMessage text={error} />}
      <SimpleInputContainer>
        <StyledTextField
          value={inputValue}
          onChange={(e) => {
            if (e.target.value.length <= maxCharLimit) {
              setInputValue(e.target.value)
            }
          }}
          variant='standard'
          fullWidth
          type='text'
          inputProps={{
            inputMode: 'text',
            'data-testid': ChatDataTestId.TEXT_INPUT,
          }}
          InputProps={{
            disableUnderline: true,
          }}
          placeholder={localise('typeAText')}
          onKeyPress={handleKeyPress}
          error={!!error}
          autoFocus={true}
          {...(isTextareaInput
            ? {
                multiline: true,
                maxRows: 2,
              }
            : {})}
        />
        <Typography
          variant='caption'
          color='text.secondary'
          pl={2}
        >{`${inputValue.length}/${maxCharLimit}`}</Typography>
        <SubmitButtonComponent />
      </SimpleInputContainer>
    </>
  )
}
