import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Box, styled } from '@mui/material'
import Chat from '@pages/Chat'
import NoRoute from '@components/NoRoute/NoRoute'
import { chatRoutes } from '@constants/routes'
import { AuthErrorPage } from '@pages/index'
import { useInitializeAuth } from 'store/authHooks'
import { AuthStatus } from 'store/slices/auth'
import { ContactConfirmationPage } from '@pages/ContactConfirmation'
import { DeadlineExtensionPage } from '@pages/DeadlineExtensionPage'
import { PactumLoader } from '@components/Icons/PactumLoader'

const Routes = (): JSX.Element => {
  const { authStateInitialized, authStatus } = useInitializeAuth()

  if (!authStateInitialized) {
    return (
      <LoaderWrapper>
        <PactumLoader />
      </LoaderWrapper>
    )
  }

  if (authStatus === AuthStatus.ERROR) {
    return <AuthErrorPage />
  }

  return (
    <Switch>
      {chatRoutes.map((route, index) => (
        <Route key={`link-route-${index}`} path={route.path}>
          <Chat />
        </Route>
      ))}
      <Route path='/contact-confirmation/:uuid'>
        <ContactConfirmationPage />
      </Route>
      <Route path='/extend-deadline/:uuid'>
        <DeadlineExtensionPage />
      </Route>
      <Route>
        <NoRoute />
      </Route>
    </Switch>
  )
}

const LoaderWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(10),
  height: 'calc(97vh - 112px)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export default Routes
