import { useCallback } from 'react'
import { defaultTranslations, isSupportedLocale, TranslationKeys } from './translations'
import { useChatParams } from 'store/selectors'
import { reportError } from 'utils/errorReporting'
import { defaultLocale } from '@constants/settings'

const useTranslations = () => {
  const chatParams = useChatParams()

  return useCallback(
    (key: TranslationKeys, values?: Record<string, string | number>): string => {
      let locale = defaultLocale
      if (chatParams) {
        if (isSupportedLocale(chatParams.locale)) {
          locale = chatParams.locale
        } else {
          reportError(
            `Locale ${chatParams.locale} is not supported. Defaulting to ${defaultLocale}`,
          )
        }
      }

      let translation: string =
        chatParams?.localisation?.[key] ??
        chatParams?.localisation?.[locale]?.[key] ??
        defaultTranslations[locale][key]

      if (values !== undefined) {
        Object.entries(values).forEach(([key, value]) => {
          translation = translation.replace(`{${key}}`, value.toString())
        })
      }

      return translation
    },
    [chatParams],
  )
}

export default useTranslations
