import React from 'react'
import { Provider } from 'react-redux'
import { matchPath, Router } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { CssBaseline } from '@mui/material'
import { LicenseInfo } from '@mui/x-license'

import { environment } from 'environments/environment'
import Routes from './containers/Routes'
import { PactumThemeProvider } from '@containers/PactumThemeProvider'
import { FullPageError } from '@components/FullPageError/FullPageError'
import store from './store'
import browserHistory from './utils/browserHistory'
import './App.css'
import './webComponents/index'
import { chatRoutes } from '@constants/routes'
import { AppEnvEnum } from '@typedef/Environment'
import { initializePosthog } from '@utils/posthog'
import { sentryDsn } from '@constants/sentry'
import { DEFAULT_SUPPORT_EMAIL } from '@constants/errors'

if (environment.REACT_APP_ENV !== AppEnvEnum.DEV) {
  Sentry.init({
    dsn: sentryDsn,
    environment: environment.REACT_APP_ENV,
    release: environment.REACT_APP_SENTRY_RELEASE_VERSION,
    integrations: [
      Sentry.reactRouterV5BrowserTracingIntegration({
        history: browserHistory,
        routes: chatRoutes,
        matchPath,
      }),
    ],
    tracesSampleRate: 1,
  })
}

initializePosthog()

LicenseInfo.setLicenseKey(
  '217e15c0c57af2c77ae7088a42ddbde7Tz05NjQzNCxFPTE3NTU2ODA2MzQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPWluaXRpYWwsS1Y9Mg==',
)

const App = (): JSX.Element => {
  return (
    <React.StrictMode>
      <Sentry.ErrorBoundary fallback={<FallbackPage />}>
        <Provider store={store}>
          <PactumThemeProvider>
            <CssBaseline>
              <Router history={browserHistory}>
                <Routes />
              </Router>
            </CssBaseline>
          </PactumThemeProvider>
        </Provider>
      </Sentry.ErrorBoundary>
    </React.StrictMode>
  )
}

const FallbackPage = () => (
  <FullPageError title={'Something went wrong'} supportEmail={DEFAULT_SUPPORT_EMAIL}>
    <p>
      There was an unexpected error while loading the page. Pactum team was notified and is working
      on it.
    </p>
  </FullPageError>
)

export default Sentry.withProfiler(App)
