export enum AppEnvEnum {
  PROD = 'production',
  STAGING = 'staging',
  SANDBOX = 'sandbox',
  DEV = 'development',
}

export type Environment = {
  NODE_ENV: string
  REGION: 'eu' | 'us' | 'local'
  REACT_APP_ENV: AppEnvEnum
  REACT_APP_API_URL: string
  REACT_APP_AUTH0_DOMAIN: string
  REACT_APP_AUTH0_CLIENT: string
  REACT_APP_AUTH0_BACKEND_API: string
  REACT_APP_ACCESS_TOKEN_COOKIE: string
  REACT_APP_NEGOTIATION_DESIGNER_API_BASE_URL: string
  REACT_APP_SENTRY_RELEASE_VERSION?: string
  REACT_APP_TURNSTILE_SITE_KEY: string
}
