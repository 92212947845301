import React from 'react'
import { FormControl } from '@mui/material'
import { ChatDataTestId } from '@typedef/chatSteps'
import { StyledTextField } from './StyledTextField'
import {
  EMAIL_INPUT_CHAR_LIMIT,
  PHONE_INPUT_CHAR_LIMIT,
  TEXT_INPUT_CHAR_LIMIT,
} from '@constants/input'

interface Props {
  variableKey: string
  value?: number | string | null
  setValue: (variable: string, value: string | number) => void
  error?: boolean
  isReadOnly: boolean
  type: 'text' | 'email' | 'phone'
}

export const TextInput = ({
  value,
  setValue,
  variableKey,
  error,
  isReadOnly,
  type,
}: Props): JSX.Element => {
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const charLimit =
      type === 'text'
        ? TEXT_INPUT_CHAR_LIMIT
        : type === 'email'
        ? EMAIL_INPUT_CHAR_LIMIT
        : PHONE_INPUT_CHAR_LIMIT
    if (e.target.value.length <= charLimit) {
      setValue(variableKey, e.target.value)
    }
  }

  return (
    <FormControl data-testid={ChatDataTestId.TEXT_INPUT}>
      <StyledTextField
        textInput
        required={false}
        type='text'
        value={value ?? ''}
        onChange={onInputChange}
        error={error}
        disabled={isReadOnly}
        inputProps={{ 'aria-label': 'Text input label' }}
        variant='outlined'
      />
    </FormControl>
  )
}
