import { useLayoutEffect, useMemo, useRef } from 'react'
import debounce from 'lodash/debounce'

export const useDebounce = (callback: Function, delay: number) => {
  const callbackRef = useRef(callback)
  useLayoutEffect(() => {
    callbackRef.current = callback
  })

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return useMemo(() => debounce((...args) => callbackRef.current(...args), delay), [delay])
}
