import React, { useEffect, useMemo, useState } from 'react'

import { useAppSelector } from 'store'
import { useChatParams } from 'store/selectors'
import ChatProgressItemSelector, { ChatProgressItemSelectorProps } from './ChatProgressItemSelector'
import { ChatLayoutVersion } from '@pactum/core-backend-types'

const ChatProgress = ({
  layoutVersion = ChatLayoutVersion.WIDE,
}: ChatProgressItemSelectorProps): JSX.Element | null => {
  const [currentStageIndex, setCurrentStageIndex] = useState(0)

  const chatStage = useAppSelector((state) => state.chat.chatStage)
  const chatParams = useChatParams()
  const stages = useMemo(() => chatParams?.stages ?? [], [chatParams])

  useEffect(() => {
    if (chatStage) {
      const newIndex = stages.findIndex((stage) => stage.id === chatStage)
      setCurrentStageIndex(newIndex === -1 ? 0 : newIndex)
    }
  }, [stages, chatStage])

  if (!chatParams?.featureFlags?.showProgress) {
    return null
  }

  return (
    <>
      {stages.map((stage, index) => {
        const isLast = index === stages.length - 1
        return (
          <ChatProgressItemSelector
            key={`stage-${index}`}
            index={index}
            currentIndex={currentStageIndex}
            isLast={isLast}
            layoutVersion={layoutVersion}
          >
            {stage.label}
          </ChatProgressItemSelector>
        )
      })}
    </>
  )
}

export default ChatProgress
