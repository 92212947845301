import React from 'react'
import { Box, Stack, styled, Typography, Grid, GridProps } from '@mui/material'

import { ChatProgressItemProps } from './ChatProgressItemSelector'

export const ChatProgressItemV2 = ({
  index,
  currentIndex,
  isLast = false,
  children,
}: ChatProgressItemProps): JSX.Element => {
  const notVisitedItem = index > currentIndex
  const isPreviousItem = index < currentIndex
  const isActiveItem = index === currentIndex

  return (
    <Grid container>
      <ProgressItem container firstItem={index === 0} lastItem={isLast}>
        <Stack direction='row' alignItems='center'>
          <Icon isPreviousItem={isPreviousItem} isActiveItem={isActiveItem} />
        </Stack>
        <StageLabel
          sx={{
            ...(notVisitedItem ? { opacity: 0.4 } : {}),
            ...(isActiveItem ? { fontWeight: 'bold', color: 'primary.main' } : {}),
          }}
        >
          {children}
        </StageLabel>
      </ProgressItem>
    </Grid>
  )
}

const ProgressItem = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'firstItem' && prop !== 'lastItem',
})<GridProps & { firstItem: boolean; lastItem: boolean }>(({ theme, firstItem, lastItem }) => {
  const progressLineStyle = {
    backgroundColor: theme.palette.pactum.lightGray,
    zIndex: 1,
    content: '""',
    position: 'absolute',
    width: 1,
    height: 'calc(100% - 13px)',
    left: 6,
    bottom: 0,
  }

  return {
    flexWrap: 'nowrap',
    position: 'relative',
    paddingBottom: theme.spacing(2.5),
    '&:after': {
      ...progressLineStyle,
      top: 'calc((100% - 6px) / 2)',
    },

    '&:before': {
      ...progressLineStyle,
      bottom: 'calc((100% - 13px) / 2)',
    },
    '&:not(:last-child):after': {
      backgroundColor: 'blue',
    },

    ...(firstItem
      ? {
          '&:before': {
            content: 'none',
          },
        }
      : {}),
    ...(lastItem
      ? {
          minHeight: 'auto',
          paddingBottom: 0,
          '&:after': {
            content: 'none',
          },
        }
      : {}),
  }
})

const Icon = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isPreviousItem' && prop !== 'isActiveItem',
})<{ isPreviousItem: boolean; isActiveItem: boolean }>(
  ({ theme, isPreviousItem, isActiveItem }) => ({
    color: theme.palette.common.white,
    backgroundColor: theme.palette.pactum.lightGray,
    width: '13px',
    height: '13px',
    borderRadius: '50%',
    zIndex: 2,
    ...(isPreviousItem
      ? { color: theme.palette.primary.main, backgroundColor: theme.palette.pactum.gray }
      : {}),
    ...(isActiveItem
      ? { backgroundColor: theme.palette.primary.main, color: theme.palette.primary.main }
      : {}),
  }),
)

const StageLabel = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginBottom: 0,
  fontSize: '13px',
  lineHeight: '20px',
}))
