import {
  styled,
  TableCell,
  tableCellClasses,
  TableCellProps,
  TableContainer,
  TableRow,
} from '@mui/material'

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderBottom: '1px solid #f2f2f2',

  '&:hover': {
    backgroundColor: '#F7F7F7',
  },

  '&:last-child td, &:last-child th': {
    border: 0,
  },
  '&:last-child td': {
    paddingBottom: theme.spacing(1.5),
  },
}))

export const StyledTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'isCopyContentsEnabled',
})<TableCellProps & { isCopyContentsEnabled: boolean }>(({ theme, isCopyContentsEnabled }) => ({
  fontFamily: 'Inter, sans-serif',
  fontSize: 15,
  textAlign: 'left',
  padding: theme.spacing(1),

  '&:last-of-type': {
    paddingRight: isCopyContentsEnabled ? theme.spacing(4) : theme.spacing(2),
  },
  '&:first-of-type': {
    paddingLeft: theme.spacing(2),
  },

  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'var(--custom-chat-table-color)',
    color: theme.palette.common.white,
    fontWeight: 700,
  },
}))

export const StyledTableContainer = styled(TableContainer)({
  position: 'relative',
  backgroundColor: '#FFF',
  borderRadius: '9px',
  boxShadow: 'none',

  scrollbarWidth: 'thin',
  scrollbarColor: `#828282 #E7EAEB`,

  '&::-webkit-scrollbar': {
    height: '12px',
  },
  '&::-webkit-scrollbar-track': {
    borderRadius: '0px 0px 9px 9px',
    background: '#E7EAEB',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#828282',
    borderRadius: '9px',
    border: `3px solid #E7EAEB`,
  },
}) as typeof TableContainer
