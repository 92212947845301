import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { setCookie } from '@utils/cookies'
import { ACCESS_TOKEN_COOKIE } from '@constants/api'

interface AuthState {
  authStatus: AuthStatus
  accessToken: string | null
  error?: string
}

export enum AuthStatus {
  UNINITIALIZED = 'UNINITIALIZED',
  INITIALIZING = 'INITIALIZING',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  AUTHENTICATED = 'AUTHENTICATED',
  ERROR = 'ERROR',
}

const initialState: AuthState = {
  authStatus: AuthStatus.UNINITIALIZED,
  accessToken: null,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthStatus(state, action: PayloadAction<AuthStatus>) {
      state.authStatus = action.payload
    },
    setToken(state, action: PayloadAction<string>) {
      setCookie(ACCESS_TOKEN_COOKIE, action.payload)
      state.accessToken = action.payload
    },
    setAuthError(state, action: PayloadAction<string>) {
      state.authStatus = AuthStatus.ERROR
      state.error = action.payload
    },
  },
})

export const authActions = authSlice.actions

export default authSlice.reducer
