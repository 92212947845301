import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux'
import { configureStore, combineReducers, PreloadedState } from '@reduxjs/toolkit'
import { baseApi } from './api'
import authReducer from './slices/auth'
import chatReducer from './slices/chat'
import chatParamsReducer from './slices/chatParams'

const rootReducer = combineReducers({
  auth: authReducer,
  chat: chatReducer,
  chatParams: chatParamsReducer,
  [baseApi.reducerPath]: baseApi.reducer,
})

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(baseApi.middleware),
    preloadedState,
  })
}

const store = setupStore()

export default store

export type AppStore = ReturnType<typeof setupStore>
export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export interface AppAsyncThunkConfig {
  state: RootState
  dispatch: AppDispatch
}
