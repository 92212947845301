import React, { useState } from 'react'
import { styled, Stack, Typography, Button, Box } from '@mui/material'
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap'
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap'
import { format } from 'date-fns'

import VerifiedIcon from '../Icons/VerifiedIcon'
import { ChatDataTestId } from '@typedef/testIDs'
import useInterval from '../../hooks/useInterval'
import useTranslations from '../../localisation/useTranslations'
import { STATUS_BAR_HEIGHT } from '@constants/styles'

interface Props {
  alwaysShowPanels: boolean
  fullScreenMode: boolean
  setFullScreenMode: React.Dispatch<React.SetStateAction<boolean>>
}

const ChatStatusBar = ({
  alwaysShowPanels,
  fullScreenMode,
  setFullScreenMode,
}: Props): JSX.Element => {
  const [autosaveTime, setAutosaveTime] = useState(() => new Date())
  const localise = useTranslations()

  useInterval(() => setAutosaveTime(new Date()), 10_000)

  return (
    <Stack
      flexDirection='row'
      alignItems='center'
      px={2}
      sx={{
        height: `${STATUS_BAR_HEIGHT}px`,
        margin: '0 auto',
        borderBottom: (theme) => `1px solid ${theme.palette.grey['300']}`,
      }}
      gap={1}
      data-testid={ChatDataTestId.STATUS_BAR}
    >
      <Stack flexDirection='row' justifyContent='space-between' alignItems='center'>
        <VerifiedIcon sx={{ mr: 1, fontSize: 19 }} />
        <Typography variant='caption'>{localise('sslSecured')}</Typography>
      </Stack>
      <Box ml='auto'>
        <AutosaveText variant='overline'>
          {localise('autosaved', {
            time: format(autosaveTime, 'HH:mm'),
          })}
        </AutosaveText>
        {!alwaysShowPanels ? (
          <Button
            onClick={() => setFullScreenMode(!fullScreenMode)}
            startIcon={fullScreenMode ? <ZoomInMapIcon /> : <ZoomOutMapIcon />}
            sx={{ ml: 3, color: '#222222', fontSize: '0.625em' }}
            data-testid={ChatDataTestId.MINIMIZE_MAXIMIZE_CHAT_AREA_BUTTON}
          >
            {(fullScreenMode ? localise('minimize') : localise('maximize')).toUpperCase()}
          </Button>
        ) : null}
      </Box>
    </Stack>
  )
}

const AutosaveText = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '0.625em',
  color: theme.palette.grey['600'],
}))

export default ChatStatusBar
