import { ChatStepBase, StepType } from './index'
import { NumberInputProperties } from '@typedef/chatSteps/SimpleUserInputStep'

export interface MultiInputComponentStep extends ChatStepBase {
  type: StepType.MULTI_INPUT_STEP
  sections: MultiInputComponentSection[]
  rules: ValidationRuleExpression[]
  contentTitle: string | null
  submitLabel: string
  cancelLabel: string | null
  inputs: Record<string, MultiInputComponentInput>
  layout: MultiInputComponentLayout | null
  uiProperties?: {
    resetButton?: {
      label?: string
    }
  }
}

export enum MultiInputComponentLayout {
  DEFAULT = 'default',
  CUSTOM = 'custom',
}

export interface MultiInputComponentSection {
  text: string
  visibility?: VisibilityExpression
}

export type MultiInputComponentInput =
  | DropdownInputProps
  | NumberInputProps
  | SliderInputProps
  | TextInputProps
  | EmailInputProps
  | PhoneInputProps
  | DateInputProps

interface MultiInputComponentPropsBase {
  required?: boolean
}

type DropdownOptionValue = number | string | null
type DropdownOptionWithLabel = {
  label?: string
  value: DropdownOptionValue
}

export type DropdownOption = DropdownOptionValue | DropdownOptionWithLabel

export interface DropdownInputProps extends MultiInputComponentPropsBase {
  type: 'dropdown'
  default: DropdownOptionValue
  optionSuffix: string | null
  optionPrefix: string | null
  nullValueLabel?: string
  options: DropdownOption[]
}

export type NumberInputProps = MultiInputComponentPropsBase & {
  type: 'number'
  default: string | number | null
} & Omit<NumberInputProperties, 'type'>

export interface DateInputProps extends MultiInputComponentPropsBase {
  type: 'date'
  default: string | null
  min: string | null
  max: string | null
}

export interface SliderInputProps extends MultiInputComponentPropsBase {
  type: 'slider'
  min: number
  max: number
  default: string | number | null
  showValue: boolean | null
  stepSize: number | null
  showValuePostfix: string | null
  labels: (string | number)[] | null
}

export interface TextInputProps extends MultiInputComponentPropsBase {
  type: 'text'
  default: string | null
}

export interface EmailInputProps extends MultiInputComponentPropsBase {
  type: 'email'
  default: string | null
}

export interface PhoneInputProps extends MultiInputComponentPropsBase {
  type: 'phone'
  default: string | null
}

export type MultiInputComponentValidationError = {
  type: 'validation'
  message: string
}

export type MultiInputComponentEmptyError = {
  type: 'empty'
}

export type MultiInputComponentError =
  | MultiInputComponentValidationError
  | MultiInputComponentEmptyError

export type MultiInputComponentValues = Record<string, string | number | undefined | null>
export type MultiInputComponentErrors = Record<string, MultiInputComponentError>

export interface MultiInputComponentSubmitRequest {
  action: 'SUBMIT' | 'CANCEL'
  values: MultiInputComponentValues | null
}

export type Operand = number | string | null | undefined
export type DynamicOperatorFun = (arg1: Operand, arg2: Operand) => boolean
export type DynamicOperator = '<' | '<=' | '>' | '>=' | '=' | '!='

export type VisibilityExpression = [DynamicOperator, string | number | null, string | number | null]
export type ValidationRuleExpression = [
  DynamicOperator,
  string | number | null,
  string | number | null,
  string,
]
export type MultiInputExpression = VisibilityExpression | ValidationRuleExpression
