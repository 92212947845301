import React, { forwardRef } from 'react'
import {
  Box,
  Checkbox,
  styled,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'

import { Order, StyledTableCell } from './CheckboxTable'
import { Column } from '@typedef/chatSteps/MultiChoiceStep'
import { HTML } from '@components/HTML'

interface TableHeaderProps {
  columns: Column[]
  onSelectAllClick: (e: React.ChangeEvent<HTMLInputElement>) => void
  totalCount: number
  selectedCount: number
  onRequestSort: (event: React.MouseEvent, columnIndex: number) => void
  order: Order | null
  showSelectAll: boolean
}

export const TableHeader = forwardRef<HTMLTableSectionElement, TableHeaderProps>(
  (
    { columns, onSelectAllClick, totalCount, selectedCount, onRequestSort, order, showSelectAll },
    ref,
  ) => {
    return (
      <TableHead component='div' ref={ref}>
        <TableRow component='div'>
          <TableCell
            component='div'
            padding='checkbox'
            sx={{ backgroundColor: 'pactum.customChatTableColor' }}
          >
            {showSelectAll ? (
              <Checkbox
                color='primary'
                indeterminate={selectedCount > 0 && selectedCount < totalCount}
                checked={selectedCount === totalCount}
                onChange={onSelectAllClick}
                inputProps={{
                  'aria-label': 'select all rows',
                }}
                sx={{
                  color: 'common.white',
                  '&.Mui-checked, &.MuiCheckbox-indeterminate': { color: 'common.white' },
                }}
              />
            ) : null}
          </TableCell>
          {columns.map(({ headerName, width }, columnIndex) => {
            const customWidth = width ? { width, minWidth: width } : {}
            const activeColumn = order?.columnIndex === columnIndex
            return (
              <StyledTableCell
                key={headerName}
                sx={{ ...customWidth, lineHeight: '28px' }}
                component='div'
                sortDirection={order?.columnIndex === columnIndex ? order.direction : false}
              >
                <StyledTableSortLabel
                  active={activeColumn}
                  direction={activeColumn ? order.direction : 'asc'}
                  onClick={(e) => onRequestSort(e, columnIndex)}
                  IconComponent={ArrowDropUpIcon}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: '28px', m: 0, width: '26px' } }}
                  hideSortIcon
                >
                  <Box>
                    <HTML markup={headerName} />
                  </Box>
                  {!activeColumn ? (
                    <UnfoldMoreIcon
                      fontSize='small'
                      sx={{ '&.MuiSvgIcon-root': { fontSize: '18px', width: '26px' } }}
                    />
                  ) : null}
                </StyledTableSortLabel>
              </StyledTableCell>
            )
          })}
        </TableRow>
      </TableHead>
    )
  },
)

const StyledTableSortLabel = styled(TableSortLabel)(({ theme }) => ({
  '&:hover': {
    color: theme.palette.grey['200'],
  },

  '&.Mui-active': {
    color: theme.palette.common.white,
    '& .MuiTableSortLabel-icon': {
      color: theme.palette.common.white,
    },
  },
}))
