import { DataGridPro } from '@mui/x-data-grid-pro'
import { styled } from '@mui/material'

export const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  border: 'none',

  '& .MuiDataGrid-columnHeader': {
    backgroundColor: theme.palette.pactum.customChatTableColor,
  },

  '& .MuiDataGrid-topContainer': {
    color: 'white',

    '&:after': {
      display: 'none',
    },
  },

  '& .MuiDataGrid-filler': {
    display: 'none',
  },

  '& .MuiDataGrid-columnHeaderTitleContainer .MuiIconButton-root': {
    color: 'white',
  },

  '& .MuiDataGrid-columnSeparator--resizable:hover': {
    color: 'white',
  },

  '& .MuiDataGrid-cell': {
    borderTop: 'none',

    '&.MuiDataGrid-cell--editable': {
      outline: 'none',
    },

    '&.MuiDataGrid-cell--editing': {
      display: 'block',
      padding: '0 10px',
      backgroundColor: 'transparent',
      boxShadow: 'none',

      '&:focus-within': {
        outline: 'none',
      },
    },
  },
})) as typeof DataGridPro
