import { useCallback, useState } from 'react'

export const useLocalStorageState = <T>(key: string, initialValue: T) => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = localStorage.getItem(key)
      return item ? (JSON.parse(item) as T) : initialValue
    } catch (error) {
      console.log(error)
      return initialValue
    }
  })

  const setValue = useCallback(
    (value: T | ((val: T) => T)) => {
      try {
        const valueToStore = value instanceof Function ? value(storedValue) : value

        localStorage.setItem(key, JSON.stringify(valueToStore))
        setStoredValue(valueToStore)
      } catch (error) {
        console.log(error)
      }
    },
    [storedValue, key],
  )

  return [storedValue, setValue] as const
}
