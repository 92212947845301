import React from 'react'
import { Button } from '@mui/material'

import { ChatDataTestId } from '@typedef/testIDs'

interface Props {
  onClick: () => void
  disabled?: boolean
  children: React.ReactNode
}

export const SubmitButton = ({ onClick, disabled, children }: Props) => (
  <Button
    aria-label='send'
    data-testid={ChatDataTestId.CHAT_SUBMIT_BUTTON}
    disabled={disabled}
    color='primary'
    onClick={onClick}
    sx={{
      borderRadius: '0 0 10px 0',
      height: 56,
      transition: 'color 300ms ease',
    }}
  >
    {children}
  </Button>
)
