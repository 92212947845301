import React from 'react'
import { styled, Tooltip as MuiTooltip, tooltipClasses, TooltipProps } from '@mui/material'

export const PactumTooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip
    placement='top'
    arrow
    {...props}
    componentsProps={{
      popper: {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -6],
            },
          },
        ],
      },
    }}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFF',
    color: '#000',
    border: '1px solid #BFBFBF',
    maxWidth: 'none',
    fontSize: '13px',
    padding: theme.spacing(2),
  },

  [`& .${tooltipClasses.arrow}`]: {
    color: '#FFF',
    '&:before': {
      border: '1px solid #BFBFBF',
    },
  },
}))
