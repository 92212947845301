export const chatRoutes = [
  { path: '/link/:token/delay/:delay/stateId/:stateId/stepId/:stepId/stepCount/:stepCount' },
  { path: '/link/:token/delay/:delay/stateId/:stateId/stepId/:stepId' },
  { path: '/link/:token/delay/:delay/stateId/:stateId' },
  { path: '/link/:token/delay/:delay' },
  { path: '/link/:token/stateId/:stateId/stepId/:stepId/stepCount/:stepCount' },
  { path: '/link/:token/stateId/:stateId/stepId/:stepId' },
  { path: '/link/:token/stateId/:stateId' },
  { path: '/link/:token' },
]

interface BaseChatUrlParams {
  token: string
  delay?: string
  stateId?: string
  stepId?: string
  stepCount?: string
}

// when stepId is present, then stateId also has to be present
interface SnapshotUrlParams extends BaseChatUrlParams {
  stateId: string
  stepId: string
}

export type ChatUrlParams = BaseChatUrlParams | SnapshotUrlParams

export const BRANDING_QUERY_PARAM_NAME = 'brandingUuid'
