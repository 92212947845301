import React from 'react'
import { SvgIcon } from '@mui/material'

export const NegotiationDesignerIcon = () => {
  return (
    <SvgIcon fontSize='inherit' viewBox='0 0 16 16'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.43771 3.23755L6.64082 3.23755L6.42857 3.26841C6.07103 3.37605 5.75744 3.59615 5.534 3.89612C5.31056 4.19575 5.18909 4.55984 5.18749 4.93421L5.18749 5.79504C5.18801 6.2558 5.37068 6.69702 5.69544 7.02271C5.96803 7.29608 6.32204 7.46921 6.70033 7.51798L9.30937 7.51798C9.39107 7.50745 9.47164 7.49112 9.5505 7.46922L9.59975 7.46922C9.9573 7.36158 10.2709 7.14148 10.4943 6.84185C10.7178 6.54188 10.8392 6.1778 10.8408 5.80343L10.8408 4.94259C10.8403 4.48183 10.6576 4.04061 10.3329 3.71492C10.0868 3.46816 9.77443 3.30327 9.43771 3.23755ZM6.46744 4.16187C6.41015 4.20128 6.35468 4.2453 6.30871 4.28398C6.30411 4.2884 6.29956 4.29287 6.29505 4.29739C6.12441 4.46846 6.02844 4.70055 6.02817 4.94259L6.02814 5.97774C6.06373 6.15168 6.1494 6.31287 6.27645 6.44024C6.4471 6.61131 6.67847 6.70765 6.9198 6.70799L9.08991 6.70799C9.33126 6.70765 9.56262 6.61131 9.73325 6.44024C9.90389 6.26917 9.9999 6.03708 10.0002 5.79504L10.0002 4.75986C9.96458 4.58593 9.87892 4.42475 9.75186 4.29739C9.58122 4.12632 9.34986 4.02998 9.10852 4.02964L6.93842 4.02964C6.77098 4.02988 6.60834 4.07632 6.46744 4.16187Z'
        fill='#092330'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.3752 8.44995L10.5783 8.44995L10.3661 8.48081C10.0085 8.58846 9.69494 8.80855 9.4715 9.10853C9.24806 9.40816 9.12659 9.77224 9.12499 10.1466L9.12499 11.0074C9.12551 11.4682 9.30818 11.9094 9.63294 12.2351C9.90553 12.5085 10.2595 12.6816 10.6378 12.7304L13.2469 12.7304C13.3286 12.7199 13.4091 12.7035 13.488 12.6816L13.5372 12.6816C13.8948 12.574 14.2084 12.3539 14.4318 12.0543C14.6553 11.7543 14.7767 11.3902 14.7783 11.0158L14.7783 10.155C14.7778 9.69423 14.5951 9.25301 14.2704 8.92732C14.0243 8.68057 13.7119 8.51568 13.3752 8.44995ZM10.4049 9.37427C10.3476 9.41369 10.2922 9.4577 10.2462 9.49638C10.2416 9.5008 10.2371 9.50527 10.2325 9.50979C10.0619 9.68086 9.96594 9.91296 9.96567 10.155L9.96564 11.1901C10.0012 11.3641 10.0869 11.5253 10.214 11.6526C10.3846 11.8237 10.616 11.9201 10.8573 11.9204L13.0274 11.9204C13.2688 11.9201 13.5001 11.8237 13.6708 11.6526C13.8414 11.4816 13.9374 11.2495 13.9377 11.0074L13.9377 9.97226C13.9021 9.79833 13.8164 9.63715 13.6894 9.50979C13.5187 9.33872 13.2874 9.24238 13.046 9.24204L10.8759 9.24204C10.7085 9.24228 10.5458 9.28872 10.4049 9.37427Z'
        fill='#A59080'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.46274 8.44995L2.66584 8.44995L2.45359 8.48081C2.09605 8.58846 1.78246 8.80855 1.55902 9.10853C1.33559 9.40816 1.21412 9.77224 1.21251 10.1466L1.21251 11.0074C1.21303 11.4682 1.39571 11.9094 1.72047 12.2351C1.99306 12.5085 2.34706 12.6816 2.72536 12.7304L5.33439 12.7304C5.41609 12.7199 5.49666 12.7035 5.57552 12.6816L5.62477 12.6816C5.98233 12.574 6.29591 12.3539 6.51934 12.0543C6.7428 11.7543 6.86424 11.3902 6.86584 11.0158L6.86584 10.155C6.86533 9.69423 6.68266 9.25301 6.3579 8.92732C6.11183 8.68057 5.79946 8.51568 5.46274 8.44995ZM2.49247 9.37427C2.43517 9.41369 2.37971 9.4577 2.33373 9.49638C2.32914 9.5008 2.32458 9.50527 2.32007 9.50979C2.14943 9.68086 2.05346 9.91296 2.05319 10.155L2.05317 11.1901C2.08876 11.3641 2.17442 11.5253 2.30148 11.6526C2.47212 11.8237 2.70349 11.9201 2.94482 11.9204L5.11494 11.9204C5.35628 11.9201 5.58764 11.8237 5.75828 11.6526C5.92892 11.4816 6.02492 11.2495 6.02519 11.0074L6.02519 9.97226C5.9896 9.79833 5.90395 9.63715 5.77688 9.50979C5.60624 9.33872 5.37488 9.24238 5.13354 9.24204L2.96345 9.24204C2.796 9.24228 2.63337 9.28872 2.49247 9.37427Z'
        fill='#D06D4C'
      />
    </SvgIcon>
  )
}
