import 'react-app-polyfill/stable'
import 'url-search-params-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { browsers } from 'supported-browsers'
import UnsupportedBrowser from './components/UnsupportedBrowser/UnsupportedBrowser'
import { environment } from '@environments/environment'
import { AppEnvEnum } from '@typedef/Environment'

const root = document.getElementById('root')

if (environment.REACT_APP_ENV !== AppEnvEnum.PROD) {
  const favicon = document.getElementById('favicon') as HTMLLinkElement
  favicon.href =
    environment.REACT_APP_ENV === AppEnvEnum.STAGING
      ? '/favicon_staging.ico?v2'
      : '/favicon_dev.ico?v2'
}

const userAgent = window.navigator.userAgent

if (browsers.test(userAgent)) {
  ReactDOM.render(<App />, root)
} else {
  console.error(`User agent not supported: ${userAgent}`)
  ReactDOM.render(<UnsupportedBrowser />, root)
}
