import React from 'react'
import { TextField, FormLabel, FormControl, styled } from '@mui/material'

import { TextLoader } from '@components/TextLoader/TextLoader'
import { ChatDataTestId } from '@typedef/testIDs'
import { ActionButton, Content, ActionButtonWrap } from './styled'
import useTranslations from 'localisation/useTranslations'

interface MessageBoxProps {
  sendingMessage: boolean
  setMessage: (msg: string) => void
  onMessageSendClicked: () => void
}

export const MessageBox: (props: MessageBoxProps) => JSX.Element = ({
  sendingMessage,
  setMessage,
  onMessageSendClicked,
}: MessageBoxProps) => {
  const localise = useTranslations()

  return (
    <>
      <Content variant='body2' component='p'>
        {localise('helpDrawer.body')}
      </Content>
      <FormControl fullWidth sx={{ mt: 2.5 }}>
        <StyledFormLabel>{localise('helpDrawer.formLabel')}</StyledFormLabel>
        <TextField
          variant='outlined'
          multiline
          fullWidth
          autoFocus={true}
          minRows={11}
          onChange={(e) => {
            setMessage(e.target.value)
          }}
        />
      </FormControl>
      <ActionButtonWrap>
        {sendingMessage ? (
          <TextLoader />
        ) : (
          <ActionButton
            variant='contained'
            color='secondary'
            disableElevation
            onClick={onMessageSendClicked}
            data-testid={ChatDataTestId.SEND_HELP_MESSAGE_BUTTON}
          >
            {localise('helpDrawer.formSubmitButton')}
          </ActionButton>
        )}
      </ActionButtonWrap>{' '}
    </>
  )
}

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(1),
}))
