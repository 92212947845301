import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Box, Grid, GridSize, useTheme, useMediaQuery, styled } from '@mui/material'
import { NegotiationStage } from '@pactum/core-backend-types'

import { LiveSideBar } from '@components/SideBar/LiveSideBar'
import { LiveSideBarHeader } from '@components/Header/LiveSidebarHeader'
import { StagesSideBar } from '@components/SideBar/StagesSideBar'
import usePrevious from '../hooks/usePrevious'
import { useAppSelector } from 'store'
import { useChatParams } from 'store/selectors'
import ChatStatusBar from '@components/ChatStatusBar/ChatStatusBar'
import {
  CHATBOX_MAX_WIDTH,
  HEADER_HEIGHT,
  STATUS_BAR_HEIGHT,
  TOP_BORDER_HEIGHT,
} from '@constants/styles'

export const WideChatWithLiveSideBar = ({
  children,
}: {
  children: React.ReactNode
}): JSX.Element => {
  const [liveSidebarShown, setLiveSidebarShown] = useState(false)
  const [stagesSidebarShown, setStagesSidebarShown] = useState(false)
  const [fullScreenMode, setFullScreenMode] = useState(false)
  const [sideBarHtmlChanged, setSideBarHtmlChanged] = useState(false)
  const [currentStage, setCurrentStage] = useState<null | NegotiationStage>(null)
  const [alwaysShowPanels, setAlwaysShowPanels] = useState(false)
  const chatBoxContainerRef = useRef<HTMLDivElement | null>(null)

  const chatParams = useChatParams()
  const { sidebarHtml, chatStage } = useAppSelector((state) => state.chat)

  const theme = useTheme()
  const isDesktopView = useMediaQuery(theme.breakpoints.up('lg'))
  const sidePanelsHidden = (!isDesktopView || fullScreenMode) && !alwaysShowPanels

  const chatStages = useMemo(() => chatParams?.stages ?? [], [chatParams])
  const chatStagesEnabled: boolean =
    chatStages.length > 0 && Boolean(chatParams?.featureFlags?.showProgress)

  useEffect(() => {
    const newStage = chatStages.find((stage) => stage.id === chatStage)
    if (chatStagesEnabled && newStage) {
      setCurrentStage(newStage)
    } else if (chatStages.length) {
      setCurrentStage(chatStages[0])
    }
  }, [chatStagesEnabled, chatStage, chatStages])

  const previousSidebarHtml = usePrevious(sidebarHtml, null)

  useEffect(() => {
    if (sidebarHtml !== previousSidebarHtml) {
      setSideBarHtmlChanged(true)
    }
  }, [sidebarHtml, previousSidebarHtml])

  useEffect(() => {
    const onResize = () => {
      const chatBoxWidth = chatBoxContainerRef.current?.clientWidth

      if (chatBoxWidth && chatBoxWidth >= CHATBOX_MAX_WIDTH && !fullScreenMode) {
        setAlwaysShowPanels(true)
      } else {
        setAlwaysShowPanels(false)
      }
    }

    onResize()
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [fullScreenMode])

  const toggleRightSideBar = () => {
    setLiveSidebarShown(!liveSidebarShown)
    setStagesSidebarShown(false)
    setSideBarHtmlChanged(false)
  }

  const toggleLeftSideBar = () => {
    setStagesSidebarShown(!stagesSidebarShown)
    setLiveSidebarShown(false)
  }

  const getChatBoxGridProps = (): Record<string, GridSize> => {
    return sidePanelsHidden
      ? { lg: 12 }
      : {
          lg: chatStagesEnabled ? 7 : 9,
          xs: 12,
        }
  }

  return (
    <>
      <Overlay hidden={!liveSidebarShown && !stagesSidebarShown} />
      <Grid
        container
        sx={{
          borderTop: `${TOP_BORDER_HEIGHT}px solid ${theme.palette.primary.main}`,
          height: '100%',
        }}
      >
        <HeaderContainer item xs={12}>
          <LiveSideBarHeader
            toggleLiveSidebar={toggleRightSideBar}
            toggleStagesSideBar={toggleLeftSideBar}
            stageLabel={currentStage?.label}
            chatStagesEnabled={chatStagesEnabled}
            progressChanged={sideBarHtmlChanged}
          />
        </HeaderContainer>
        <SidebarContainer
          item
          xl={2}
          lg={2}
          md={2}
          xs={12}
          hidden={!chatStagesEnabled}
          sx={{
            ...(sidePanelsHidden
              ? {
                  position: 'fixed',
                  top: HEADER_HEIGHT,
                  transform: 'translateX(-100%)',
                  width: 'fit-content',
                  minWidth: '200px',
                }
              : {}),
            ...(stagesSidebarShown ? { transform: 'translateX(0%)' } : {}),
          }}
        >
          <StagesSideBar onClose={() => setStagesSidebarShown(false)} />
        </SidebarContainer>
        <ChatBoxContainer ref={chatBoxContainerRef} item {...getChatBoxGridProps()}>
          <>
            {isDesktopView ? (
              <ChatStatusBar
                alwaysShowPanels={alwaysShowPanels}
                fullScreenMode={fullScreenMode}
                setFullScreenMode={setFullScreenMode}
              />
            ) : null}
            <ChatBox>{children}</ChatBox>
          </>
        </ChatBoxContainer>
        <SidebarContainer
          item
          xl={3}
          lg={3}
          md={4}
          xs={12}
          sx={{
            breakBefore: 'page',
            ...(sidePanelsHidden
              ? { position: 'fixed', top: HEADER_HEIGHT, right: 0, transform: 'translateX(100%)' }
              : {}),
            ...(liveSidebarShown ? { transform: 'translateX(0%)' } : {}),
          }}
        >
          <LiveSideBar onClose={() => setLiveSidebarShown(false)} />
        </SidebarContainer>
      </Grid>
    </>
  )
}

const Overlay = styled(Box)({
  width: '100%',
  height: '100%',
  zIndex: 2,
  backgroundColor: 'black',
  position: 'absolute',
  opacity: 0.25,
})

const HeaderContainer = styled(Grid)({
  width: '100%',
  zIndex: 1,
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.04)',
})

const ChatBoxContainer = styled(Grid)(({ theme }) => ({
  height: `calc(100% - ${HEADER_HEIGHT + STATUS_BAR_HEIGHT}px)`,
  width: '100%',
  overflow: 'auto',
  [theme.breakpoints.up('lg')]: {
    height: `calc(100% - ${HEADER_HEIGHT}px)`,
  },
  '@media print': {
    height: 'auto',
  },
}))

const ChatBox = styled(Box)(({ theme }) => ({
  height: '100%',
  [theme.breakpoints.up('lg')]: {
    height: `calc(100% - ${STATUS_BAR_HEIGHT}px)`,
  },
  '@media print': {
    height: 'auto',
  },
}))

const SidebarContainer = styled(Grid)(({ theme }) => ({
  height: `calc(100% - ${HEADER_HEIGHT}px)`,
  maxWidth: '80%',
  overflowY: 'auto',
  backgroundColor: '#fff',
  zIndex: 2,
  transition: 'transform 0.3s ease',

  [theme.breakpoints.up('lg')]: {
    backgroundColor: theme.palette.pactum.backgroundGray,
    maxWidth: 'none',
  },
}))
