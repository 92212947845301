import React, { useEffect } from 'react'
import { Checkbox, Stack } from '@mui/material'
import {
  gridFocusCellSelector,
  GridRenderCellParams,
  useGridApiContext,
} from '@mui/x-data-grid-pro'
import { BooleanColumn, TabularDataRow } from '@pactum/core-backend-types'
import { PactumTooltip } from '@components/PactumTooltip'
import { UpdateTableState } from '@components/InputSteps/TabularDataStep/columns'
import { getRowsForTableUpdate } from '@components/InputSteps/TabularDataStep/Cells/helpers'

interface Props {
  column: BooleanColumn
  updateTableState: UpdateTableState
  params: GridRenderCellParams<TabularDataRow, string | number | boolean, string | number | boolean>
  disabled: boolean
}

export const BooleanCell = ({ column, updateTableState, params, disabled }: Props) => {
  const apiRef = useGridApiContext()
  const cellError = params.row.data[params.field].error

  const nonEditable = !column.editable || params.row.data[params.field].readonly
  const cell = gridFocusCellSelector(apiRef)

  useEffect(() => {
    const onPaste = (e: ClipboardEvent) => {
      if (!e.clipboardData) {
        return
      }

      e.preventDefault()

      const pastedValues = e.clipboardData.getData('text').split('\n')
      const updatedRows = getRowsForTableUpdate(
        pastedValues,
        apiRef,
        params.id,
        params.field,
        (v) => {
          const value = v.toLowerCase()
          return value === 'true' || value === 'false' ? value === 'true' : undefined
        },
        true,
      )

      apiRef.current.updateRows(updatedRows)
      updateTableState(updatedRows)
    }

    if (cell && cell.id === params.id && cell.field === params.field) {
      window.document.addEventListener('paste', onPaste)
    }
    return () => window.document.removeEventListener('paste', onPaste)
  }, [apiRef, cell, params.field, params.id, updateTableState])

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (nonEditable) {
      return
    }

    const row = apiRef.current.getRow(params.id) as TabularDataRow
    const updatedRow = {
      ...row,
      data: { ...row.data, [params.field]: { value: e.target.checked } },
    }
    apiRef.current.updateRows([updatedRow])
    updateTableState([updatedRow])
  }

  return (
    <Stack width='100%' height='100%' direction='row' justifyContent='center'>
      {cellError ? (
        <PactumTooltip title={cellError}>
          <Checkbox
            color='error'
            sx={{ verticalAlign: 'middle' }}
            checked={params.value as boolean}
            onChange={onChange}
            disabled={disabled || nonEditable}
          />
        </PactumTooltip>
      ) : (
        <Checkbox
          color='primary'
          sx={{ verticalAlign: 'middle' }}
          checked={params.value as boolean}
          onChange={onChange}
          disabled={disabled || nonEditable}
        />
      )}
    </Stack>
  )
}
