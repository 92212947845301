import React from 'react'
import { styled, Alert, Fade, Box } from '@mui/material'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react'
import { SerializedError } from '@reduxjs/toolkit'
import { useAppSelector } from '@store/index'
import { ChatDataTestId } from '@typedef/testIDs'
import useTranslations from '../../localisation/useTranslations'
import { ChatBoxApiError } from '@containers/ChatBox/ChatBoxApiError'
import { REQUEST_TIMEOUT_ERROR } from '@store/api/baseQuery'

interface Props {
  chatStepError?: FetchBaseQueryError | SerializedError
  retryPreviousStep: () => void
}

export const ChatBoxErrorMessage = ({ chatStepError, retryPreviousStep }: Props) => {
  const localise = useTranslations()

  const {
    authInfo: { readOnly, isMainLink, supplierMessageForUneditableChat },
    timeoutError: nextStepTimeoutError,
  } = useAppSelector((state) => state.chat)

  if (isMainLink && readOnly) {
    return (
      <ErrorMessageContainer>
        <Alert data-testid={ChatDataTestId.READ_ONLY_ALERT} severity='warning'>
          {supplierMessageForUneditableChat ?? localise('supplierLinkReadOnlyAlert')}
        </Alert>
      </ErrorMessageContainer>
    )
  }

  if (chatStepError) {
    return (
      <ErrorMessageContainer>
        <ChatBoxApiError chatStepError={chatStepError} retryPreviousStep={retryPreviousStep} />
      </ErrorMessageContainer>
    )
  }

  if (nextStepTimeoutError) {
    return (
      <ErrorMessageContainer>
        <ChatBoxApiError
          chatStepError={{ status: 'FETCH_ERROR', error: REQUEST_TIMEOUT_ERROR }}
          retryPreviousStep={retryPreviousStep}
        />
      </ErrorMessageContainer>
    )
  }

  return null
}

const ErrorMessageContainer = ({ children }: { children: React.ReactElement }) => (
  <PositionedContainer>
    <Fade in>
      <Box>{children}</Box>
    </Fade>
  </PositionedContainer>
)
export const PositionedContainer = styled(Box)({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 1,
  margin: '0 auto',
})
