import { addHook, sanitize } from 'dompurify'

addHook('afterSanitizeAttributes', (node) => {
  if ('target' in node.attributes) {
    // set all anchor tags owning target to have target=_blank with rel=noopener
    if (node.tagName.toUpperCase() === 'A') {
      node.setAttribute('target', '_blank')
      node.setAttribute('rel', 'noopener')
    } else {
      node.removeAttribute('target')
    }
  }
})

export const sanitizeHTML = (html: string) =>
  sanitize(html, {
    USE_PROFILES: { html: true },
    CUSTOM_ELEMENT_HANDLING: {
      tagNameCheck: /^pactum-/, // allow all tags starting with "pactum-"
      allowCustomizedBuiltInElements: false, // no customized built-ins allowed
    },
    ADD_ATTR: ['target'],
  })
