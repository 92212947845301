import React, { useState } from 'react'
import { Grid, GridProps, styled } from '@mui/material'

import { Header } from '@components/Header/Header'
import { SideBar } from '@components/SideBar/SideBar'
import { useChatParams } from 'store/selectors'
import { TOP_BORDER_HEIGHT } from '@constants/styles'

export const WideChat = ({ children }: { children: React.ReactNode }): JSX.Element | null => {
  const [showSidebar, setShowSidebar] = useState(false)
  const chatParams = useChatParams()

  if (!chatParams) {
    return null
  }

  return (
    <Grid
      container
      sx={(theme) => ({
        borderTop: `${TOP_BORDER_HEIGHT}px solid ${theme.palette.primary.main}`,
        height: '100%',
      })}
    >
      <Grid
        item
        xs={12}
        sx={(theme) => ({
          width: '100%',
          borderBottom: `1px solid ${theme.palette.grey['300']}`,
        })}
      >
        <Header
          logo={chatParams.headerImage ?? ''}
          onMenuClick={() => setShowSidebar(!showSidebar)}
        />
      </Grid>
      <ChatBox item xl={9} lg={8} md={8} xs={12}>
        {children}
      </ChatBox>
      <SidebarContainer item xl={3} lg={4} md={4} xs={12} showSidebar={showSidebar}>
        <SideBar description={chatParams.description ?? ''} name={chatParams.name ?? ''} />
      </SidebarContainer>
    </Grid>
  )
}

const ChatBox = styled(Grid)(({ theme }) => ({
  height: 'calc(100% - 77px)',
  [theme.breakpoints.down('md')]: {
    height: 'calc(100% - 46px)',
  },
}))

const SidebarContainer = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'showSidebar',
})<GridProps & { showSidebar: boolean }>(({ theme, showSidebar }) => ({
  height: 'calc(100% - 77px)',
  borderLeft: `1px solid ${theme.palette.grey['300']}`,
  overflowY: 'auto',
  [theme.breakpoints.down('md')]: {
    position: 'fixed',
    top: 59,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: '#fff',
    transform: showSidebar ? 'translateX(0)' : 'translateX(100%)',
    transition: 'transform 0.3s ease',
  },
}))
