import React, { createContext } from 'react'

interface ChatContextProps {
  stateId: string | undefined
  locale: string | undefined
  linkToken: string
}

const ChatContext = createContext<ChatContextProps | null>(null)

export const ChatContextProvider = (props: ChatContextProps & { children: React.ReactNode }) => {
  return <ChatContext.Provider value={props}>{props.children}</ChatContext.Provider>
}

export const useChatContext = (): ChatContextProps | null => {
  return React.useContext(ChatContext)
}
