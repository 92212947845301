import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'

import useTranslations from 'localisation/useTranslations'
import ChatOptions from '@components/ChatOptions/ChatOptions'
import ChatMessage from '@components/ChatMessage/ChatMessage'
import {
  Choice,
  MultiChoiceStep,
  MultiChoiceSubmitValue,
  RowItem,
} from '@typedef/chatSteps/MultiChoiceStep'
import { ChatDataTestId, StepType } from '@typedef/chatSteps'
import { StepOption } from '@typedef/chatSteps/OptionsStep'
import CheckboxTable from './CheckboxTable/CheckboxTable'
import CheckboxList from './CheckboxList'
import { SimulatorMultipleChoice } from '@utils/simulator/inputs'

interface Props {
  step: MultiChoiceStep
  sendValue: (
    value: MultiChoiceSubmitValue,
    stepInput: SimulatorMultipleChoice,
    label?: string,
  ) => void
  chatBoxContainerRef: React.MutableRefObject<null | HTMLDivElement>
}

const ChatMultiChoice = ({ step, sendValue, chatBoxContainerRef }: Props): JSX.Element => {
  const { title, choices, minChoices, maxChoices, preselectedValues, layout, uiProperties } = step
  const [selectedValues, setSelectedValues] = useState<string[]>(preselectedValues ?? [])
  const localise = useTranslations()

  const getSelectedIndices = (choices: { value: string }[]): number[] => {
    return choices.reduce((acc: number[], choice, idx) => {
      if (selectedValues.includes(choice.value)) {
        acc.push(idx)
      }
      return acc
    }, [])
  }

  const isRowItem = (choice: Choice | RowItem): choice is RowItem => 'row' in choice

  const getItemLabel = (item: Choice | RowItem) => (isRowItem(item) ? item.value : item.label)

  const getSubmitLabel = (choices: Choice[] | RowItem[], selectedIndices: number[]) =>
    selectedIndices.reduce((acc: string, idx) => {
      return `${acc}${String.fromCharCode(10003)} ${getItemLabel(choices[idx])}\n`
    }, '')

  const onSubmit = (choice: StepOption) => {
    let selectedIndices: number[] = []
    let label = ''
    let sortedValues: string[] = []

    if (choice.value === 'SUBMIT') {
      const allValues = choices.map((c) => c.value)
      selectedIndices = getSelectedIndices(choices)
      label = getSubmitLabel(choices, selectedIndices)
      sortedValues = [...selectedValues].sort((a, b) => allValues.indexOf(a) - allValues.indexOf(b))
    }

    sendValue(
      sortedValues,
      {
        type: StepType.MULTIPLE_CHOICE_STEP,
        value: {
          choiceIndexes: selectedIndices,
        },
      },
      label,
    )
    setSelectedValues([])
  }

  const getTitle = () => {
    if (minChoices === 1 && maxChoices === choices.length) {
      return localise('pleaseSelect')
    }

    if (minChoices === 1) {
      return localise('selectUpToMaxOptions', {
        maxChoices,
      })
    }

    return localise('selectBetweenMinMaxOptions', {
      minChoices,
      maxChoices,
    })
  }

  return (
    <ChatMessage
      stepId={step.id}
      user={false}
      message={
        <Box data-testid={ChatDataTestId.MULTI_CHOICE_CHECKBOXES}>
          {/* If title is empty string, don't render header at all */}
          {title === '' ? null : (
            <Typography
              variant='body2'
              component='p'
              mb={2}
              sx={{ fontWeight: 'bold', fontSize: '0.9375rem' }}
            >
              {title ? title : getTitle()}
            </Typography>
          )}
          {layout === 'table' ? (
            <CheckboxTable
              chatBoxContainerRef={chatBoxContainerRef}
              columns={step.columns}
              choices={choices}
              selectedValues={selectedValues}
              setSelectedValues={setSelectedValues}
              showSelectAll={!!uiProperties?.showSelectAll}
            />
          ) : (
            <CheckboxList
              choices={choices}
              selectedValues={selectedValues}
              setSelectedValues={setSelectedValues}
              showSelectAll={!!uiProperties?.showSelectAll}
            />
          )}
          <ChatOptions
            choices={[
              {
                label: localise('submit'),
                value: 'SUBMIT',
                disabled: selectedValues.length < minChoices || selectedValues.length > maxChoices,
                uiProperties: {
                  variant: 'filled',
                },
              },
              ...(uiProperties?.cancelButton
                ? [
                    {
                      label: uiProperties.cancelButton.label ?? localise('cancel'),
                      value: 'CANCEL',
                      disabled: false,
                    },
                  ]
                : []),
            ]}
            align='left'
            size='medium'
            onChipClick={onSubmit}
          />
        </Box>
      }
      shouldShowAvatar={false}
      shouldAnimate={true}
    />
  )
}

export default ChatMultiChoice
