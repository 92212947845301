import React from 'react'
import { Box, keyframes, styled } from '@mui/material'
import { ChatDataTestId } from '@typedef/testIDs'

export const TextLoader = (): JSX.Element => (
  <Box data-testid={ChatDataTestId.LOADER}>
    <TextBall sx={{ animationDelay: '-0.32s' }} />
    <TextBall sx={{ animationDelay: '-0.16s' }} />
    <TextBall />
  </Box>
)

const TextBall = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  width: 8,
  height: 8,
  backgroundColor: theme.palette.text.disabled,
  borderRadius: '100%',
  animation: `${bounceAnimation} 1.4s infinite ease-in-out both`,
}))

const bounceAnimation = keyframes`
  0% {
    transform: scale(0)
  }

  40% {
    transform: scale(1)
  }

  80% {
    transform: scale(0)
  }

  100% {
    transform: scale(0)
  }
`
