import React from 'react'
import useTranslations from '../../localisation/useTranslations'
import { Chip, chipClasses } from '@mui/material'

interface Props {
  isPreview: boolean
  isBrandingMissing: boolean
  isClientSandbox: boolean
}

export const NegotiationWarning = ({ isPreview, isBrandingMissing, isClientSandbox }: Props) => {
  const localise = useTranslations()

  if (isClientSandbox) {
    return <StyledChip label={localise('demo').toUpperCase()} />
  }

  if (isPreview) {
    return <StyledChip label={localise('preview').toUpperCase()} />
  }

  if (isBrandingMissing) {
    return <StyledChip label={localise('brandingMissingWarning').toUpperCase()} />
  }

  return null
}

const StyledChip = ({ label }: { label: string }) => (
  <Chip
    label={label}
    color='info'
    sx={{
      ml: 1,
      letterSpacing: '1.5px',
      [`& .${chipClasses.label}`]: {
        whiteSpace: 'break-spaces',
      },
    }}
  />
)
