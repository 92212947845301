import { Button, styled, Typography } from '@mui/material'

export const PageTitle = styled(Typography)({
  fontSize: '32px',
  fontWeight: 600,
  lineHeight: '36px',
}) as typeof Typography

export const SubmitButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1),
  borderRadius: '2px',
  textTransform: 'none',
}))

export const SecondaryButton = styled(Button)(() => ({
  color: '#222222',
  fontWeight: 700,
  textTransform: 'none',
}))
