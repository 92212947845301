import React from 'react'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro'
import {
  BooleanCell,
  NumberEditCell,
  SelectCell,
  TextEditCell,
  TextViewCell,
} from '@components/InputSteps/TabularDataStep/Cells'
import {
  BooleanColumn,
  NumberColumn,
  SelectColumn,
  StringColumn,
  TabularDataColumn,
  TabularDataRow,
} from '@pactum/core-backend-types'

export type UpdateTableState = (updatedRows: TabularDataRow[]) => void

export interface ChangedCellDef {
  id: string
  field: string
}

export const getDataGridColumns = (
  columns: TabularDataColumn[],
  updateTableState: UpdateTableState,
  isReadonly: boolean,
  changedCell: ChangedCellDef | null,
): GridColDef<TabularDataRow>[] => {
  return columns.map((column) => {
    if (column.type === 'number') {
      return {
        ...getColumnsBaseProps(column, isReadonly),
        type: 'number',
        renderCell: getTextViewCellRenderer(column, isReadonly),
        renderEditCell: getNumberEditCellRenderer(
          column,
          updateTableState,
          isReadonly,
          changedCell,
        ),
        valueGetter: baseValueGetter(column.field),
        valueSetter: baseValueSetter(column.field),
      }
    }

    if (column.type === 'boolean') {
      return {
        ...getColumnsBaseProps(column, isReadonly),
        editable: false,
        type: 'boolean',
        align: 'center',
        headerAlign: 'center',
        renderCell: getBooleanViewCellRenderer(column, updateTableState, isReadonly),
        valueGetter: baseValueGetter(column.field),
        valueSetter: baseValueSetter(column.field),
      }
    }

    if (column.type === 'select') {
      return {
        ...getColumnsBaseProps(column, isReadonly),
        editable: false,
        type: 'singleSelect',
        align: 'center',
        headerAlign: 'center',
        renderCell: getSelectViewCellRenderer(column, updateTableState, isReadonly),
        valueOptions: column.options,
        valueGetter: baseValueGetter(column.field),
        valueSetter: baseValueSetter(column.field),
      }
    }

    return {
      ...getColumnsBaseProps(column, isReadonly),
      type: 'string',
      renderCell: getTextViewCellRenderer(column, isReadonly),
      renderEditCell: getTextEditCellRenderer(column, updateTableState, isReadonly, changedCell),
      valueGetter: baseValueGetter(column.field),
      valueSetter: baseValueSetter(column.field),
    }
  })
}

const baseValueGetter =
  (field: string) => (value: string | number | boolean, row: TabularDataRow) =>
    row.data[field].value

const baseValueSetter =
  (field: string) => (value: string | number | boolean, row: TabularDataRow) => ({
    ...row,
    data: {
      ...row.data,
      [field]: {
        ...row.data[field],
        value: value,
      },
    },
  })

const getTextViewCellRenderer =
  (column: TabularDataColumn, isDisabled: boolean) =>
  (params: GridRenderCellParams<TabularDataRow, string | number, string | number>) =>
    <TextViewCell column={column} params={params} disabled={isDisabled} />

const getTextEditCellRenderer =
  (
    column: StringColumn,
    updateTableState: UpdateTableState,
    isDisabled: boolean,
    changedCell: ChangedCellDef | null,
  ) =>
  (params: GridRenderCellParams<TabularDataRow, string, string>) =>
    (
      <TextEditCell
        updateTableState={updateTableState}
        params={params}
        disabled={isDisabled}
        changedCell={changedCell}
      />
    )

const getNumberEditCellRenderer =
  (
    column: NumberColumn,
    updateTableState: UpdateTableState,
    isDisabled: boolean,
    changedCell: ChangedCellDef | null,
  ) =>
  (params: GridRenderCellParams<TabularDataRow, number, number>) =>
    (
      <NumberEditCell
        column={column}
        updateTableState={updateTableState}
        params={params}
        disabled={isDisabled}
        changedCell={changedCell}
      />
    )

const getBooleanViewCellRenderer =
  (column: BooleanColumn, updateTableState: UpdateTableState, isDisabled: boolean) =>
  (params: GridRenderCellParams<TabularDataRow, boolean, boolean>) =>
    (
      <BooleanCell
        column={column}
        updateTableState={updateTableState}
        params={params}
        disabled={isDisabled}
      />
    )

const getSelectViewCellRenderer =
  (column: SelectColumn, updateTableState: UpdateTableState, isDisabled: boolean) =>
  (params: GridRenderCellParams<TabularDataRow, string>) =>
    (
      <SelectCell
        column={column}
        updateTableState={updateTableState}
        params={params}
        disabled={isDisabled}
      />
    )

const getColumnsBaseProps = (
  column: TabularDataColumn,
  isTabularComponentReadonly: boolean,
): GridColDef<TabularDataRow, string | number | boolean> => ({
  headerName: column.headerLabel,
  field: column.field,
  editable: isTabularComponentReadonly ? false : column.editable,
  sortable: column.sortable,
  align: 'left',
  headerAlign: 'left',
  minWidth: 100,
  flex: 1,
})
