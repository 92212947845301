import React from 'react'
import isNil from 'lodash/isNil'
import { TextField, FormControl, MenuItem, styled } from '@mui/material'

import { ChatDataTestId } from '@typedef/chatSteps'
import useTranslations from 'localisation/useTranslations'
import { DropdownInputProps, DropdownOption } from '@typedef/chatSteps/MultiInputStep'

interface Props {
  variableKey: string
  inputProps: DropdownInputProps
  value: string | number | undefined | null
  setValue: (variable: string, value: string | number) => void
  error?: boolean
  isReadOnly: boolean
}

export const DropdownInput = ({
  variableKey,
  inputProps,
  value,
  setValue,
  error = false,
  isReadOnly,
}: Props): JSX.Element => {
  const localise = useTranslations()

  const optionLabelWithSuffixes = (value: number | string | null) => {
    if (value === null) {
      return inputProps.nullValueLabel ?? '-'
    }
    return `${inputProps.optionPrefix ?? ''}${value}${inputProps.optionSuffix ?? ''}`
  }

  const getOptionLabel = (option: DropdownOption): string => {
    if (typeof option === 'object' && option !== null) {
      return option.label ? option.label : optionLabelWithSuffixes(option.value)
    } else {
      return optionLabelWithSuffixes(option)
    }
  }
  const pleaseSelectText = localise('pleaseSelect')

  return (
    <FormControl data-testid={ChatDataTestId.DROPDOWN_INPUT}>
      <StyledTextField
        select
        value={isNil(value) ? '' : value}
        onChange={(event) => setValue(variableKey, event.target.value)}
        inputProps={{ 'aria-label': 'Dropdown input label' }}
        error={error}
        disabled={isReadOnly}
        variant='outlined'
        SelectProps={{ displayEmpty: true }}
      >
        {(inputProps.required === false || isNil(value)) && (
          <MenuItem value=''>
            {inputProps.required ? pleaseSelectText : inputProps.nullValueLabel ?? pleaseSelectText}
          </MenuItem>
        )}
        {inputProps.options.map((option, index) => (
          <MenuItem
            key={variableKey + index.toString()}
            value={
              (typeof option === 'object' && option !== null ? option.value : option) ?? undefined
            }
          >
            {getOptionLabel(option)}
          </MenuItem>
        ))}
      </StyledTextField>
    </FormControl>
  )
}

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    borderRadius: 0,
    margin: theme.spacing(0, 1),
  },
  '&& .MuiSelect-select': {
    minWidth: 40,
    minHeight: '1.4375em',
    padding: '6px 22px 6px 8px',
    borderRadius: 0,
    whiteSpace: 'break-spaces',
    fontSize: '0.9375em',
    color: theme.palette.common.black,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.grey['300'],
  },
  '& .MuiSvgIcon-root': {
    color: 'var(--primary-color)',
    right: 0,
  },
}))
