import { CesSubmitValue, FileValue, StepType, TabularDataSubmitValue } from '@typedef/chatSteps'

export enum ChatEvent {
  USER_MESSAGE_POSTED = 'user-message-posted',
}

export type SimulatorInput =
  | SimulatorSimpleInput
  | SimulatorFileInput
  | SimulatorOptions
  | SimulatorMultipleChoice
  | SimulatorMultiInput
  | SimulatorCes
  | SimulatorTabularData

export interface SimulatorSimpleInput {
  type: StepType.INPUT_STEP
  value: string | number
}

export interface SimulatorFileInput {
  type: StepType.FILE_INPUT_STEP
  value: FileValue
}

export interface SimulatorOptions {
  type: StepType.OPTIONS_STEP
  value: {
    choiceIndex: number
  }
}

export interface SimulatorMultipleChoice {
  type: StepType.MULTIPLE_CHOICE_STEP
  value: {
    choiceIndexes: number[]
  }
}

export interface SimulatorMultiInput {
  type: StepType.MULTI_INPUT_STEP
  value: (string | number | undefined | null)[] | null
  submitButtonIndex: number
}

export interface SimulatorCes {
  type: StepType.CES_STEP
  value: CesSubmitValue
}

export interface SimulatorTabularData {
  type: StepType.TABULAR_DATA_STEP
  value: TabularDataSubmitValue
}
