import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Alert, Box, CircularProgress } from '@mui/material'
import { GridCellParams, GridNoRowsOverlay, GridOverlay, useGridApiRef } from '@mui/x-data-grid-pro'
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  MutationDefinition,
} from '@reduxjs/toolkit/query'
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { TabularDataRow, TabularDataTab as TabularDataTabType } from '@pactum/core-backend-types'
import { RetryOptions } from '@pactum/common'

import { TabularDataUpdateParams } from '@store/api'
import { ChangedCellDef, getDataGridColumns } from '@components/InputSteps/TabularDataStep/columns'
import { StyledDataGrid } from '@components/InputSteps/TabularDataStep/styled'
import { isQuerySuccessResponse } from '@store/api/error'
import useTranslations from '../../../localisation/useTranslations'
import { MessageText } from '@components/ChatMessage/ChatMessageBubble'
import { HTML } from '@components/HTML'
import { useIsReadOnlyChatSession } from '@store/selectors'

type UpdateDataType = MutationTrigger<
  MutationDefinition<
    TabularDataUpdateParams,
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, RetryOptions, FetchBaseQueryMeta>,
    'ContactConfirmation',
    { tabularDataTab: TabularDataTabType },
    'api'
  >
>

interface Props {
  tab: TabularDataTabType
  token: string
  stepId: string
  stateId: string
  updateData: UpdateDataType
}

export const TabularDataTab = ({ tab, token, stepId, stateId, updateData }: Props) => {
  const localise = useTranslations()
  const [summary, setSummary] = useState(tab.summary ?? '')
  const [tableState, setTableState] = useState<TabularDataRow[]>([])
  const [isUpdateInProgress, setIsUpdateInProgress] = useState(false)
  const [updatingCell, setUpdatingCell] = useState<ChangedCellDef | null>(null)
  const [updateError, setUpdateError] = useState('')
  const apiRef = useGridApiRef()

  const isReadOnlyView = useIsReadOnlyChatSession()

  const updateTableState = useCallback(
    async (updatedRows: TabularDataRow[]) => {
      setIsUpdateInProgress(true)

      const updateRequest = {
        token,
        stepId,
        stateId,
        tabId: tab.id,
        updatedRows,
      }

      const response = await updateData(updateRequest)

      if (isQuerySuccessResponse(response)) {
        const { tabularDataTab } = response.data
        setTableState(tabularDataTab.rows)
        setSummary(tabularDataTab.summary ?? '')
        setIsUpdateInProgress(false)
        return tabularDataTab.rows
      }

      setUpdateError(localise('generalError'))
      setIsUpdateInProgress(false)
    },
    [token, stepId, stateId, tab.id, updateData, localise],
  )

  useEffect(() => {
    updateTableState([])
  }, [updateTableState])

  const processRowUpdate = useCallback(
    async (newRow: TabularDataRow, oldRow: TabularDataRow): Promise<TabularDataRow> => {
      const changedField = getRowChangedField(newRow, oldRow)
      if (!changedField) {
        return newRow
      }

      setUpdatingCell({ id: newRow.id, field: changedField })
      const updatedRows = await updateTableState([newRow])
      setUpdatingCell(null)
      return updatedRows ? updatedRows.find((row) => row.id === newRow.id)! : oldRow
    },
    [updateTableState],
  )

  const handleCellClick = useCallback(
    (params: GridCellParams<TabularDataRow>, event: React.MouseEvent) => {
      if (!params.isEditable) {
        return
      }

      // Ignore portal
      if (
        (event.target as unknown as { nodeType: number }).nodeType === 1 &&
        !event.currentTarget.contains(event.target as Element)
      ) {
        return
      }

      if (apiRef.current.getCellMode(params.id, params.field) !== 'edit') {
        apiRef.current.startCellEditMode({ id: params.id, field: params.field })
      }
    },
    [apiRef],
  )

  const datagridColumns = useMemo(
    () =>
      getDataGridColumns(
        tab.columns,
        updateTableState,
        isUpdateInProgress || isReadOnlyView,
        updatingCell,
      ),
    [tab.columns, updateTableState, isUpdateInProgress, updatingCell, isReadOnlyView],
  )

  const isCellEditable = useCallback((params: GridCellParams<TabularDataRow>) => {
    if (params.row.data[params.field].readonly == null) {
      return !!params.colDef.editable
    }

    return !params.row.data[params.field].readonly
  }, [])

  const rowHeight = 48
  const columnHeaderHeight = 32
  const maxRowsShown = 20
  const minRowsShown = 2
  const rowsShown = Math.min(maxRowsShown, Math.max(minRowsShown, tableState.length))

  return (
    <Box>
      <MessageText>
        <HTML markup={summary} />
      </MessageText>
      <Box height={rowHeight * rowsShown + columnHeaderHeight + 2}>
        <StyledDataGrid
          apiRef={apiRef}
          columns={datagridColumns}
          rows={tableState}
          isCellEditable={(params) => !isUpdateInProgress && isCellEditable(params)}
          processRowUpdate={processRowUpdate}
          onCellClick={handleCellClick}
          columnHeaderHeight={columnHeaderHeight}
          rowHeight={rowHeight}
          hideFooter
          disableColumnMenu
          slots={{
            columnSortedAscendingIcon: ArrowDropUpIcon,
            columnSortedDescendingIcon: ArrowDropDownIcon,
            noRowsOverlay: NoRowsOverlay(isUpdateInProgress),
          }}
          data-table-updating={isUpdateInProgress}
        />
      </Box>
      {updateError ? (
        <Alert severity='error' onClose={() => setUpdateError('')} sx={{ mt: 0.5 }}>
          {updateError}
        </Alert>
      ) : null}
    </Box>
  )
}

const NoRowsOverlay = (isUpdateInProgress: boolean) => () => {
  if (isUpdateInProgress) {
    return (
      <GridOverlay>
        <CircularProgress />
      </GridOverlay>
    )
  }

  return <GridNoRowsOverlay />
}

const getRowChangedField = (row1: TabularDataRow, row2: TabularDataRow): false | string => {
  const row1Keys = Object.keys(row1.data)
  const row2Keys = Object.keys(row2.data)

  if (row1Keys.length !== row2Keys.length) {
    return false
  }

  if (row1Keys.sort().join(',') !== row2Keys.sort().join(',')) {
    return false
  }

  const changedKey = row1Keys.find((key) => row1.data[key].value !== row2.data[key].value)

  return changedKey ?? false
}
