import React from 'react'
import { Box, Grid, Link } from '@mui/material'

import { TOP_BORDER_HEIGHT } from '@constants/styles'
import { PageTitle } from '@components/styled'

interface OwnProps {
  title: string
  supportEmail?: string
}

type Props = React.PropsWithChildren<OwnProps>

export const FullPageError = ({ children, title, supportEmail }: Props): JSX.Element => {
  return (
    <Grid
      container
      justifyContent='center'
      sx={(theme) => ({ borderTop: `${TOP_BORDER_HEIGHT}px solid ${theme.palette.primary.main}` })}
    >
      <Grid item xl={6} lg={6} md={10} xs={12}>
        <Box py={2} px={4}>
          <PageTitle>{title}</PageTitle>
          {children}
          {supportEmail ? (
            <p>
              If you need help, please contact{' '}
              <Link href={`mailto:${supportEmail}`} title='Contact support'>
                {supportEmail}
              </Link>
              .
            </p>
          ) : null}
        </Box>
      </Grid>
    </Grid>
  )
}
