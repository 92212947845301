import React from 'react'
import { TextField, Typography } from '@mui/material'
import { GridRenderCellParams } from '@mui/x-data-grid-pro'
import { PactumTooltip } from '@components/PactumTooltip'
import { TabularDataColumn, TabularDataRow } from '@pactum/core-backend-types'
import { formatNumber } from '@containers/ChatInput/NumberInput'
import { useNumberLocale } from '@hooks/useNumberLocale'
import { HTML } from '@components/HTML'

interface Props {
  column: TabularDataColumn
  params: GridRenderCellParams<TabularDataRow, string | number, string | number>
  disabled: boolean
}

export const TextViewCell = ({ column, params, disabled }: Props) => {
  const cellError = params.row.data[params.field].error
  const numberLocale = useNumberLocale()

  const nonEditable = !column.editable || params.row.data[params.field].readonly

  if (!nonEditable) {
    const displayValue =
      column.type === 'number'
        ? formatNumber(params.value ?? '', numberLocale, column.numberFormat)
        : String(params.value)

    return cellError ? (
      <PactumTooltip title={cellError} placement='top'>
        <TextField
          error={!!cellError}
          value={displayValue}
          size='small'
          sx={{ verticalAlign: 'middle' }}
          disabled={disabled}
        />
      </PactumTooltip>
    ) : (
      <TextField
        value={displayValue}
        size='small'
        sx={{ verticalAlign: 'middle' }}
        disabled={disabled}
      />
    )
  }

  const displayValue =
    column.type === 'number'
      ? formatNumber(params.value ?? '', numberLocale, column.numberFormat)
      : String(params.value)

  return cellError ? (
    <PactumTooltip title={cellError} placement='top'>
      <Typography variant='body2' component='span' fontWeight='500' color='error'>
        <HTML markup={displayValue} />
      </Typography>
    </PactumTooltip>
  ) : (
    <Typography variant='body2' component='span'>
      <HTML markup={displayValue} />
    </Typography>
  )
}
