import React, { FormEvent, useRef } from 'react'
import { Alert, CircularProgress, Stack, TextField, Typography } from '@mui/material'
import useTranslations from '../../localisation/useTranslations'
import { PageTitle, SubmitButton } from '@components/styled'

interface Props {
  onSubmit: (value: string) => void
  defaultValue: string | null
  loading?: boolean
  errorMessage?: string
}

export const AccessKeyForm = ({ onSubmit, defaultValue, errorMessage, loading }: Props) => {
  const localise = useTranslations()
  const inputRef = useRef<HTMLInputElement | null>(null)

  const submit = (e: FormEvent) => {
    e.preventDefault()
    if (!inputRef.current) {
      return
    }
    onSubmit(inputRef.current.value)
  }

  return (
    <Stack component='form' onSubmit={submit}>
      <Stack gap={1}>
        <PageTitle>{localise('accessKeyDialogTitle')}</PageTitle>
        <Typography variant='body2'>{localise('accessKeyDialogBody')}</Typography>
        {errorMessage && <Alert severity={'error'}>{errorMessage}</Alert>}
        <Stack direction='row' gap={1}>
          <TextField
            fullWidth
            autoFocus
            type='password'
            inputRef={inputRef}
            defaultValue={defaultValue}
          />
          <SubmitButton type='submit'>
            {loading ? <CircularProgress /> : localise('accessKeyDialogSubmit')}
          </SubmitButton>
        </Stack>
      </Stack>
    </Stack>
  )
}
