import React from 'react'
import { Box, Typography, Badge, styled, Stack } from '@mui/material'
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined'
import { HelpDrawer } from '../HelpDrawer/HelpDrawer'
import useTranslations from '../../localisation/useTranslations'
import { useChatParams } from 'store/selectors'
import { HEADER_HEIGHT, STATUS_BAR_HEIGHT } from '@constants/styles'
import { NegotiationWarning } from '@components/NegotiationWarning'

interface Props {
  toggleStagesSideBar: () => void
  toggleLiveSidebar: () => void
  progressChanged: boolean
  chatStagesEnabled: boolean
  stageLabel?: string
  title?: string
}

export const LiveSideBarHeader = ({
  toggleStagesSideBar,
  toggleLiveSidebar,
  progressChanged,
  chatStagesEnabled,
  stageLabel,
}: Props): JSX.Element => {
  const localise = useTranslations()
  const chatParams = useChatParams()
  const logo = chatParams?.headerImage ?? ''

  return (
    <>
      <HeaderContainer>
        <Stack flexDirection='row' alignItems='center'>
          {logo && <Logo src={logo} alt='Logo' />}
          {chatParams ? (
            <NegotiationWarning
              isPreview={chatParams.isPreview}
              isBrandingMissing={chatParams.isBrandingMissing ?? false}
              isClientSandbox={chatParams.isClientSandbox}
            />
          ) : null}
        </Stack>
        <HeaderActionsContainer>
          <Box sx={{ ml: 1 }}>
            <HelpDrawer />
          </Box>
        </HeaderActionsContainer>
      </HeaderContainer>
      <MobileBar hasStage={!!stageLabel}>
        <Box hidden={!chatStagesEnabled} onClick={toggleStagesSideBar}>
          <ProgressTitle>{localise('chatProgress').toUpperCase()}</ProgressTitle>
          <ProgressStage>{stageLabel}</ProgressStage>
        </Box>
        <Box sx={{ color: 'pactum.darkGray' }} onClick={toggleLiveSidebar}>
          <AgreementButtonText>
            {localise('agreementInfo').toUpperCase()}
            &nbsp;
          </AgreementButtonText>
          <Badge color={progressChanged ? 'primary' : undefined} variant='dot'>
            <ListAltOutlinedIcon />
          </Badge>
        </Box>
      </MobileBar>
    </>
  )
}

const AgreementButtonText = styled('span')({
  textTransform: 'uppercase',
  fontWeight: 600,
  fontSize: '10px',
  lineHeight: '12px',
  verticalAlign: 'middle',
})

const ProgressTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.pactum.darkGray,
  fontWeight: 600,
  fontSize: '10px',
  lineHeight: '12px',
}))

const ProgressStage = styled(Typography)(({ theme }) => ({
  color: theme.palette.pactum.black,
  fontWeight: 'bold',
  fontSize: '13px',
  lineHeight: '18px',
}))

export const HeaderContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  minHeight: `${HEADER_HEIGHT}px`,
  height: `${HEADER_HEIGHT}px`,
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(1.5, 4),
  },
}))

const HeaderActionsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  flexWrap: 'wrap-reverse',
  justifyContent: 'flex-end',
  marginLeft: 'auto',
}))

const MobileBar = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'hasStage',
})<{ hasStage: boolean }>(({ theme, hasStage }) => ({
  width: hasStage ? '100%' : undefined,
  float: hasStage ? 'unset' : 'right',
  padding: theme.spacing(1, 2),
  gap: theme.spacing(1),
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: STATUS_BAR_HEIGHT,
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
}))

export const Logo = styled('img')(({ theme }) => ({
  maxHeight: 25,
  [theme.breakpoints.up('lg')]: {
    maxHeight: 52,
  },
}))
