import React, { useLayoutEffect, useRef } from 'react'
import { CircularProgress, InputAdornment, TextField } from '@mui/material'
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-pro'
import { NumberColumn, TabularDataRow } from '@pactum/core-backend-types'
import { formatNumber, NumberInput, parseNumber } from '@containers/ChatInput/NumberInput'
import { useNumberLocale } from '@hooks/useNumberLocale'
import { ChangedCellDef, UpdateTableState } from '@components/InputSteps/TabularDataStep/columns'
import { NumberParser } from '@internationalized/number'
import {
  getRowsForTableUpdate,
  isCellUpdating,
} from '@components/InputSteps/TabularDataStep/Cells/helpers'

interface Props {
  column: NumberColumn
  updateTableState: UpdateTableState
  params: GridRenderEditCellParams<TabularDataRow, number>
  disabled: boolean
  changedCell: ChangedCellDef | null
}

export const NumberEditCell = ({
  column,
  updateTableState,
  params,
  disabled,
  changedCell,
}: Props) => {
  const apiRef = useGridApiContext()
  const numberLocale = useNumberLocale()
  const { id, value, field, hasFocus } = params
  const ref = useRef<HTMLInputElement>(null)

  const inputProps = isCellUpdating(changedCell, String(id), field)
    ? {
        endAdornment: (
          <InputAdornment position='end'>
            <CircularProgress disableShrink size='1rem' />
          </InputAdornment>
        ),
      }
    : {}

  const onCellValueChange = (value: number | null) => {
    apiRef.current.setEditCellValue({ id, field, value })
  }

  const onPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedData = e.clipboardData.getData('text')
    const values = pastedData.split('\n')

    e.preventDefault()
    const updatedRows = getRowsForTableUpdate(values, apiRef, id, field, (v) =>
      getParsedNumber(v, numberLocale, column.numberFormat),
    )

    apiRef.current.updateRows(updatedRows)
    updateTableState(updatedRows)
  }

  useLayoutEffect(() => {
    if (hasFocus && ref.current) {
      const input = ref.current.querySelector<HTMLInputElement>('input')
      input?.focus()
    }
  }, [hasFocus, value])

  return (
    <NumberInput
      ref={ref}
      onPaste={onPaste}
      TextFieldComponent={TextField}
      numberLocale={numberLocale}
      value={value ?? null}
      onValidInput={onCellValueChange}
      inputProps={{ 'aria-label': 'Number input label' }}
      variant='outlined'
      size='small'
      formatOptions={column.numberFormat}
      placeholderTranslationKey={'numberInput.placeholderShort'}
      sx={{ verticalAlign: 'middle' }}
      disabled={disabled}
      InputProps={inputProps}
    />
  )
}

const getParsedNumber = (
  input: string,
  numberLocale: string,
  formatOptions: NumberColumn['numberFormat'],
): number | undefined => {
  const parser = new NumberParser(numberLocale, formatOptions ?? {})

  if (parser.isValidPartialNumber(input)) {
    let parsed = parseNumber(input, numberLocale, formatOptions)
    if (!isNaN(parsed)) {
      const formattedNumber = formatNumber(parsed, numberLocale, {
        ...(formatOptions ?? {}),
        style: 'decimal',
      })

      return parseNumber(formattedNumber, numberLocale, formatOptions)
    }
  }
}
