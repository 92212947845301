import React, { useState } from 'react'
import { Box, Button, ButtonProps, styled, Typography } from '@mui/material'
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded'
import formatBytes from 'containers/ChatInput/formatBytes'
import { ChatDataTestId, FileValue } from '@typedef/chatSteps'
import useTranslations from '../../localisation/useTranslations'
import { PactumTooltip } from '@components/PactumTooltip'

interface Props {
  setFileValue: (value: FileValue) => void
  setInputError: (value: string) => void
}

const FileInput = ({ setFileValue, setInputError }: Props): JSX.Element => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const localise = useTranslations()

  const handleFileSelect = (files: FileList | null) => {
    if (!files || files.length < 1) {
      return
    }

    const file = files[0]
    setSelectedFile(file)

    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)

    fileReader.onload = () => {
      setFileValue({
        filename: file.name,
        contentType: file.type,
        contentLength: file.size,
        contentBase64: fileReader.result as string, // readAsDataURL docs say it is actually a string
      })
    }

    fileReader.onerror = () => {
      setInputError(localise('fileProcessingFailed'))
    }
  }

  const fileText = selectedFile
    ? `${selectedFile.name} (${formatBytes(selectedFile.size)})`
    : localise('noFileSelected')

  return (
    <Wrapper data-testid={ChatDataTestId.FILE_INPUT}>
      <StyledButton variant='contained' component='label' color='primary'>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <UploadFileRoundedIcon sx={{ fontSize: '30px' }} />
          <Typography component='span' ml={1} sx={{ textTransform: 'none' }}>
            {localise('selectFile')}
          </Typography>
        </Box>
        <input type='file' hidden onChange={(e) => handleFileSelect(e.target.files)} />
      </StyledButton>
      <PactumTooltip title={fileText}>
        <FileText>{fileText}</FileText>
      </PactumTooltip>
    </Wrapper>
  )
}

const Wrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  height: '56px',
  width: '100%',
  overflowX: 'hidden',
  fontSize: 16,
  color: '#000',
})

const FileText = styled('div')(({ theme }) => ({
  height: '100%',
  lineHeight: '56px',
  overflowX: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  marginLeft: theme.spacing(2),
  color: theme.palette.grey['700'],
}))

const StyledButton = styled(Button)<{ component: string } & ButtonProps>(() => ({
  borderRadius: '0 0 0 10px',
  height: '100%',
  flexShrink: 0,
}))

export default FileInput
