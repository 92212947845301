import React, { useEffect, useState } from 'react'
import copy from 'copy-to-clipboard'

import { IconButton, SxProps, Tooltip } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

interface Props {
  onCopy: () => string
  sx?: SxProps
}

export const ClipboardCopyIconButton = ({ onCopy, sx }: Props): JSX.Element => {
  const [copied, setIsCopied] = useState(false)
  const copyTextToClipboard = () => {
    const textToCopy = onCopy()
    copy(textToCopy, { format: 'text/plain' })
    setIsCopied(true)
  }

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setIsCopied(false)
      }, 2000)
    }
  }, [copied])

  return copied ? (
    <Tooltip title='Copied!'>
      <CheckIcon sx={{ ...sx, m: '5px' }} fontSize='small' />
    </Tooltip>
  ) : (
    <IconButton sx={sx} title='Copy' aria-label='copy' size='small' onClick={copyTextToClipboard}>
      <ContentCopyIcon fontSize='small' />
    </IconButton>
  )
}
