import React from 'react'
import { TextField } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'

import { ChatDataTestId } from '@typedef/chatSteps'
import { dateIsoFormat } from '@constants/datetime'

interface Props {
  TextFieldComponent: typeof TextField
  selectedDate: Date | null
  minDate: Date | null
  maxDate: Date | null
  handleDateChange: (date: Date | null) => void
  handleKeyPress?: (event: React.KeyboardEvent<HTMLDivElement>) => void
  disabled?: boolean
  error?: boolean
}

export const DateInput = ({
  TextFieldComponent,
  selectedDate,
  handleDateChange,
  minDate,
  maxDate,
  handleKeyPress,
  disabled,
  error = false,
}: Props): JSX.Element => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        onChange={handleDateChange}
        value={selectedDate}
        InputProps={{
          disableUnderline: true,
        }}
        renderInput={(params) => (
          <TextFieldComponent
            {...params}
            data-testid={ChatDataTestId.DATE_INPUT}
            variant='standard'
            error={error}
            onKeyPress={handleKeyPress}
            fullWidth
            autoFocus
          />
        )}
        minDate={minDate ?? undefined}
        maxDate={maxDate ?? undefined}
        disabled={!!disabled}
        inputFormat={dateIsoFormat}
      />
    </LocalizationProvider>
  )
}
