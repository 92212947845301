import { grey } from '@mui/material/colors'

export const lightTheme = {
  themeName: 'Pactum light',
  themeGroup: 'chat',
  palette: {
    primary: {
      main: '#0072ce',
    },
    secondary: {
      main: '#F2C94C',
    },
    background: {
      paper: grey[200],
    },
    text: {
      primary: '#333333',
    },
  },
  spacing: 8,
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: '#fff',
          fontFamily: ['Inter', 'sans-serif'].join(','),
          fontSize: '1rem',
          lineHeight: '1.5',
          letterSpacing: 0,
          color: '#333333',
        },
        html: {
          WebkitFontSmoothing: 'auto',
        },
      },
    },
  },
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
    color: '#333333',
  },
}
