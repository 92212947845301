import React from 'react'
import { CircularProgress } from '@mui/material'
import SendIcon from '@mui/icons-material/Send'

import { SubmitButton } from '@containers/ChatInput/SubmitButton'
import useTranslations from '../../localisation/useTranslations'

interface Props {
  inputValue: string
  onSubmit: () => void
  format: 'text' | 'textarea'
  minChars: number
}

export const SubmitButtonWithProgress = ({ inputValue, onSubmit, format, minChars }: Props) => {
  const localise = useTranslations()
  const progress = Math.round((inputValue.length / minChars) * 100)

  return (
    <>
      {progress > 100 ? null : (
        <CircularProgress
          variant='determinate'
          value={progress}
          sx={{ transition: 'opacity 300ms ease', ...(progress >= 100 ? { opacity: 0 } : {}) }}
        />
      )}
      <SubmitButton onClick={onSubmit} disabled={progress < 100}>
        {format === 'textarea' ? localise('textareaSubmitBtnText') : <SendIcon />}
      </SubmitButton>
    </>
  )
}
