import React, { ChangeEvent, useCallback, useEffect } from 'react'
import { FormControl, NativeSelect, Stack, Typography } from '@mui/material'
import {
  gridFocusCellSelector,
  GridRenderCellParams,
  useGridApiContext,
} from '@mui/x-data-grid-pro'
import {
  SelectColumn,
  SelectOption,
  TabularDataRow,
  TabularDataSelectCell,
} from '@pactum/core-backend-types'
import { UpdateTableState } from '@components/InputSteps/TabularDataStep/columns'
import { getRowsForTableUpdate } from '@components/InputSteps/TabularDataStep/Cells/helpers'
import { PactumTooltip } from '@components/PactumTooltip'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

interface Props {
  column: SelectColumn
  updateTableState: UpdateTableState
  params: GridRenderCellParams<TabularDataRow, string | number | boolean, string | number | boolean>
  disabled: boolean
}

export const SelectCell = ({ column, updateTableState, params, disabled }: Props) => {
  const apiRef = useGridApiContext()
  const cellError = params.row.data[params.field].error
  const nonEditable =
    params.row.data[params.field].readonly == null
      ? !column.editable
      : params.row.data[params.field].readonly
  const cell = gridFocusCellSelector(apiRef)

  const parseFunction = useCallback(
    (v: string, row: TabularDataRow) => {
      const options: SelectOption[] =
        (row.data[params.field] as TabularDataSelectCell).cellSelectOptions ?? column.options

      if (options.map((option) => option.value).includes(v)) {
        return v
      }

      if (options.map((option) => option.label).includes(v)) {
        return options.find((option) => option.label === v)!.value
      }

      return undefined
    },
    [params.field, column.options],
  )

  useEffect(() => {
    const onPaste = (e: ClipboardEvent) => {
      if (!e.clipboardData) {
        return
      }

      e.preventDefault()

      const pastedValues = e.clipboardData.getData('text').split('\n')
      const updatedRows = getRowsForTableUpdate(
        pastedValues,
        apiRef,
        params.id,
        params.field,
        parseFunction,
        true,
      )

      apiRef.current.updateRows(updatedRows)
      updateTableState(updatedRows)
    }

    if (cell && cell.id === params.id && cell.field === params.field) {
      window.document.addEventListener('paste', onPaste)
    }
    return () => window.document.removeEventListener('paste', onPaste)
  }, [apiRef, cell, params.field, params.id, updateTableState, parseFunction])

  const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (nonEditable) {
      return
    }

    const row = apiRef.current.getRow(params.id) as TabularDataRow
    const updatedRow = {
      ...row,
      data: { ...row.data, [params.field]: { ...row.data[params.field], value: e.target.value } },
    }
    apiRef.current.updateRows([updatedRow])
    updateTableState([updatedRow])
  }

  const getCellOptions = (): SelectOption[] => {
    const optionsOverride = (params.row.data[params.field] as TabularDataSelectCell)
      .cellSelectOptions
    return optionsOverride ?? column.options
  }

  const select = (
    <FormControl fullWidth sx={{ margin: 'auto' }}>
      <NativeSelect
        IconComponent={KeyboardArrowDownIcon}
        value={String(params.value)}
        onChange={onChange}
        disabled={disabled}
        sx={{
          '&.MuiInputBase-root.MuiInput-root': {
            ...(cellError ? { color: 'error.main' } : {}),
          },
          '&.MuiInputBase-root.MuiInput-root:before': {
            borderBottom: 'none',
          },
          '&:hover:before, &:focus:before, &:after, &:hover:after, &:focus:after': {
            borderBottom: 'none',
          },
        }}
      >
        {getCellOptions().map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </NativeSelect>
    </FormControl>
  )

  if (nonEditable) {
    return (
      <Stack width='100%' height='100%' direction='row' alignItems='center'>
        <Typography>{getCellOptions().find((o) => o.value === params.value)!.label}</Typography>
      </Stack>
    )
  }

  return (
    <Stack width='100%' height='100%' direction='row' justifyContent='center'>
      {cellError ? <PactumTooltip title={cellError}>{select}</PactumTooltip> : select}
    </Stack>
  )
}
