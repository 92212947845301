import React, { KeyboardEvent, useState } from 'react'
import SendIcon from '@mui/icons-material/Send'
import { SubmitButton } from '@containers/ChatInput/SubmitButton'
import { ErrorMessage, SimpleInputContainer, StyledTextField } from './ChatInput'
import { ChatDataTestId } from '@typedef/testIDs'
import useTranslations from '../../localisation/useTranslations'
import { extractEmailValue, extractPhoneValue } from '@utils/validations'
import { EMAIL_INPUT_CHAR_LIMIT, PHONE_INPUT_CHAR_LIMIT } from '@constants/input'

interface PhoneEmailInputProps {
  format: 'phone' | 'email'
  submitStringValue: (value?: string) => void
  setInputError: (value: string) => void
  inputError: string
}

export const PhoneEmailInput = ({
  submitStringValue,
  format,
  setInputError,
  inputError,
}: PhoneEmailInputProps) => {
  const localise = useTranslations()
  const [inputValue, setInputValue] = useState('')

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const limit = format === 'phone' ? PHONE_INPUT_CHAR_LIMIT : EMAIL_INPUT_CHAR_LIMIT
    if (e.target.value.length <= limit) {
      setInputValue(e.target.value)
    }
  }

  const handleKeyPress = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      submitValue()
    }
  }

  const submitValue = () => {
    const error = validateInput()

    if (error) {
      setInputError(error)
      return
    }

    submitStringValue(inputValue)
  }

  const validateInput = () => {
    if (format === 'email') {
      const emailValue = extractEmailValue(inputValue)

      if (emailValue === null) {
        return localise('enterValidEmail')
      }
    }

    if (format === 'phone') {
      const phoneValue = extractPhoneValue(inputValue)

      if (phoneValue === null) {
        return localise('enterValidPhone')
      }
    }
  }

  return (
    <>
      {!!inputError && <ErrorMessage text={inputError} />}
      <SimpleInputContainer>
        <StyledTextField
          value={inputValue}
          onChange={onInputChange}
          variant='standard'
          fullWidth
          type={format === 'phone' ? 'tel' : 'email'}
          inputProps={{
            inputMode: format === 'phone' ? 'tel' : 'email',
            'data-testid': ChatDataTestId.TEXT_INPUT,
          }}
          InputProps={{
            disableUnderline: true,
          }}
          placeholder={localise(format === 'phone' ? 'enterValidPhone' : 'enterValidEmail')}
          onKeyPress={handleKeyPress}
          error={!!inputError}
          autoFocus={true}
        />
        <SubmitButton onClick={submitValue}>
          <SendIcon />
        </SubmitButton>
      </SimpleInputContainer>
    </>
  )
}
