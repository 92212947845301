import React from 'react'
import { Link, styled, SxProps, Typography } from '@mui/material'
import useTranslations from '../../localisation/useTranslations'

interface PactumInfoProps {
  clientName: string
  sx?: SxProps
}

export const PactumInfo = ({ clientName, sx }: PactumInfoProps) => {
  const localise = useTranslations()

  return (
    <Typography
      variant='caption'
      component='p'
      sx={{
        pb: 2,
        fontSize: '0.625em',
        opacity: 0.5,
        ...(sx ? sx : {}),
      }}
    >
      {localise('liveSidebar.poweredByPactumPrefix', { name: clientName })}
      <TextLink href='https://pactum.com' target='_blank'>
        Pactum AI
      </TextLink>
      {localise('liveSidebar.poweredByPactumSuffix')}
      <br />
      <PrivacyPolicyText />
    </Typography>
  )
}

export const PrivacyPolicyText = () => {
  const localise = useTranslations()

  return (
    <>
      {localise('dataProcessingSentence')}{' '}
      <TextLink href='https://pactum.com/privacy' target='_blank'>
        {localise('privacyPolicy')}
      </TextLink>
    </>
  )
}

export const TextLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  textDecoration: 'underline',

  '&:hover': {
    textDecoration: 'none',
  },
}))
