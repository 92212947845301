import React from 'react'
import { Box } from '@mui/material'
import { DropdownInput } from './DropdownInput'
import { MultiInputNumberInput } from './NumberInput'
import { SliderInput } from './SliderInput'
import { TextInput } from './TextInput'
import { ChatDataTestId } from '@typedef/chatSteps'
import MultiInputDateInput from './MultiInputDateInput'
import { DropdownInputProps, MultiInputComponentInput } from '@typedef/chatSteps/MultiInputStep'

interface Props {
  inputProps: MultiInputComponentInput
  variableKey: string
  value: string | number | undefined | null
  setValue: (variable: string, value: string | number) => void
  error?: boolean
  isReadOnly: boolean
}

const Input = (props: Props): JSX.Element => {
  const { inputProps, ...otherProps } = props

  const isDropdownInputProps = (
    inputProps: MultiInputComponentInput,
  ): inputProps is DropdownInputProps => {
    const type = inputProps.type ? inputProps.type : 'dropdown'
    return type === 'dropdown'
  }

  const getInputComponent = () => {
    if (isDropdownInputProps(inputProps)) {
      return <DropdownInput {...otherProps} inputProps={inputProps} />
    } else if (inputProps.type === 'date') {
      return <MultiInputDateInput {...otherProps} inputProps={inputProps} />
    } else if (
      inputProps.type === 'text' ||
      inputProps.type === 'email' ||
      inputProps.type === 'phone'
    ) {
      return <TextInput {...otherProps} type={inputProps.type} />
    } else if (inputProps.type === 'slider') {
      return <SliderInput {...otherProps} inputProps={inputProps} />
    } else {
      return (
        <MultiInputNumberInput {...otherProps} numberOptions={{ ...inputProps, type: 'NUMBER' }} />
      )
    }
  }

  return <Box data-testid={ChatDataTestId.MULTI_INPUT_ELEMENT}>{getInputComponent()}</Box>
}

export default Input
