import React from 'react'
import { format, isValid } from 'date-fns'
import { Box, styled, TextField } from '@mui/material'

import { DateInput } from '@containers/ChatInput/DateInput'
import { dateIsInRange, extractValidDateOrNull } from '@utils/validations'
import { DateInputProps } from '@typedef/chatSteps/MultiInputStep'
import { dateIsoFormat } from '@constants/datetime'

interface Props {
  variableKey: string
  value?: number | string | null
  setValue: (variable: string, value: string) => void
  error?: boolean
  isReadOnly: boolean
  inputProps: DateInputProps
}

const MultiInputDateInput = ({
  value,
  setValue,
  variableKey,
  error,
  isReadOnly,
  inputProps,
}: Props): JSX.Element => {
  const getValidStringOrBlank = (
    dateInput: Date | null,
    min: string | null,
    max: string | null,
  ) => {
    return dateInput && isValid(dateInput) && dateIsInRange(dateInput, min, max)
      ? format(dateInput, dateIsoFormat)
      : ''
  }

  return (
    <Box px={1} py={0.5}>
      <DateInput
        TextFieldComponent={StyledDateTextField}
        selectedDate={extractValidDateOrNull(value?.toString())}
        minDate={extractValidDateOrNull(inputProps.min)}
        maxDate={extractValidDateOrNull(inputProps.max)}
        disabled={isReadOnly}
        error={error}
        handleDateChange={(date) => {
          setValue(variableKey, getValidStringOrBlank(date, inputProps.min, inputProps.max))
        }}
      />
    </Box>
  )
}

const StyledDateTextField = styled(TextField)(({ theme }) => ({
  borderRadius: 0,
  fontSize: '0.9375rem',
  height: '2.5rem',
  color: theme.palette.common.black,
  border: 0,

  '& .MuiInput-root': {
    padding: theme.spacing(0, 1),
    borderBottom: `1px solid ${theme.palette.grey['300']}`,
  },

  '& .MuiInput-input': {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
  },

  '& .Mui-error': {
    borderBottom: `1px solid ${theme.palette.error.main}`,
    color: theme.palette.error.main,
  },
})) as typeof TextField

export default MultiInputDateInput
