import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Typography } from '@mui/material'

import { FullPageLoader } from '@components/FullPageLoader/FullPageLoader'
import { ChatErrorPage } from '@pages/index'
import {
  QueryError,
  useExtendNegotiationDeadlineMutation,
  useGetNegotiationParamsQuery,
} from '@store/api'
import { StandalonePageContainer } from '@containers/StandalonePageContainer'
import useTranslations from '../localisation/useTranslations'
import { PageTitle } from '@components/styled'
import { getLocalizedDateTimeWithTimezoneInfo } from '@utils/datetime'

export const DeadlineExtensionPage = () => {
  const { uuid } = useParams<{ uuid: string }>()
  const [extendedDeadline, setExtendedDeadline] = useState<string>()
  const [extensionError, setExtensionError] = useState<QueryError>()

  const { data: negotiationParams, error: negotiationParamsError } =
    useGetNegotiationParamsQuery(uuid)
  const [extendDeadline] = useExtendNegotiationDeadlineMutation()

  const localise = useTranslations()

  useEffect(() => {
    const extendDeadlineDate = async () => {
      try {
        const data = await extendDeadline(uuid).unwrap()
        setExtendedDeadline(data.newDeadline)
      } catch (e) {
        setExtensionError(e as QueryError)
      }
    }

    if (negotiationParams && !negotiationParams.extendedDeadline) {
      extendDeadlineDate()
    }
  }, [negotiationParams, uuid, extendDeadline])

  const error = negotiationParamsError ?? extensionError

  if (error) {
    return <ChatErrorPage queryError={error} />
  }

  const currentDeadline = negotiationParams?.extendedDeadline ?? extendedDeadline

  if (!negotiationParams || !currentDeadline) {
    return <FullPageLoader />
  }

  return (
    <StandalonePageContainer
      logo={negotiationParams.chatParams.headerImage ?? ''}
      clientName={negotiationParams.chatParams.name ?? ''}
    >
      <PageTitle>
        {negotiationParams.extendedDeadline
          ? localise('repetitiveDeadlineExtension')
          : localise('successfulDeadlineExtension')}
      </PageTitle>
      <Typography variant='body2' mt={3}>
        {localise('deadlineDateStatement')}{' '}
        <b>{getLocalizedDateTimeWithTimezoneInfo(currentDeadline)}</b>
      </Typography>
    </StandalonePageContainer>
  )
}
