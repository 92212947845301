import React from 'react'
import { Container, Stack, styled, Typography, useMediaQuery, useTheme } from '@mui/material'

import { HeaderContainer, Logo } from '@components/Header/LiveSidebarHeader'
import VerifiedIcon from '@components/Icons/VerifiedIcon'
import { ChatDataTestId } from '@typedef/testIDs'
import { STATUS_BAR_HEIGHT, TOP_BORDER_HEIGHT } from '@constants/styles'
import useTranslations from '../localisation/useTranslations'
import { PactumInfo } from '@components/PactumInfo'

interface Props {
  logo: string
  clientName: string
  children: React.ReactNode
}

export const StandalonePageContainer = ({ logo, clientName, children }: Props) => {
  const theme = useTheme()
  const isDesktopView = useMediaQuery(theme.breakpoints.up('sm'))
  const localise = useTranslations()

  return (
    <FullPageContainer disableGutters maxWidth={false}>
      <HeaderContainer
        sx={{
          borderBottom: (theme) => `1px solid ${theme.palette.grey['300']}`,
        }}
      >
        <Stack flexDirection='row' alignItems='center'>
          {logo ? <Logo src={logo} alt='Company Logo' /> : null}
        </Stack>
      </HeaderContainer>
      <StatusBar flexDirection='row' alignItems='center' data-testid={ChatDataTestId.STATUS_BAR}>
        <VerifiedIcon sx={{ mr: 1, fontSize: 19 }} />
        <Typography variant='caption'>{localise('sslSecured')}</Typography>
      </StatusBar>
      <Container
        maxWidth={false}
        sx={{ py: isDesktopView ? 12 : 4, maxWidth: '608px', flexGrow: 1 }}
      >
        {children}
      </Container>
      <PactumInfo clientName={clientName} sx={{ textAlign: 'center' }} />
    </FullPageContainer>
  )
}

const FullPageContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  minHeight: '100%',
  borderTop: `${TOP_BORDER_HEIGHT}px solid ${theme.palette.primary.main}`,
}))

const StatusBar = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(0, 2),
  minHeight: `${STATUS_BAR_HEIGHT}px`,
  height: `${STATUS_BAR_HEIGHT}px`,
  borderBottom: `1px solid ${theme.palette.grey['300']}`,

  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(0, 4),
  },
}))
