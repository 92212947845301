import React from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import { Box, Stack, styled, Typography, IconButton, useMediaQuery, useTheme } from '@mui/material'
import { HelpDrawer } from '@components/HelpDrawer/HelpDrawer'

interface Props {
  logo: string
  onMenuClick: () => void
}

export const Header = ({ logo, onMenuClick }: Props): JSX.Element => {
  const theme = useTheme()
  const showMobileMenu = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Container flexDirection='row' justifyContent='space-between' alignItems='center'>
      <Stack flexDirection='row' alignItems='center'>
        {logo ? <Logo src={logo} alt='Logo' /> : null}
      </Stack>
      {showMobileMenu ? (
        <Box ml='auto' mr={0}>
          <Typography variant='button' sx={{ fontSize: '0.66em' }}>
            Menu
          </Typography>
          <IconButton
            aria-label='open menu'
            size='small'
            onClick={() => {
              onMenuClick()
            }}
          >
            <MenuIcon />
          </IconButton>
        </Box>
      ) : null}
      <HelpDrawer />
    </Container>
  )
}

const Container = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(1.5, 4),
  minHeight: 76,
  overflow: 'hidden',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(1, 2),
    minHeight: 48,
  },
}))

const Logo = styled('img')(({ theme }) => ({
  maxHeight: 52,
  [theme.breakpoints.down('md')]: {
    maxHeight: 25,
  },
}))
