import { MultiInputComponentStep, MultiInputComponentSubmitRequest } from './MultiInputStep'
import { SimpleUserInputStep } from './SimpleUserInputStep'
import { CesStep, CesSubmitValue, SubmittedCesStep } from './CesStep'
import { TextStep } from './TextStep'
import { OptionsStep } from './OptionsStep'
import { FileInputStep, FileValue } from './FileInputStep'
import { MultiChoiceStep, MultiChoiceSubmitValue } from './MultiChoiceStep'
import {
  SubmittedTabularDataStep,
  TabularDataStep,
  TabularDataSubmitValue,
} from './TabularDataStep'

export enum StepType {
  TEXT_STEP = 'TEXT_STEP',
  INPUT_STEP = 'INPUT_STEP',
  FILE_INPUT_STEP = 'FILE_INPUT_STEP',
  OPTIONS_STEP = 'OPTIONS_STEP',
  MULTIPLE_CHOICE_STEP = 'MULTIPLE_CHOICE_STEP',
  MULTI_INPUT_STEP = 'MULTI_INPUT_STEP',
  CES_STEP = 'CES_INPUT_STEP',
  TABULAR_DATA_STEP = 'TABULAR_DATA_STEP',
}

export type ChatStep = TextStep | InputStep | SubmittedInputStep

export type InputStep =
  | OptionsStep
  | MultiChoiceStep
  | SimpleUserInputStep
  | MultiInputComponentStep
  | FileInputStep
  | CesStep
  | TabularDataStep

export type SubmittedInputStep = SubmittedCesStep | SubmittedTabularDataStep

export interface ChatStepBase {
  id: string
  type: StepType
  delayInMilliseconds?: number
  timestamp?: string
}

export interface SubmittedInputStepBase<V, S extends ChatStepBase> extends ChatStepBase {
  submittedValue: V
  originalStep: S
  trigger: string | null
}

export type StepSubmitValue =
  | string
  | number
  | MultiChoiceSubmitValue
  | MultiInputComponentSubmitRequest
  | FileValue
  | CesSubmitValue
  | TabularDataSubmitValue

export { ChatDataTestId } from '../testIDs'
export type {
  FileInputStep,
  CesStep,
  SubmittedCesStep,
  SubmittedTabularDataStep,
  MultiChoiceStep,
  OptionsStep,
  SimpleUserInputStep,
  TabularDataStep,
  TextStep,
}
export type {
  MultiChoiceSubmitValue,
  MultiInputComponentSubmitRequest,
  FileValue,
  CesSubmitValue,
  TabularDataSubmitValue,
}
export * from './guards'
