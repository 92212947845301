import { styled, TextField, TextFieldProps } from '@mui/material'

export const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'textInput',
})<TextFieldProps & { textInput?: boolean }>(({ theme, textInput }) => ({
  '& .MuiInputBase-root': {
    borderRadius: 0,
    margin: theme.spacing(0, 1),
  },
  '& .MuiOutlinedInput-input': {
    fontSize: '0.9375em',
    height: '1.4375em',
    padding: '6px',
    color: theme.palette.common.black,
    borderRadius: 0,
    width: textInput ? '136px' : '75px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.grey['300'],
  },
}))
