import React from 'react'
import { FullPageError } from '@components/FullPageError/FullPageError'
import { useChatParams } from '@store/selectors'
import { DEFAULT_SUPPORT_EMAIL } from '@constants/errors'

const AUTH_ERROR_TITLE = 'Access Denied'
const AUTH_ERROR_MESSAGE =
  'Your account does not have authorization to access this page. Please contact our support if you think this is a mistake'

const AuthErrorPage = (): JSX.Element => {
  const chatParams = useChatParams()
  const supportEmail = chatParams?.supportEmail || DEFAULT_SUPPORT_EMAIL

  return (
    <FullPageError title={AUTH_ERROR_TITLE} supportEmail={supportEmail}>
      <p>{AUTH_ERROR_MESSAGE}</p>
    </FullPageError>
  )
}

export default AuthErrorPage
