import React from 'react'
import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import { Alert, Box, Button } from '@mui/material'

import {
  DEFAULT_SUPPORT_EMAIL,
  ERROR_TRANSLATION_KEYS,
  getDefaultErrorMessage,
} from 'constants/errors'
import useTranslations from 'localisation/useTranslations'
import { ChatDataTestId } from '@typedef/chatSteps'
import { REQUEST_TIMEOUT_ERROR } from 'store/api/baseQuery'
import { parseQueryError } from 'store/api/error'
import { useChatParams } from '@store/selectors'

type RetryOption = 'RELOAD' | 'REFETCH'

export const ChatBoxApiError = ({
  chatStepError,
  retryPreviousStep,
}: {
  chatStepError: FetchBaseQueryError | SerializedError
  retryPreviousStep: () => void
}): JSX.Element => {
  const localise = useTranslations()
  const { supportEmail } = useChatParams() ?? {}

  let errorMessage: string
  let errorElementTestId: string | null = null
  let retryOption: RetryOption | undefined = 'REFETCH'

  const { status, errorInternalCode, errorMsg } = parseQueryError(chatStepError)

  if (errorInternalCode !== undefined) {
    errorMessage = localise(ERROR_TRANSLATION_KEYS[errorInternalCode])
    if (errorInternalCode === 'STATE_STALE') {
      retryOption = 'RELOAD'
    } else {
      retryOption = undefined
    }
  } else if (typeof status === 'number' && status >= 500) {
    errorMessage = localise(ERROR_TRANSLATION_KEYS['SERVER_ERROR'])
  } else if (status === 'FETCH_ERROR') {
    errorMessage = localise(ERROR_TRANSLATION_KEYS['NETWORK_ERROR_SHORT'])
    errorElementTestId = ChatDataTestId.ALERT_CHATBOX_NETWORK_ERROR
    if (errorMsg === REQUEST_TIMEOUT_ERROR) {
      retryOption = 'RELOAD'
    }
  } else {
    errorMessage = getDefaultErrorMessage(supportEmail || DEFAULT_SUPPORT_EMAIL)
  }

  return (
    <Alert
      data-testid={ChatDataTestId.ALERT_STEP_ERROR}
      severity='error'
      sx={{ alignItems: 'center' }}
      action={
        <>
          {retryOption && (
            <Button
              color='inherit'
              size='small'
              onClick={() => {
                if (retryOption === 'RELOAD') {
                  window.location.reload()
                } else {
                  retryPreviousStep()
                }
              }}
            >
              {retryOption === 'RELOAD' ? 'Reload' : 'Retry'}
            </Button>
          )}
        </>
      }
    >
      <Box {...(errorElementTestId ? { 'data-testid': errorElementTestId } : {})}>
        {errorMessage}
      </Box>
    </Alert>
  )
}
