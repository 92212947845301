import React, { useContext } from 'react'
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom'

import { ContactConfirmationResponse, useGetContactConfirmationQuery } from '@store/api'
import { ConfirmationForm } from '@pages/ContactConfirmation/ConfirmationForm'
import { DescriptionText, ResultPage } from '@pages/ContactConfirmation/ResultPage'
import { FullPageLoader } from '@components/FullPageLoader/FullPageLoader'
import { PageTitle } from '@components/styled'
import { ChatErrorPage } from '@pages/index'
import useTranslations from '../../localisation/useTranslations'
import { StandalonePageContainer } from '@containers/StandalonePageContainer'

const ContactConfirmationContext = React.createContext<ContactConfirmationResponse | undefined>(
  undefined,
)

export const useContactConfirmationContext = () => {
  const context = useContext(ContactConfirmationContext)
  if (context === undefined) {
    throw new Error(
      'useContactConfirmationContext must be used within a ContactConfirmationContextProvider',
    )
  }
  return context
}

export const ContactConfirmationPage = () => {
  const { uuid } = useParams<{ uuid: string }>()
  const { isLoading, data, error, isError } = useGetContactConfirmationQuery(uuid)
  const { path } = useRouteMatch()
  const localise = useTranslations()

  if (isError) {
    return <ChatErrorPage queryError={error} />
  }

  if (!data || isLoading) {
    return <FullPageLoader />
  }

  return (
    <StandalonePageContainer
      logo={data.negotiationParams.headerImage ?? ''}
      clientName={data.negotiationParams.name ?? ''}
    >
      <ContactConfirmationContext.Provider value={data}>
        <Switch>
          <Route exact path={path}>
            <ConfirmationForm />
          </Route>
          <Route exact path={`${path}/result`}>
            <ResultPage />
          </Route>
          <Route>
            <PageTitle component='h1'>404 {localise('errorNotFound')}</PageTitle>
            <DescriptionText>{localise('err_negotiation_not_found')}</DescriptionText>
          </Route>
        </Switch>
      </ContactConfirmationContext.Provider>
    </StandalonePageContainer>
  )
}
