import React from 'react'
import posthog from 'posthog-js'
import { FullPageError } from '../FullPageError/FullPageError'
import { DEFAULT_SUPPORT_EMAIL } from '@constants/errors'

const UnsupportedBrowser = (): JSX.Element => {
  if (posthog && 'capture' in posthog && typeof posthog.capture === 'function') {
    posthog.capture('UnsupportedBrowser')
  }

  return (
    <FullPageError title={'Unsupported Browser'} supportEmail={DEFAULT_SUPPORT_EMAIL}>
      <p>
        We are sorry, but your browser is not supported. Please use a more modern browser to open
        this link.
      </p>
    </FullPageError>
  )
}

export default UnsupportedBrowser
