import React, { useState } from 'react'
import { captureException } from '@sentry/react'
import parseStyleToJS from 'style-to-js'
import { Box, Dialog } from '@mui/material'
import parse, { DOMNode } from 'html-react-parser'

import { replaceables } from '../../webComponents'
import { sanitizeHTML } from '@utils/sanitize'
import { useDebugToolbar } from '@context/DebugToolbar'

export const HTML = ({ markup }: { markup: string }) => {
  const { open } = useDebugToolbar()
  const sanitized = sanitizeHTML(markup)

  return (
    <>
      {parse(sanitized, {
        replace(domNode: DOMNode) {
          if (domNode.type === 'tag' && replaceables[domNode.tagName]) {
            let replacement = null

            try {
              replacement = replaceables[domNode.tagName](domNode)
            } catch (e) {
              if (open) {
                replacement =
                  e instanceof Error ? (
                    <>
                      {e.message}
                      <ErrorDialog error={e} />
                    </>
                  ) : (
                    'An error occurred when rendering chat component. (Please check the browser console)'
                  )
                captureException(e)
              } else {
                throw e
              }
            }

            if (!replacement) {
              return replacement
            }

            //Handle inline style of a custom element. Passing it as a string to React element
            //won't work, we need to convert it to a JS object
            const inlineStyle = domNode.attribs['style']
            if (!inlineStyle) {
              return <Box sx={{ display: 'inline' }}>{replacement}</Box>
            }

            return React.cloneElement(<Box sx={{ display: 'inline' }}>{replacement}</Box>, {
              style: parseStyleToJS(inlineStyle),
            })
          }
        },
      })}
    </>
  )
}

const ErrorDialog = ({ error }: { error: Error }) => {
  const [open, setOpen] = useState(true)

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <Box p={2}>
        <b>{error.message}</b>
        <p>
          You can search for error message on the page to find where exactly the error occurred.
        </p>
        <pre>{error.stack}</pre>
      </Box>
    </Dialog>
  )
}
