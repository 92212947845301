export const exhaustiveSwitchGuard = (
  _: never,
  options?: { err?: string; throw?: boolean },
): void => {
  const errorMessage = options?.err ?? 'Switch statement is not exhaustive'
  if (options?.throw) {
    throw new Error(errorMessage)
  }
  console.error(errorMessage)
}
