import isNil from 'lodash/isNil'
import { isFiniteNumber } from '@utils/typeGuards'
import {
  MultiInputComponentSection,
  MultiInputComponentValues,
  DynamicOperator,
  DynamicOperatorFun,
  MultiInputExpression,
  Operand,
} from '@typedef/chatSteps/MultiInputStep'

export const dynamicOperators: Record<DynamicOperator, DynamicOperatorFun> = {
  '<': (arg1: Operand, arg2: Operand) => (isNil(arg1) || isNil(arg2) ? true : arg1 < arg2),
  '<=': (arg1: Operand, arg2: Operand) => (isNil(arg1) || isNil(arg2) ? true : arg1 <= arg2),
  '>': (arg1: Operand, arg2: Operand) => (isNil(arg1) || isNil(arg2) ? true : arg1 > arg2),
  '>=': (arg1: Operand, arg2: Operand) => (isNil(arg1) || isNil(arg2) ? true : arg1 >= arg2),
  '=': (arg1: Operand, arg2: Operand) => arg1 === arg2,
  '!=': (arg1: Operand, arg2: Operand) => arg1 !== arg2,
}

export const isSectionVisible = (
  section: MultiInputComponentSection,
  values: MultiInputComponentValues,
  allVariables: string[],
): boolean => {
  if (!section.visibility) {
    return true
  }

  return evaluateExpression(section.visibility, values, allVariables)
}

export const evaluateExpression = (
  rule: MultiInputExpression,
  values: MultiInputComponentValues,
  allVariables: string[],
): boolean => {
  const leftArgument = getDynamicOperatorArgument(rule[1], values, allVariables)
  const rightArgument = getDynamicOperatorArgument(rule[2], values, allVariables)
  return dynamicOperators[rule[0]](leftArgument, rightArgument)
}

// rawArgument should be either a numeric constant or a variable name string
// variable values in some flows currently come in as strings even though they represent numbers, so need to try to parse them
const getDynamicOperatorArgument = (
  rawArgument: string | number | null,
  values: MultiInputComponentValues,
  allVariables: string[],
): number | string | null | undefined => {
  if (rawArgument === null || typeof rawArgument === 'number') {
    return rawArgument
  }
  if (!allVariables.includes(rawArgument)) {
    const asNumber = parseFloat(rawArgument)
    return isFiniteNumber(asNumber) ? asNumber : rawArgument
  }

  const variableValue = values[rawArgument]

  if (isFiniteNumber(variableValue)) {
    return variableValue
  } else if (variableValue === undefined || variableValue === null) {
    return variableValue
  } else {
    const variableValueAsNumber = parseFloat(variableValue)
    if (isFiniteNumber(variableValueAsNumber)) {
      return variableValueAsNumber
    }
    return variableValue
  }
}
