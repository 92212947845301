import React, { useEffect } from 'react'
import { lightTheme } from 'utils/Themes'
import { ThemeProvider, createTheme } from '@mui/material'
import { useChatParams } from 'store/selectors'

interface PactumPaletteType {
  black: React.CSSProperties['color']
  gray: React.CSSProperties['color']
  lightGray: React.CSSProperties['color']
  darkGray: React.CSSProperties['color']
  backgroundGray: React.CSSProperties['color']
  tableRowHoverGray: React.CSSProperties['color']
  customChatTableColor: React.CSSProperties['color']
}

declare module '@mui/material/styles/createPalette' {
  type PactumPalette = PactumPaletteType

  interface Palette {
    pactum: PactumPalette
  }
  interface PaletteOptions {
    pactum: PactumPalette
  }
}

type Props = {
  children?: React.ReactNode
}

export const PactumThemeProvider = ({ children }: Props): JSX.Element => {
  const chatParams = useChatParams()
  const defaultTheme = lightTheme
  const customColor = chatParams?.theme?.color || chatParams?.color
  const tableColor = chatParams?.theme?.tableColor || customColor

  const theme = {
    ...defaultTheme,
    palette: {
      ...defaultTheme.palette,
      primary: {
        main: customColor || defaultTheme.palette.primary.main,
      },
      pactum: {
        black: '#262626',
        gray: '#CCD2D3',
        lightGray: '#E7EAEB',
        darkGray: '#828282',
        backgroundGray: '#F2F4F5',
        tableRowHoverGray: '#F7F7F7',
        customChatTableColor: tableColor,
      },
    },
  }

  useEffect(() => {
    if (chatParams) {
      document.documentElement.style.setProperty(
        '--custom-chat-table-color',
        tableColor ?? 'initial',
      )
      document.documentElement.style.setProperty(
        '--primary-color',
        customColor ?? defaultTheme.palette.primary.main,
      )
    }
  }, [tableColor, customColor, defaultTheme.palette.primary.main, chatParams])

  return <ThemeProvider theme={createTheme(theme)}>{children}</ThemeProvider>
}
