import React, { useEffect } from 'react'

const useMutationObserver = (
  ref: React.MutableRefObject<Node | null>,
  callback: (mutations: MutationRecord[], observer: MutationObserver) => void,
  options: MutationObserverInit = {
    attributes: true,
    characterData: true,
    childList: true,
    subtree: true,
  },
) => {
  useEffect(() => {
    if (ref.current) {
      const observer = new MutationObserver(callback)
      observer.observe(ref.current, options)
      return () => observer.disconnect()
    }
  }, [callback, options, ref])
}

export default useMutationObserver
