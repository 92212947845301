import { MutableRefObject, useEffect, useRef } from 'react'

/**
 * usePrevious hook described here:
 * @see {@link https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state}
 * @param {*} value - a value to preserve previous for
 * @param defaultValue - optional default value
 */
export default function usePrevious<T>(
  value: T,
  defaultValue?: T,
): MutableRefObject<T | undefined>['current'] {
  const ref = useRef<T | undefined>(defaultValue)

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}
