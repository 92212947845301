import { format, parseISO } from 'date-fns'

export const getLocalizedDateTimeWithTimezoneInfo = (dateTimeISOString: string) => {
  const { localizedDeadlineTime, offsetString } =
    extractLocalizedTimestampWithOffset(dateTimeISOString)

  return `${format(localizedDeadlineTime, 'yyyy-MM-dd HH:mm')} [${
    Intl.DateTimeFormat().resolvedOptions().timeZone
  } ${offsetString}]`
}

export const getLocalizedDateTimeWithTimezoneOffset = (dateTimeISOString: string) => {
  const { localizedDeadlineTime, offsetString } =
    extractLocalizedTimestampWithOffset(dateTimeISOString)

  return `${format(localizedDeadlineTime, 'yyyy-MM-dd HH:mm')} [${offsetString}]`
}

const extractLocalizedTimestampWithOffset = (dateTimeISOString: string) => {
  const localizedDeadlineTime = parseISO(dateTimeISOString)
  const timezoneOffset = -localizedDeadlineTime.getTimezoneOffset() / 60
  const offsetString = timezoneOffset >= 0 ? `GMT+${timezoneOffset}` : `GMT${timezoneOffset}`

  return { localizedDeadlineTime, offsetString }
}
