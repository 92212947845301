import { Translations } from '../localisation/translations'
import { isAfter, isBefore, isSameDay, isValid, parseISO } from 'date-fns'

export const extractNumericalValue = (text: string): number => {
  const whiteSpaceRegex = /\s/g
  const currencyRegex = /[$£€]/g
  text = text
    .replace(whiteSpaceRegex, '')
    .replace(currencyRegex, '')
    .replace('%', '')
    .replace(',', '.')

  return text === '' ? NaN : Number(text) >= 0 ? Number(text) : NaN
}

export const extractDateValue = (date: Date | null): Date | null =>
  date && isValid(date) ? date : null

export const dateIsInRange = (date: Date | null, min?: string | null, max?: string | null) => {
  if (!date) return false

  const minDate = min && isValid(parseISO(min)) ? parseISO(min) : null
  const maxDate = max && isValid(parseISO(max)) ? parseISO(max) : null

  return (
    (!minDate || isSameDay(minDate, date) || isBefore(minDate, date)) &&
    (!maxDate || isSameDay(maxDate, date) || isAfter(maxDate, date))
  )
}

export const validateNumber = (
  value: number,
  min: number | null | undefined,
  max: number | null | undefined,
): keyof Translations | undefined => {
  const hasMin = typeof min === 'number'
  const hasMax = typeof max === 'number'

  if (hasMin && hasMax) {
    if (value < min || value > max) {
      return 'numberOutOfRange'
    }
  }

  if (hasMin && value < min) {
    return 'numberTooLow'
  }

  if (hasMax && value > max) {
    return 'numberTooHigh'
  }

  return undefined
}

export const extractValidDateOrNull = (dateInput: string | undefined | null) => {
  if (!dateInput) return null
  const parsedDate = parseISO(dateInput)
  return isValid(parsedDate) ? parsedDate : null
}

export const extractPhoneValue = (text: string): string | null => {
  const regex = /([0-9\s-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/
  const isPhoneValid = regex.test(text)
  return isPhoneValid ? text : null
}

export const extractEmailValue = (text: string): string | null => {
  const regex = /^[a-zA-Z0-9_+&*'-]+(?:\.[a-zA-Z0-9_+&*'-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,15}$/
  const isEmailValid = regex.test(text)
  return isEmailValid ? text : null
}
