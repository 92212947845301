import React, { createContext } from 'react'

interface StepContextProps {
  stepId: string
}

const StepContext = createContext<StepContextProps | null>(null)

export const StepContextProvider = (props: StepContextProps & { children: React.ReactNode }) => {
  return <StepContext.Provider value={props}>{props.children}</StepContext.Provider>
}
export const useStepId = (): string | null => {
  const context = React.useContext(StepContext)
  if (!context) {
    return null
  }
  return context.stepId
}
