import Cookies, { CookieSetOptions } from 'universal-cookie'
import { environment } from '@environments/environment'

const cookies = new Cookies()

const getCookieSubDomain = () => {
  if (environment.REACT_APP_ENV === 'development' || !environment.REACT_APP_ENV) {
    return 'localhost'
  }

  return window.location.host
}

const cookieSubDomain = getCookieSubDomain()

export const setCookie = (
  name: string,
  value: string,
  options: Partial<CookieSetOptions> = {},
): void => {
  cookies.set(name, value, {
    domain: cookieSubDomain,
    path: '/',
    secure: true,
    maxAge: 3600 + 60, // 1h + 1min leeway
    sameSite: cookieSubDomain === 'localhost' ? 'none' : 'strict',
    ...options,
  })
}

export const getCookie = (name: string): string | null => cookies.get<string>(name)
