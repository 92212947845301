import React from 'react'
import CookieConsent from 'react-cookie-consent'
import { styled, Typography, Link, Box } from '@mui/material'

import { shouldShowCookieConsent } from '@pactum/frontend-utils'
import { ChatDataTestId } from '@typedef/chatSteps'
import { initializePosthog } from 'utils/posthog'

const onAcceptCookieConsent = () => {
  initializePosthog(true)
}

const CookiePolicy = (): JSX.Element => {
  return (
    <div data-testid={ChatDataTestId.COOKIE_CONSENT}>
      {shouldShowCookieConsent() && (
        <StyleContainer>
          <CookieConsent
            location='none'
            containerClasses='cookieBox'
            buttonText='Allow all'
            declineButtonText='Deny'
            buttonWrapperClasses='cookieButtonWrapper'
            buttonClasses='cookieButton'
            declineButtonClasses='cookieButton'
            enableDeclineButton
            expires={182} // in days; which means half a year (or actually a bit less, but this is fine)
            onAccept={onAcceptCookieConsent}
            disableStyles
          >
            <PolicyText>
              <span>
                We use cookies to provide you with the best user experience. By clicking "Allow
                all", you consent to the use of all cookies. Selecting "Deny", you consent to
                functional cookies. Read more about our{' '}
              </span>
              <PolicyLink href='https://pactum.com/cookie-policy/' target='_blank'>
                Cookie Policy
              </PolicyLink>
              {' and '}
              <PolicyLink href='https://pactum.com/privacy/' target='_blank'>
                Privacy Policy
              </PolicyLink>
            </PolicyText>
          </CookieConsent>
        </StyleContainer>
      )}
    </div>
  )
}

const PolicyText = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  marginLeft: theme.spacing(1),
}))

const PolicyLink = styled(Link)(({ theme }) => ({
  color: theme.palette.info.contrastText,
  fontWeight: 'bolder',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}))

const StyleContainer = styled(Box)(({ theme }) => ({
  '& .cookieBox': {
    position: 'fixed',
    left: theme.spacing(2),
    bottom: theme.spacing(2),
    zIndex: 3,
    marginRight: theme.spacing(2), // centering for small devices

    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: '400px',
    padding: theme.spacing(2),
    borderRadius: '8px',

    backgroundColor: theme.palette.primary.main,
    color: 'white',

    boxShadow: '0px 4px 21px 8px rgb(0 0 0 / 12%)',
  },
  '& .cookieButton': {
    width: '40%',
    padding: theme.spacing(1.5, 3),
    backgroundColor: theme.palette.secondary.main,
    border: 0,
    borderRadius: 3,

    fontSize: '1rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  '& .cookieButtonWrapper': {
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(4),
    width: '100%',
    marginTop: theme.spacing(3),
  },
}))

export default CookiePolicy
