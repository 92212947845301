import React from 'react'
import { SvgIcon, SxProps } from '@mui/material'

interface Props {
  sx: SxProps
  color?: string
}

const VerifiedIcon = ({ sx, color }: Props): JSX.Element => {
  return (
    <SvgIcon sx={sx} width='16' height='19' viewBox='0 0 16 19' fill='none'>
      <path
        d='M8.00002 0C8.00002 0 4.01018 3.73646 0 3.73646C0 7.38889 0.0202606 10.9562 1.49619 13.7222C3.01265 16.5507 5.9652 18.5142 8.00002 19C10.0153 18.4508 12.8479 16.4451 14.3441 13.6802C15.8402 10.9142 16 7.38889 16 3.71598C11.9898 3.73659 7.99998 0 7.99998 0H8.00002ZM12.1496 7.7691L7.84023 13.954C7.70074 14.1437 7.48098 14.2707 7.26202 14.2921H7.20202C6.98226 14.2921 6.78277 14.208 6.62381 14.039L4.20956 11.4421C3.91032 11.104 3.91032 10.5762 4.20956 10.2389C4.52907 9.92226 5.0278 9.92226 5.3465 10.2389L7.12166 12.1389L10.8723 6.7556C11.1318 6.37543 11.6305 6.29132 11.9898 6.58654C12.3288 6.86116 12.409 7.38894 12.1495 7.7691L12.1496 7.7691Z'
        fill={color ?? '#27AE60'}
      />
    </SvgIcon>
  )
}

export default VerifiedIcon
