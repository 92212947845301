import { getApiBaseUrl } from '@store/api/baseQuery'
import { ChatParamsResponse } from '@store/api'

export const replaceApiPlaceholdersInHtml = (
  html: string,
  chatParams: ChatParamsResponse,
  linkToken: string,
): string => {
  return html
    .replaceAll(/\$apiUrl/g, getApiBaseUrl())
    .replaceAll(/\$stateId/g, chatParams.stateId)
    .replaceAll(/\$linkToken/g, linkToken)
}
