import React, { useLayoutEffect, useRef } from 'react'
import { CircularProgress, InputAdornment, TextField } from '@mui/material'
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-pro'
import { TabularDataRow } from '@pactum/core-backend-types'
import { ChangedCellDef, UpdateTableState } from '../columns'
import {
  getRowsForTableUpdate,
  isCellUpdating,
} from '@components/InputSteps/TabularDataStep/Cells/helpers'

interface Props {
  params: GridRenderEditCellParams<TabularDataRow, string>
  updateTableState: UpdateTableState
  disabled: boolean
  changedCell: ChangedCellDef | null
}

export const TextEditCell = ({ params, updateTableState, disabled, changedCell }: Props) => {
  const apiRef = useGridApiContext()
  const { id, value, field, hasFocus } = params
  const ref = useRef<HTMLDivElement>(null)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    apiRef.current.setEditCellValue({ id, field, value: e.target.value })
  }

  const inputProps = isCellUpdating(changedCell, String(id), field)
    ? {
        endAdornment: (
          <InputAdornment position='end'>
            <CircularProgress disableShrink size='1rem' />
          </InputAdornment>
        ),
      }
    : {}

  const onPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedData = e.clipboardData.getData('text')
    const values = pastedData.split('\n')

    e.preventDefault()
    const updatedRows = getRowsForTableUpdate(values, apiRef, id, field, (v) => v)
    apiRef.current.updateRows(updatedRows)
    updateTableState(updatedRows)
  }

  useLayoutEffect(() => {
    if (hasFocus && ref.current) {
      const input = ref.current.querySelector<HTMLInputElement>(`input[value="${value}"]`)
      input?.focus()
    }
  }, [hasFocus, value])

  return (
    <TextField
      ref={ref}
      value={value}
      onPaste={onPaste}
      onChange={onChange}
      size='small'
      sx={{ verticalAlign: 'middle' }}
      disabled={disabled}
      InputProps={inputProps}
    />
  )
}
