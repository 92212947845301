import { createSlice } from '@reduxjs/toolkit'
import { NegotiationStage } from '@pactum/core-backend-types'
import { ChatStep } from '@typedef/chatSteps'
import { baseApi, LinkAuthInfo } from '../api'

interface ChatState {
  steps: ChatStep[]
  sidebarHtml: string | null
  chatStage: NegotiationStage['id'] | null
  timeoutError: boolean
  authInfo: LinkAuthInfo
}

const initialState: ChatState = {
  steps: [],
  sidebarHtml: null,
  chatStage: null,
  timeoutError: false,
  authInfo: {
    isMainLink: false,
    readOnly: false,
    loginRequired: false,
    orgId: '',
    supplierMessageForUneditableChat: null,
    isLinkProtected: false,
  },
}

const chatSlice = createSlice({
  name: 'chat',
  initialState: initialState,
  reducers: {
    setTimeoutError(state) {
      state.timeoutError = true
    },
    clearTimeoutError(state) {
      state.timeoutError = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(baseApi.endpoints.getLinkAuthInfo.matchFulfilled, (state, action) => {
        state.authInfo = action.payload
      })
      .addMatcher(baseApi.endpoints.getNextStep.matchFulfilled, (state, action) => {
        const { steps, sidebarHtml, chatStage, authInfo } = action.payload
        if (authInfo.readOnly) {
          state.steps = steps
        } else {
          state.steps.push(...steps)
        }
        state.sidebarHtml = sidebarHtml
        state.chatStage = chatStage
        state.authInfo = authInfo
      })
  },
})

export const { setTimeoutError, clearTimeoutError } = chatSlice.actions

export default chatSlice.reducer
