import React, { useState } from 'react'
import { Box, FormControl, TextField } from '@mui/material'
import { ChatDataTestId } from '@typedef/chatSteps'
import { NumberInputProperties } from '@typedef/chatSteps/SimpleUserInputStep'
import { StyledTextField } from './StyledTextField'
import { formatNumber, NumberInput } from '@containers/ChatInput/NumberInput'
import useTranslations from '../../../localisation/useTranslations'
import { useNumberLocale } from '@hooks/useNumberLocale'
import { PactumTooltip } from '@components/PactumTooltip'

interface Props {
  variableKey: string
  value?: number | string | null
  setValue: (variable: string, value: string | number) => void
  error?: boolean
  isReadOnly: boolean
  numberOptions?: NumberInputProperties
}

export const MultiInputNumberInput = ({
  value,
  setValue,
  variableKey,
  error,
  isReadOnly,
  numberOptions,
}: Props): JSX.Element => {
  const localise = useTranslations()
  const numberLocale = useNumberLocale()
  const [focused, setFocused] = useState(false)

  const update = (parsed: number | null) => {
    setValue(variableKey, parsed ?? '')
  }

  const formattedValue =
    value === 0 || !!value ? formatNumber(value, numberLocale, numberOptions?.numberFormat) : ''

  return (
    <FormControl data-testid={ChatDataTestId.MULTI_INPUT_NUMBER_INPUT}>
      <PactumTooltip
        open={!!value && focused}
        title={
          value === 0 || !!value
            ? localise('numberInput.helperText', { value: formattedValue })
            : ''
        }
        placement='top-start'
      >
        <Box>
          <NumberInput
            TextFieldComponent={StyledTextField as typeof TextField}
            numberLocale={numberLocale}
            value={value ?? null}
            onValidInput={update}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            required={false}
            inputProps={{ 'aria-label': 'Number input label' }}
            error={error}
            disabled={isReadOnly}
            variant='outlined'
            formatOptions={numberOptions?.numberFormat}
            placeholderTranslationKey={'numberInput.placeholderShort'}
          />
        </Box>
      </PactumTooltip>
    </FormControl>
  )
}
