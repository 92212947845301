import React from 'react'
import { Box, Stack, IconButton } from '@mui/material'
import BranchingLinkIcon from '@mui/icons-material/CallSplit'
import { getBranchingUrl } from '@utils/branchingUrl'
import { useGetDebugQuery } from '@store/api'
import { useChatParams } from '@store/selectors'
import { NegotiationDesignerIcon } from '@components/Icons/NegotiationDesignerIcon'
import { isNegotiationDesignerFlow, negotiationDesignerLink } from '@constants/negotiationDesigner'

interface Props {
  stateUrl: string
  stepId: string
  stepCount: number
  isUserStep: boolean
}

const negotiationDesignerLinkToNode = (driveId: string, stepId: string): string =>
  `${negotiationDesignerLink(driveId)}?nodeId=${stepId}`

export const ChatMessageHelpers = ({ stepId, stateUrl, stepCount, isUserStep }: Props) => {
  const chatParams = useChatParams()
  const stateId = chatParams?.stateId
  const { data: debugState } = useGetDebugQuery(stateId ?? '')
  const isNedFlow = isNegotiationDesignerFlow(debugState?.assets.flow)
  const driveFileId = debugState?.assets.flow.storageKey

  return (
    <Box mt={-1.5}>
      <Stack direction='row' position='absolute'>
        <IconButton
          title='Branch from this step'
          color='primary'
          size='small'
          href={getBranchingUrl(stateUrl, stepId, stepCount)}
          target='_blank'
          sx={{ fontSize: '16px' }}
        >
          <BranchingLinkIcon fontSize='inherit' />
        </IconButton>
        {isNedFlow && !isUserStep && driveFileId && (
          <IconButton
            title='Open in Negotiation Designer'
            href={negotiationDesignerLinkToNode(driveFileId, stepId)}
            size='small'
            target='_blank'
            sx={{ fontSize: '16px' }}
          >
            <NegotiationDesignerIcon />
          </IconButton>
        )}
      </Stack>
    </Box>
  )
}
