import React from 'react'
import { FullPageError } from '../FullPageError/FullPageError'
import useTranslations from '../../localisation/useTranslations'
import { useChatParams } from '@store/selectors'
import { DEFAULT_SUPPORT_EMAIL } from '@constants/errors'

const NoRoute = (): JSX.Element => {
  const localise = useTranslations()
  const chatParams = useChatParams()
  const supportEmail = chatParams?.supportEmail || DEFAULT_SUPPORT_EMAIL

  return (
    <FullPageError title={'404 Not found'} supportEmail={supportEmail}>
      <p>{localise('err_negotiation_not_found')}</p>
    </FullPageError>
  )
}

export default NoRoute
