import React, { useEffect, useRef, useState } from 'react'
import { Box, Paper, Stack, StackProps, styled, Typography } from '@mui/material'
import { Close, Minimize } from '@mui/icons-material'

type Coordinates = {
  x: number
  y: number
}

type DragState = {
  position: Coordinates
  relative: Coordinates
  dragging: boolean
}

type Props = {
  isOpen: boolean
  title: React.ReactNode
  children?: React.ReactNode
  onClose?: () => unknown
}

const DraggableDialog = ({ isOpen, title, children, onClose }: Props): JSX.Element | null => {
  const [collapsed, setCollapsed] = useState(false)
  const [dragState, setDragState] = useState<DragState>({
    position: { x: window ? window.outerWidth / 2 : 0, y: 20 },
    relative: { x: 0, y: 0 },
    dragging: false,
  })

  const ref = useRef<HTMLDivElement>(null)

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement> | MouseEvent) => {
    const element = ref.current

    if (!element) {
      return
    }

    setDragState({
      ...dragState,
      dragging: true,
      relative: {
        x: e.pageX - element.offsetLeft,
        y: e.pageY - element.offsetTop,
      },
    })

    e.stopPropagation()
    e.preventDefault()
  }

  const onMouseUp = (e: React.DragEvent<HTMLDivElement> | MouseEvent) => {
    setDragState({ ...dragState, dragging: false })
    e.stopPropagation()
    e.preventDefault()
  }

  const onMouseMove = (e: React.DragEvent<HTMLDivElement> | MouseEvent) => {
    if (!dragState.dragging) {
      return
    }

    setDragState({
      ...dragState,
      position: {
        x: e.pageX - dragState.relative.x,
        y: e.pageY - dragState.relative.y,
      },
    })

    e.stopPropagation()
    e.preventDefault()
  }

  useEffect(() => {
    document.addEventListener('mousemove', onMouseMove)
    document.addEventListener('mouseup', onMouseUp)

    return () => {
      document.removeEventListener('mousemove', onMouseMove)
      document.removeEventListener('mouseup', onMouseUp)
    }
  })

  if (!isOpen) {
    return null
  }

  return (
    <DialogContainer
      collapsed={collapsed}
      ref={ref}
      style={{
        top: dragState.position.y,
        left: dragState.position.x,
      }}
    >
      <Stack>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          p={1}
          sx={{ borderBottom: '.5px solid #0000000f', cursor: 'grab' }}
          onMouseDown={onMouseDown}
          onDoubleClick={() => setCollapsed(!collapsed)}
        >
          <Typography component='h2'>{title}</Typography>

          <Box>
            <Minimize
              sx={{ fontSize: '2rem', mr: 1, cursor: 'pointer' }}
              onClick={() => setCollapsed(!collapsed)}
            />
            <Close sx={{ cursor: 'pointer' }} onClick={onClose} />
          </Box>
        </Stack>
        <Box>{!collapsed ? children : null}</Box>
      </Stack>
    </DialogContainer>
  )
}

const DialogContainer = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'collapsed',
})<StackProps & { collapsed: boolean }>(({ collapsed, theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
  padding: theme.spacing(2),
  height: 'auto',
  minHeight: '300px',
  width: '440px',
  minWidth: '440px',
  zIndex: 1000,
  resize: 'both',
  overflow: 'auto',
  backgroundColor: theme.palette.grey.A100,

  ...(collapsed
    ? {
        height: 'auto !important',
        minHeight: 'auto !important',
        resize: 'horizontal',
      }
    : {}),
}))
export default DraggableDialog
