import React, { ChangeEvent, useState } from 'react'
import { Alert, Box, Stack, styled, TextField, Typography } from '@mui/material'

import { ChatDataTestId } from '@typedef/testIDs'
import {
  CesStep as CesStepType,
  CesSubmitValue,
  StepType,
  SubmittedCesStep as SubmittedCesStepType,
} from '@typedef/chatSteps'
import ChatMessage from '@components/ChatMessage/ChatMessage'
import useTranslations from '../../../localisation/useTranslations'
import ChipButton from '@components/ChipButton/ChipButton'
import { SimulatorCes } from '@utils/simulator/inputs'
import { ScoreSelection } from '@components/InputSteps/CesStep/ScoreSelection'
import { SubmittedCesStep } from './SubmittedCesStep'
import { CES_COMMENT_INPUT_CHAR_LIMIT } from '@constants/input'

export interface Props {
  step: CesStepType | SubmittedCesStepType
  sendValue: (value: CesSubmitValue, stepInput: SimulatorCes, label?: string) => void
}

export const CesStep = ({ step, sendValue }: Props) => {
  if ('submittedValue' in step) {
    return <SubmittedCesStep step={step.originalStep} submittedValue={step.submittedValue} />
  } else {
    return (
      <ChatMessage
        stepId={step.id}
        user={false}
        dataTestId={ChatDataTestId.CES_STEP}
        message={<CesForm step={step} sendValue={sendValue} />}
        shouldShowAvatar={false}
        shouldAnimate={true}
      />
    )
  }
}

export interface FormProps {
  step: CesStepType
  sendValue: Props['sendValue']
}

export const CesForm = ({ step, sendValue }: FormProps) => {
  const localise = useTranslations()
  const [satisfactionScore, setSatisfactionScore] = useState<number | null>(null)
  const [selectedScore, setSelectedScore] = useState<number | null>(null)
  const [comment, setComment] = useState('')
  const [error, setError] = useState(false)
  const includeSatisfactionScore = step.includeSatisfactionScore

  const onCommentChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length <= CES_COMMENT_INPUT_CHAR_LIMIT) {
      setComment(e.target.value)
    }
  }

  const onSubmit = () => {
    if (!selectedScore) {
      setError(true)
      return
    }

    if (includeSatisfactionScore) {
      if (!satisfactionScore) {
        setError(true)
        return
      }

      sendSubmitValue({
        score: selectedScore,
        satisfactionScore,
        comment: comment.trim() === '' ? null : comment,
        includeSatisfactionScore,
      })
    } else {
      sendSubmitValue({
        score: selectedScore,
        comment: comment.trim() === '' ? null : comment,
        includeSatisfactionScore,
      })
    }
  }

  const onCancel = () => {
    sendSubmitValue({
      score: null,
      comment: null,
      includeSatisfactionScore: step.includeSatisfactionScore,
    })
  }

  const sendSubmitValue = (submitValue: CesSubmitValue) => {
    sendValue(submitValue, { type: StepType.CES_STEP, value: submitValue })
  }

  const showError = error && (!selectedScore || (includeSatisfactionScore && !satisfactionScore))

  return (
    <CesStepContainer>
      {step.includeSatisfactionScore ? (
        <ScoreSelection
          question={localise('cesRatingSatisfactionQuestion')}
          onChange={(score) => setSatisfactionScore(score)}
          selectedScore={satisfactionScore}
          error={error && !satisfactionScore}
          lowestScoreLabel={localise('cesRatingSatisfactionLowestScoreLabel')}
          highestScoreLabel={localise('cesRatingSatisfactionHighestScoreLabel')}
          testId={ChatDataTestId.CES_STEP_SATISFACTION_SCORE_BUTTON}
        />
      ) : null}
      <ScoreSelection
        question={step.questionLabel ?? localise('cesRatingQuestion')}
        onChange={(score) => setSelectedScore(score)}
        selectedScore={selectedScore}
        error={error && !selectedScore}
        lowestScoreLabel={localise('cesRatingLowestScoreLabel')}
        highestScoreLabel={localise('cesRatingHighestScoreLabel')}
        testId={ChatDataTestId.CES_STEP_SCORE_BUTTON}
      />
      <Typography variant='body2' component='div' mt={1}>
        <StyledTextField
          label={localise('cesRatingAdditionalCommentQuestion')}
          variant='outlined'
          fullWidth
          onChange={onCommentChange}
          value={comment}
          multiline
          minRows={4}
          maxRows={4}
          inputProps={{
            'data-testid': ChatDataTestId.CES_STEP_COMMENT_INPUT,
          }}
        />
      </Typography>
      <Stack direction='row' justifyContent='flex-end'>
        <Typography
          variant='caption'
          color='text.secondary'
          pl={2}
        >{`${comment.length}/${CES_COMMENT_INPUT_CHAR_LIMIT}`}</Typography>
      </Stack>
      {showError ? (
        <Alert severity='error' sx={{ mt: 2 }}>
          {localise('cesRatingEmptyError')}
        </Alert>
      ) : null}
      <Stack direction='row' justifyContent='end' mt={2} spacing={2}>
        <ChipButton
          data-testid={ChatDataTestId.CES_STEP_CANCEL_BUTTON}
          color='primary'
          variant='outlined'
          label={localise('cesRatingCancelButton')}
          onClick={onCancel}
        />
        <ChipButton
          data-testid={ChatDataTestId.CES_STEP_SUBMIT_BUTTON}
          color='primary'
          label={localise('cesRatingSubmitButton')}
          onClick={onSubmit}
        />
      </Stack>
    </CesStepContainer>
  )
}

export const CesStepContainer = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(1),
  [theme.breakpoints.up('sm')]: {
    minWidth: '400px',
  },
  [theme.breakpoints.up('md')]: {
    minWidth: '504px',
  },
}))

export const StyledTextField = styled(TextField)({
  '& .MuiInputBase-input, .MuiInputLabel-root': {
    fontSize: '14px',
  },
  '& legend': {
    fontSize: '10.5px',
  },
})
