import React from 'react'
import { Box, Fade, Grow, styled } from '@mui/material'

import { ChatMessageBubble } from './ChatMessageBubble'
import { ChatDataTestId } from '@typedef/chatSteps'
import { BotAvatar } from '@components/BotAvatar'
import { StepContextProvider } from '@components/ChatMessage/StepContext'

interface Props {
  shouldShowAvatar: boolean
  user: boolean
  message: string | React.ReactElement
  stepId: string
  shouldAnimate: boolean
  dataTestId?: ChatDataTestId
  helperComponent?: JSX.Element | null
  useUserMessageStyles?: boolean
  fullWidth?: boolean
  timestamp?: string
}

const ChatMessage = ({
  user,
  message,
  stepId,
  shouldShowAvatar,
  shouldAnimate,
  dataTestId = ChatDataTestId.HISTORY,
  helperComponent = null,
  useUserMessageStyles = user,
  fullWidth,
  timestamp,
}: Props): JSX.Element | null => {
  return message ? (
    <StepContextProvider stepId={stepId}>
      <Box px={1} data-testid={dataTestId} data-step-id={stepId}>
        {shouldShowAvatar && !user && <BotAvatar />}
        <MessageWrapper isUserMessage={user}>
          {user ? (
            <>
              <Grow
                in={true}
                timeout={shouldAnimate ? 500 : 0}
                style={{ transformOrigin: 'bottom left' }}
              >
                <ChatMessageBubble
                  message={message}
                  useUserMessageStyles={useUserMessageStyles}
                  fullWidth={!!fullWidth}
                  timestamp={timestamp}
                />
              </Grow>
              {helperComponent}
            </>
          ) : (
            <>
              <Fade
                in={true}
                timeout={shouldAnimate ? 500 : 0}
                style={{ transformOrigin: '0 0 0' }}
              >
                <ChatMessageBubble
                  message={message}
                  useUserMessageStyles={useUserMessageStyles}
                  fullWidth={!!fullWidth}
                  timestamp={timestamp}
                />
              </Fade>
              {helperComponent}
            </>
          )}
        </MessageWrapper>
      </Box>
    </StepContextProvider>
  ) : null
}

const MessageWrapper = styled(Box, { shouldForwardProp: (prop) => prop !== 'isUserMessage' })<{
  isUserMessage: boolean
}>(({ theme, isUserMessage }) => ({
  display: 'flex',
  justifyContent: isUserMessage ? 'end' : 'start',

  [theme.breakpoints.up('lg')]: {
    ...(isUserMessage
      ? {
          marginRight: theme.spacing(3),
        }
      : { marginLeft: theme.spacing(3) }),
  },
}))

export default ChatMessage
