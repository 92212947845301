import React, { useCallback, useEffect, useState } from 'react'
import { Redirect, useHistory, useRouteMatch } from 'react-router-dom'
import { Alert, Box, Stack, TextField, Typography } from '@mui/material'
import Turnstile from 'react-turnstile'
import { environment } from '@environments/environment'

import { PageTitle, SecondaryButton, SubmitButton } from '@components/styled'
import {
  baseApi,
  ContactConfirmationSubmitStatus,
  parseQueryError,
  useLogCaptchaErrorMutation,
  useRegisterConfirmationPageVisitMutation,
  useSubmitContactConfirmationMutation,
} from '@store/api'
import { useContactConfirmationContext } from '@pages/ContactConfirmation/ContactConfirmationPage'
import { useQueryParams } from '@hooks/useQueryParams'
import { PactumLoader } from '@components/Icons/PactumLoader'
import { extractEmailValue } from '@utils/validations'
import useTranslations from '../../localisation/useTranslations'

const REFERER_QUERY_PARAM_NAME = 'referer'
const PREFILL_QUERY_PARAM_NAME = 'prefill'

interface ValidationErrors {
  contactName?: string
  contactEmail?: string
}

const contactDataDefaultState = {
  contactName: '',
  contactEmail: '',
  contactPhone: '',
}

export const ConfirmationForm = () => {
  const query = useQueryParams()
  const history = useHistory()
  const localise = useTranslations()

  const { contactConfirmation, supplierName } = useContactConfirmationContext()
  const [captchaToken, setCaptchaToken] = useState<string | null>(null)
  const [isPrefilled, setIsPrefilled] = useState(false)
  const [contactData, setContactData] = useState(contactDataDefaultState)
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({})
  const { isFetching } = baseApi.endpoints.getContactConfirmation.useQueryState(
    contactConfirmation.uuid,
  )
  const [submitContactConfirmation, { error: submitError, isLoading: submitIsLoading }] =
    useSubmitContactConfirmationMutation()
  const [registerConfirmationPageVisit] = useRegisterConfirmationPageVisitMutation()
  const [logCaptchaError] = useLogCaptchaErrorMutation()
  const { url } = useRouteMatch()

  const prefillContactValues = useCallback(() => {
    setContactData({
      contactName: contactConfirmation.recipientName,
      contactEmail: contactConfirmation.recipientEmail,
      contactPhone: contactConfirmation.recipientPhone ?? '',
    })
    setIsPrefilled(true)
  }, [
    contactConfirmation.recipientEmail,
    contactConfirmation.recipientName,
    contactConfirmation.recipientPhone,
  ])

  useEffect(() => {
    const shouldPrefill = query.get(PREFILL_QUERY_PARAM_NAME) !== null

    if (shouldPrefill) {
      prefillContactValues()
    }
  }, [query, prefillContactValues])

  useEffect(() => {
    const refererValue = query.get(REFERER_QUERY_PARAM_NAME)
    const isEmailReferer = refererValue === 'email'

    if (isEmailReferer) {
      query.delete(REFERER_QUERY_PARAM_NAME)
      history.replace({ search: query.toString() })
      if (contactConfirmation.status === 'OPEN') {
        registerConfirmationPageVisit(contactConfirmation.uuid)
      }
    }
  }, [
    query,
    history,
    registerConfirmationPageVisit,
    contactConfirmation.uuid,
    contactConfirmation.status,
  ])

  const onChangeContactPerson = () => {
    setIsPrefilled(false)
    setContactData(contactDataDefaultState)
  }

  const getValidationErrors = () => {
    const { contactName, contactEmail } = contactData
    const validationErrors: ValidationErrors = {}

    if (contactName.trim().length < 3) {
      validationErrors.contactName = localise('contactConfirmationEnterContactName')
    }
    if (extractEmailValue(contactEmail) === null) {
      validationErrors.contactEmail = localise('enterValidEmail')
    }

    return validationErrors
  }

  const submitContact = () => {
    const validationErrors = getValidationErrors()
    const submitStatus: ContactConfirmationSubmitStatus = isPrefilled
      ? 'SUBMITTED_CONFIRMATION'
      : 'SUBMITTED_NEW_CONTACTS'

    if (Object.keys(validationErrors).length === 0) {
      submitContactConfirmation({
        uuid: contactConfirmation.uuid,
        request: {
          submitStatus,
          ...contactData,
        },
        captchaToken: captchaToken!,
      })
    } else {
      setValidationErrors(validationErrors)
    }
  }

  const ignoreSubmitOnEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }

  const rejectContact = () => {
    submitContactConfirmation({
      uuid: contactConfirmation.uuid,
      request: {
        submitStatus: 'SUBMITTED_REJECTION',
        ...contactData,
      },
      captchaToken: captchaToken!,
    })
  }

  const onCaptchaTokenChange = (token: string) => {
    setCaptchaToken(token)
  }

  // error is actually string but typed as any
  const onCaptchaError = (error?: never) => {
    logCaptchaError({
      uuid: contactConfirmation.uuid,
      additionalInfo: { message: 'Captcha challenge failed', errorCode: String(error) },
    })
    setCaptchaToken('')
  }

  const onFieldChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setContactData({ ...contactData, [e.target.name]: e.target.value })
  }

  if (contactConfirmation.status !== 'OPEN') {
    return <Redirect to={`${url}/result`} />
  }

  return (
    <>
      <Turnstile
        sitekey={environment.REACT_APP_TURNSTILE_SITE_KEY}
        onVerify={onCaptchaTokenChange}
        onError={onCaptchaError}
        refreshExpired='auto'
        retry='never'
        fixedSize
        size='normal'
      />
      {/* account for Turnstile widget height */}
      <Box sx={{ mt: '-65px' }}>
        {submitIsLoading || isFetching || captchaToken === null ? (
          <Stack alignItems='center' sx={{ mt: '152px' }}>
            <PactumLoader />
          </Stack>
        ) : (
          <>
            <PageTitle component='h1'>{supplierName}</PageTitle>
            <Typography variant='body2' mt={3}>
              {localise('contactConfirmationIntro', {
                supplierName,
              })}
            </Typography>
            <Stack component='form' noValidate gap={2} mt={3}>
              <TextField
                id='contactName'
                name='contactName'
                label={localise('firstAndLastName')}
                value={contactData.contactName}
                onChange={onFieldChange}
                onKeyPress={ignoreSubmitOnEnter}
                required
                error={!!validationErrors.contactName}
                helperText={validationErrors.contactName}
              />
              <TextField
                id='contactEmail'
                name='contactEmail'
                label={localise('email')}
                disabled={isPrefilled}
                value={contactData.contactEmail}
                onChange={onFieldChange}
                onKeyPress={ignoreSubmitOnEnter}
                required
                error={!!validationErrors.contactEmail}
                helperText={validationErrors.contactEmail}
              />
              <TextField
                id='contactPhone'
                name='contactPhone'
                label={localise('phone')}
                value={contactData.contactPhone}
                onChange={onFieldChange}
                onKeyPress={ignoreSubmitOnEnter}
              />
              {submitError ? (
                <Alert severity='error' sx={{ mt: 2 }}>
                  {parseQueryError(submitError).errorMsg}
                </Alert>
              ) : null}
              <Stack flexDirection='row' justifyContent='end' gap={2} mt={3}>
                <SecondaryButton variant='text' type='submit' onClick={() => rejectContact()}>
                  {localise('contactConfirmationRejection')}
                </SecondaryButton>
                {isPrefilled ? (
                  <SecondaryButton variant='text' onClick={onChangeContactPerson}>
                    {localise('contactConfirmationChangeContactPerson')}
                  </SecondaryButton>
                ) : (
                  <SecondaryButton variant='text' onClick={prefillContactValues}>
                    {localise('contactConfirmationCancelChange')}
                  </SecondaryButton>
                )}
                <SubmitButton
                  variant='contained'
                  name='submitContact'
                  onClick={() => submitContact()}
                >
                  {localise('submit')}
                </SubmitButton>
              </Stack>
            </Stack>
          </>
        )}
      </Box>
    </>
  )
}
