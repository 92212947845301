import posthog from 'posthog-js'
import { getCookieConsentValue } from 'react-cookie-consent'
import { shouldShowCookieConsent } from '@pactum/frontend-utils'
import { environment } from 'environments/environment'
import { AppEnvEnum } from 'typedef/Environment'
import * as Sentry from '@sentry/react'
import { sentryProjectId } from '@constants/sentry'

declare let window: Window & {
  _DATADOG_SYNTHETICS_BROWSER?: unknown
  _CHAT_SIMULATOR_TEST?: boolean
}

export const initializePosthog = (cookieConsentAccepted: boolean = false): void => {
  if (isPosthogEnabled(cookieConsentAccepted)) {
    posthog.init('phc_Cc5uaJ6g1xL7b1HoYzQ0AhSCO4a3UfYsWu0wTkoj3c', {
      api_host: 'https://eu.posthog.com',
      persistence: 'localStorage+cookie',
      session_recording: {
        maskAllInputs: false,
        maskInputOptions: { password: true },
      },
    })

    posthog.people.set({
      environment: environment.REACT_APP_ENV,
      region: environment.REGION,
    })

    Sentry.getClient()?.addIntegration?.(
      new posthog.SentryIntegration(
        posthog,
        'pactum.sentry.io', // this works together with prefix, and tbh is not perfect solution from posthog side, so need to hack around the final url is prefix+organization+project
        sentryProjectId,
        'https://', // part of the url that is not part of the organization, if undefined it will us not a self hosted url.
      ),
    )
  }
}

export const isPosthogEnabled = (cookieConsentAccepted: boolean = false): boolean => {
  const isTestRun = window?._DATADOG_SYNTHETICS_BROWSER !== undefined
  const isChatSimulatorRun = window?._CHAT_SIMULATOR_TEST !== undefined
  const isProduction = environment.REACT_APP_ENV === AppEnvEnum.PROD
  const isStaging = environment.REACT_APP_ENV === AppEnvEnum.STAGING

  if (isTestRun || isChatSimulatorRun) {
    return false
  }

  if (shouldShowCookieConsent()) {
    if (getCookieConsentValue() !== 'true' && !cookieConsentAccepted) {
      return false
    }
  }

  return isProduction || isStaging
}

export const setPosthogActiveProject = (projectTag: string): void => {
  if (isPosthogEnabled()) {
    posthog.people.set({
      clientTag: projectTag, // for backwards compatibility
      projectTag,
    })
  }
}
