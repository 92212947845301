import React from 'react'
import { Element } from 'domhandler'
import { useStepId } from '@components/ChatMessage/StepContext'
import { useChatContext } from '@pages/ChatContext'
import { getApiBaseUrl } from '@store/api/baseQuery'

interface Props {
  linkToken: string
  stepId: string
  stateId?: string
  children: React.ReactNode
}
const ChatStepDownloadLink = ({ linkToken, stepId, stateId, children }: Props) => {
  return (
    <a
      href={`${getApiBaseUrl()}/chats/${linkToken}/download-step-file/${stepId}?stateId=${
        stateId ?? ''
      }`}
      download
    >
      {children}
    </a>
  )
}

export const ChatStepDownloadLinkReplace = (element: Element) => {
  const chatContext = useChatContext()
  const stepIdFromContext = useStepId()

  if (!chatContext) {
    return null
  }
  const { linkToken, stateId } = chatContext

  const stepId = element.attribs['data-step-id'] ?? stepIdFromContext
  const text = element.attribs['data-text'] ?? 'Download'

  if (!stepId) {
    return null
  }

  return (
    <ChatStepDownloadLink linkToken={linkToken} stepId={stepId} stateId={stateId}>
      {text}
    </ChatStepDownloadLink>
  )
}
