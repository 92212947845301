import React, { ReactNode } from 'react'
import { ChatLayoutVersion } from '@pactum/core-backend-types'
import ChatProgressItem from './ChatProgressItem'
import { ChatProgressItemV2 } from './ChatProgressItemV2'

export interface ChatProgressItemProps {
  index: number
  currentIndex: number
  isLast?: boolean
  children?: ReactNode
}

export interface ChatProgressItemSelectorProps {
  layoutVersion: ChatLayoutVersion.WIDE | ChatLayoutVersion.WIDE_WITH_LIVE_SIDEBAR
}

type Props = ChatProgressItemProps & ChatProgressItemSelectorProps

const ChatProgressItemSelector = (props: Props): JSX.Element => {
  if (props.layoutVersion === 'WIDE_WITH_LIVE_SIDEBAR') {
    return <ChatProgressItemV2 {...props} />
  } else {
    return <ChatProgressItem {...props} />
  }
}

export default ChatProgressItemSelector
