import { useAppSelector } from 'store'
import { ChatParamsResponse } from 'store/api'
import { ChatStep } from '@typedef/chatSteps'
import { shouldSkipChatStep } from '@utils/chat'

export const useChatParams = (): ChatParamsResponse | null => {
  return useAppSelector((state) => state.chatParams)
}

export const useIsReadOnlyChatSession = (): boolean => {
  return useAppSelector((state) => state.chat.authInfo.readOnly)
}

export const useChatSteps = (): ChatStep[] => {
  return useAppSelector((state) => state.chat.steps)
}

export const useDisplayedChatSteps = (): ChatStep[] => {
  return useChatSteps().filter((step) => !shouldSkipChatStep(step))
}
