import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react'

export type QueryError = FetchBaseQueryError | SerializedError

export type ApiErrorInternalCode =
  | 'STATE_STALE'
  | 'STATE_MISSING'
  | 'NEGOTIATION_EXPIRED'
  | 'NEGOTIATION_DELETED'
  | 'NEGOTIATION_NOT_FOUND'
  | 'ACCESS_KEY_INVALID'

type ApiErrorStatus = FetchBaseQueryError['status']

interface CoreBackendApiError {
  id: string
  name: string
  message: string
  meta: {
    internalCode?: ApiErrorInternalCode
    negotiationId?: number
    projectId?: number
  }
}

interface FetchBaseQueryCoreBackendError {
  status: number
  data: CoreBackendApiError
}

export const isFetchBaseQueryError = (error: unknown): error is FetchBaseQueryError => {
  return typeof error === 'object' && error !== null && 'status' in error
}

export const isQuerySuccessResponse = <T>(
  response: { data: T } | { error: FetchBaseQueryError | SerializedError },
): response is { data: T } => {
  return 'data' in response && !('error' in response)
}

export const isQueryErrorResponse = <T>(
  response: { data: T } | { error: FetchBaseQueryError | SerializedError },
): response is { error: FetchBaseQueryError | SerializedError } => {
  return !isQuerySuccessResponse(response)
}

/**
 * Type predicate to narrow an api error to `FetchBaseQueryCoreBackendError`
 */
export const isCoreBackendApiError = (
  error: FetchBaseQueryError | SerializedError | undefined,
): error is FetchBaseQueryCoreBackendError => {
  return (
    isFetchBaseQueryError(error) &&
    typeof error.status === 'number' &&
    typeof error.data === 'object' &&
    error.data !== null &&
    'id' in error.data &&
    'name' in error.data &&
    'message' in error.data &&
    'meta' in error.data
  )
}

export const parseQueryError = (error: QueryError) => {
  let status: ApiErrorStatus | undefined
  let errorInternalCode: ApiErrorInternalCode | undefined
  let errorMsg: string | undefined

  if (isFetchBaseQueryError(error)) {
    status = error.status
    if ('error' in error) {
      errorMsg = error.error
    }
  }
  if (isCoreBackendApiError(error)) {
    errorInternalCode = error.data.meta.internalCode
    errorMsg = error.data.message
  }

  return {
    status,
    errorInternalCode,
    errorMsg,
  }
}
