import React from 'react'
import { FullPageError } from '@components/FullPageError/FullPageError'
import { useChatParams } from '@store/selectors'
import useTranslations from '../localisation/useTranslations'

export const MaintenanceErrorPage = (): JSX.Element => {
  const chatParams = useChatParams()
  const localise = useTranslations()

  const portalName = chatParams?.shortName ?? chatParams?.name ?? localise('supplierPortal')
  const title = `${portalName} is under maintenance`

  return (
    <FullPageError title={title}>
      <p>
        Our service is temporarily offline for essential maintenance. We’ll be back up as soon as
        possible.
      </p>
    </FullPageError>
  )
}
