import React from 'react'
import { Chip, ChipProps, styled } from '@mui/material'

type Props = ChipProps & {
  labelSize?: 'large' | 'medium'
  greyedOutNoBorders?: boolean
}

const ChipButton = (props: Props) => {
  const { labelSize, greyedOutNoBorders, ...otherProps } = props

  return (
    <StyledChip labelSize={labelSize} greyedOutNoBorders={!!greyedOutNoBorders} {...otherProps} />
  )
}

const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'labelSize' && prop !== 'greyedOutNoBorders',
})<ChipProps & { labelSize?: 'large' | 'medium'; greyedOutNoBorders: boolean }>(
  ({ theme, labelSize, greyedOutNoBorders }) => ({
    padding: theme.spacing(0.5, 1.625),
    minHeight: 50,
    height: 'auto',
    borderRadius: 26,
    whiteSpace: 'normal',
    fontSize: '0.9375rem',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),

    ...(labelSize === 'medium' ? { fontSize: '0.875rem' } : {}),
    ...(greyedOutNoBorders
      ? {
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          color: '#9e9e9e',
          fontWeight: 600,
          '&.MuiChip-clickable.MuiChip-outlinedPrimary:hover': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
          },
          '&:focus': {
            backgroundColor: 'transparent',
          },
          '&:active': {
            boxShadow: 'none',
          },
        }
      : {}),
  }),
)

export default ChipButton
