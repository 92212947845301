import React, { SyntheticEvent } from 'react'
import { Box, Checkbox, FormControl, FormControlLabel, Grid } from '@mui/material'

import { useIsReadOnlyChatSession } from '@store/selectors'
import useTranslations from '../../localisation/useTranslations'
import { Choice } from '@typedef/chatSteps/MultiChoiceStep'
import { HTML } from '@components/HTML'

interface Props {
  choices: Choice[]
  selectedValues: string[]
  setSelectedValues: React.Dispatch<React.SetStateAction<string[]>>
  showSelectAll: boolean
}

const CheckboxList = ({ choices, selectedValues, setSelectedValues, showSelectAll }: Props) => {
  const localise = useTranslations()
  const isReadOnly = useIsReadOnlyChatSession()

  const onChoiceSelection = (
    e: SyntheticEvent<Element, Event>,
    checked: boolean,
    choice: Choice,
  ) => {
    const currentValues = [...selectedValues]
    const index = selectedValues.indexOf(choice.value)

    if (index === -1) {
      currentValues.push(choice.value)
    } else {
      currentValues.splice(index, 1)
    }

    setSelectedValues(currentValues)
  }

  const onSelectAllClick = (e: SyntheticEvent<Element, Event>, checked: boolean) => {
    if (checked) {
      const allValues = choices.map((choice) => choice.value)
      setSelectedValues(allValues)
    } else {
      setSelectedValues([])
    }
  }

  const getChoices = () => {
    const isMultiColumn = choices.length > 30

    if (isMultiColumn) {
      const secondColumnStart = Math.ceil(choices.length / 2)
      return (
        <Grid container spacing={2}>
          <Grid item sm={6}>
            {choices.slice(0, secondColumnStart).map(getCheckboxRow)}
          </Grid>
          <Grid item sm={6}>
            {choices.slice(secondColumnStart).map(getCheckboxRow)}
          </Grid>
        </Grid>
      )
    }

    return choices.map(getCheckboxRow)
  }

  const getCheckboxRow = (choice: Choice) => (
    <CheckboxRow
      key={choice.value}
      choice={choice}
      selected={selectedValues.includes(choice.value)}
      onSelect={onChoiceSelection}
      isReadOnly={isReadOnly}
    >
      <Checkbox sx={{ py: 0, pr: 1 }} color='primary' size='small' />
    </CheckboxRow>
  )

  const totalCount = choices.length
  const selectedCount = selectedValues.length

  return (
    <FormControl component='fieldset'>
      {showSelectAll ? (
        <CheckboxRow
          choice={{ label: localise('selectAll'), value: '' }}
          selected={selectedCount === totalCount}
          onSelect={onSelectAllClick}
          isReadOnly={isReadOnly}
        >
          <Checkbox
            sx={{ py: 0, pr: 1 }}
            color='primary'
            size='small'
            indeterminate={selectedCount > 0 && selectedCount < totalCount}
            inputProps={{
              'aria-label': 'select all rows',
            }}
          />
        </CheckboxRow>
      ) : null}
      {getChoices()}
    </FormControl>
  )
}

interface CheckboxRowProps {
  choice: Choice
  selected: boolean
  onSelect: (e: SyntheticEvent<Element, Event>, checked: boolean, value: Choice) => void
  isReadOnly: boolean
  children: React.ReactElement
}

const CheckboxRow = ({
  choice,
  selected,
  onSelect,
  isReadOnly,
  children,
}: CheckboxRowProps): JSX.Element => (
  <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 1.5 }}>
    <FormControlLabel
      disabled={isReadOnly}
      checked={selected}
      onChange={(e, checked) => onSelect(e, checked, choice)}
      control={children}
      label={
        <Box>
          <HTML markup={choice.label} />
        </Box>
      }
      sx={{ pl: 1, '.MuiFormControlLabel-label': { fontSize: '0.9375rem', lineHeight: 1.5 } }}
    />
  </Box>
)

export default CheckboxList
