import React from 'react'
import { Avatar, Stack, styled, Typography } from '@mui/material'

import { ChatDataTestId } from '@typedef/testIDs'
import { useChatParams } from '@store/selectors'
import { defaultAvatar } from '@constants/settings'
import useTranslations from '../../localisation/useTranslations'

export const BotAvatar = () => {
  const localise = useTranslations()
  const chatParams = useChatParams()
  const botAvatar = chatParams?.botAvatar || defaultAvatar

  return (
    <AvatarContainer flexDirection='row' alignItems='center' data-testid={ChatDataTestId.AVATAR}>
      <Avatar alt='Pactum' src={botAvatar} sx={{ width: 24, height: 24 }} />
      <Typography variant='caption' component='span' sx={{ fontSize: 10, color: '#6C6C6C', ml: 1 }}>
        {localise('botAvatarLabel') || chatParams?.organizationName}
      </Typography>
    </AvatarContainer>
  )
}

const AvatarContainer = styled(Stack)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginBottom: theme.spacing(1),

  [theme.breakpoints.up('lg')]: {
    marginLeft: theme.spacing(3),
  },
}))
