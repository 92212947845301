import React from 'react'
import { Box, IconButton, styled, Typography, useMediaQuery, useTheme } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import ChatProgress from '../ChatProgress/ChatProgress'
import { ChatLayoutVersion } from '@pactum/core-backend-types'
import { LocalizedDateTimeString } from '../../webComponents/LocalizedDatetime'
import { useChatParams } from '@store/selectors'
import useTranslations from '../../localisation/useTranslations'
import { CountdownTimer } from '../../webComponents/CountdownTimer'

interface Props {
  onClose: () => void
}

export const StagesSideBar = ({ onClose }: Props): JSX.Element => {
  const theme = useTheme()
  const isDesktopView = useMediaQuery(theme.breakpoints.up('lg'))
  const chatParams = useChatParams()
  const localise = useTranslations()

  return (
    <Container>
      {!isDesktopView ? (
        <Box>
          <Typography
            variant='h6'
            component='h3'
            sx={{
              color: 'pactum.black',
              fontWeight: 'bold',
              fontSize: '15px',
              lineHeight: '24px',
              pb: 2,
            }}
          >
            Progress
          </Typography>
          <IconButton
            size='medium'
            onClick={onClose}
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              mt: 1,
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      ) : null}
      <ChatProgress layoutVersion={ChatLayoutVersion.WIDE_WITH_LIVE_SIDEBAR} />
      {chatParams?.deadline && chatParams?.deadlineConfiguration.showDeadline ? (
        <Box mt={2}>
          <Typography variant='body2' component='span'>
            {localise('negotiationDeadlineMessage')}{' '}
            <b>
              <LocalizedDateTimeString
                datetime={chatParams.deadline}
                formatDescriptor={chatParams.dateFormatDescriptor}
              />
            </b>
            {chatParams.deadlineConfiguration.includeCountDown ? (
              <>
                {' '}
                <CountdownTimer datetime={chatParams.deadline} omitTimezoneInfo />
              </>
            ) : null}
            <b>.</b>
          </Typography>
        </Box>
      ) : null}
    </Container>
  )
}

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(3.5),
  },
}))
