import { AppAsyncThunkConfig, useAppDispatch, useAppSelector } from 'store/index'
import { useLocation, useParams } from 'react-router-dom'
import { useCallback, useEffect } from 'react'
import browserHistory from 'utils/browserHistory'
import { ChatUrlParams } from '@constants/routes'
import { baseApi } from 'store/api'
import { authActions, AuthStatus } from 'store/slices/auth'
import createAuth0Client, { Auth0Client } from '@auth0/auth0-spa-js'
import { environment } from 'environments/environment'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { isPosthogEnabled } from 'utils/posthog'
import posthog from 'posthog-js'
import { setUser as sentrySetUser } from '@sentry/react'

let auth0: Auth0Client | null = null

const getAuth0Client = async (): Promise<Auth0Client> => {
  if (auth0 == null) {
    auth0 = await createAuth0Client({
      domain: environment.REACT_APP_AUTH0_DOMAIN,
      client_id: environment.REACT_APP_AUTH0_CLIENT,
      audience: environment.REACT_APP_AUTH0_BACKEND_API,
      redirect_uri: window.location.origin,
      useRefreshTokens: true,
    })
  }

  return auth0
}

// If this is true, then auth0Client.getUser() and auth0Client.getTokenSilently() will work,
// otherwise need to call auth0Client.loginWithPopup() or auth0Client.loginWithRedirect() first
const haveActiveAuth0LoginSession = async (auth0Client: Auth0Client): Promise<boolean> => {
  return await auth0Client.isAuthenticated()
}

export const refreshAccessToken = createAsyncThunk<void, void, AppAsyncThunkConfig>(
  'auth/refreshAccessToken',
  async (_, { getState, dispatch }) => {
    const { authStatus } = getState().auth
    if (authStatus === AuthStatus.AUTHENTICATED) {
      const auth0Client = await getAuth0Client()
      dispatch(authActions.setToken(await auth0Client.getTokenSilently()))
    }
  },
)

const handleSuccessfulLogin = createAsyncThunk<void, void, AppAsyncThunkConfig>(
  'auth/handleSuccessfulLogin',
  async (_, { dispatch }) => {
    const auth0Client = await getAuth0Client()
    const user = await auth0Client.getUser()

    if (!(await haveActiveAuth0LoginSession(auth0Client)) || !user) {
      throw new Error('handleSuccessfulLogin called in non-authenticated state')
    }

    if (isPosthogEnabled()) {
      posthog.identify(user.email, {
        email: user.email,
        name: user.name,
      })
    }
    sentrySetUser({ email: user.email, username: user.name })

    dispatch(authActions.setToken(await auth0Client.getTokenSilently()))
    dispatch(authActions.setAuthStatus(AuthStatus.AUTHENTICATED))
  },
)

const authStatusInitialized = (authStatus: AuthStatus) => {
  return ![AuthStatus.UNINITIALIZED, AuthStatus.INITIALIZING].includes(authStatus)
}

// Use this in App entrypoint before rendering Routes
export const useInitializeAuth = () => {
  const dispatch = useAppDispatch()
  const { search } = useLocation()
  const { authStatus } = useAppSelector((state) => state.auth)

  useEffect(() => {
    const initializeAuthState = async () => {
      const params = new URLSearchParams(window.location.search)

      // Check if auth0 callback handling required on page load
      if (params.get('code') && params.get('state')) {
        const auth0Client = await getAuth0Client()
        try {
          const { appState }: { appState?: { returnTo: string; returnToSearch: string } } =
            await auth0Client.handleRedirectCallback()
          const newPathname = appState?.returnTo || window.location.pathname
          browserHistory.replace({ pathname: newPathname, search: appState?.returnToSearch })
        } catch (e) {
          console.error(e)
          dispatch(authActions.setAuthError('Failed to handle auth0 redirect callback'))
        }
        dispatch(handleSuccessfulLogin())
      } else if (params.get('error') || params.get('error_description')) {
        const loginErrorMessage = params.get('error_description') ?? params.get('error')
        console.info(loginErrorMessage)
        dispatch(authActions.setAuthError(`Failed to log in: ${loginErrorMessage}`))
      } else {
        dispatch(authActions.setAuthStatus(AuthStatus.UNAUTHENTICATED))
      }
    }

    if (authStatus === AuthStatus.UNINITIALIZED) {
      dispatch(authActions.setAuthStatus(AuthStatus.INITIALIZING))
      void initializeAuthState()
    }
  }, [dispatch, search, authStatus])

  return {
    authStatus,
    authStateInitialized: authStatusInitialized(authStatus),
  }
}

// Use this after useInitializeAuth has been successful
// Also needs to be rendered within Router, to have access to useParams
export const useAuth = () => {
  const { token: linkToken } = useParams<ChatUrlParams>()
  const dispatch = useAppDispatch()
  const authStatus = useAppSelector((state) => state.auth.authStatus)
  const { data: linkAuthInfo, error: linkAuthInfoError } =
    baseApi.useGetLinkAuthInfoQuery(linkToken)
  const { data: userOrganizations } = baseApi.useGetUserOrganizationsQuery(undefined, {
    skip: authStatus !== AuthStatus.AUTHENTICATED,
  })

  if (!authStatusInitialized(authStatus)) {
    throw new Error(`useAuth called before auth state is initialized`)
  }

  const login = useCallback(
    async (usePopup = false) => {
      const auth0Client = await getAuth0Client()
      if (await haveActiveAuth0LoginSession(auth0Client)) {
        return dispatch(handleSuccessfulLogin())
      }

      if (usePopup) {
        try {
          await auth0Client.loginWithPopup()
          dispatch(handleSuccessfulLogin())
        } catch (e) {
          console.info(e)
          return void authActions.setAuthError('Failed to login via popup')
        }
      } else {
        await auth0Client.loginWithRedirect({
          appState: { returnTo: window.location.pathname, returnToSearch: window.location.search },
        })
      }
    },
    [dispatch],
  )

  // Log in if needed for protected link
  useEffect(() => {
    if (authStatus === AuthStatus.UNAUTHENTICATED && linkAuthInfo && linkAuthInfo.loginRequired) {
      return void login()
    }
  }, [linkAuthInfo, authStatus, login])

  // Used for debug toolbar, forces login if that isn't already done
  const forcePopupLogin = useCallback(() => {
    if (linkAuthInfo && authStatus !== AuthStatus.AUTHENTICATED) {
      void login(true)
    }
  }, [login, linkAuthInfo, authStatus])

  // We shouldn't render chat until we have determined that auth isn't needed or
  // we have successfully authenticated user
  const isAuthenticating =
    !linkAuthInfo || (linkAuthInfo.loginRequired && authStatus !== AuthStatus.AUTHENTICATED)

  return {
    authStatus,
    isAuthenticating,
    linkAuthInfoError,
    forcePopupLogin,
    linkAuthInfo,
    userOrganizations,
  }
}
