import React, { Suspense, useCallback, useEffect } from 'react'
import { CircularProgress, Stack } from '@mui/material'
import { ErrorBoundary } from '@sentry/react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useAuth } from 'store/authHooks'
import { useDebugToolbar } from '@context/DebugToolbar'
import { ADMIN_ROLE_PERMISSION } from '@constants/api'
import DraggableDialog from './DraggableDialog/DraggableDialog'
import { UserOrganization } from '@pactum/core-backend-types'

const DebugToolbar = React.lazy(() => import('./DebugToolbar'))

export interface DelayProps {
  chatDelay: number
  setDebugDelay: (delay: number | null) => void
}

const DebugToolbarEntryPoint = ({ chatDelay, setDebugDelay }: DelayProps): JSX.Element | null => {
  const { forcePopupLogin, linkAuthInfo, userOrganizations } = useAuth()
  const { open, setOpen } = useDebugToolbar()

  useHotkeys('ctrl+y, meta+k', () => setOpen((prevOpen) => !prevOpen), [setOpen])

  const onDialogClose = useCallback(() => {
    setDebugDelay(null)
    setOpen(false)
  }, [setDebugDelay, setOpen])

  useEffect(() => {
    if (open) {
      forcePopupLogin()
    }
  }, [open, onDialogClose, forcePopupLogin])

  if (!userOrganizations || !linkAuthInfo?.orgId) {
    return null
  }

  const hasAdminPermissionsInOrg = (
    userOrganizations: UserOrganization[],
    currentOrgId: string,
  ) => {
    const requiredOrg = userOrganizations.find((org) => org.id === currentOrgId)
    const hasAdminPermissions = (requiredOrg?.permissions ?? []).includes(ADMIN_ROLE_PERMISSION)

    if (!hasAdminPermissions) {
      console.warn('Cannot open Toolbar, insufficient permissions or access.')
    }

    return hasAdminPermissions
  }

  const isOpen = open && hasAdminPermissionsInOrg(userOrganizations, linkAuthInfo.orgId)

  return (
    <ErrorBoundary
      onError={() => {
        console.error('Debug toolbar crashed unexpectedly')
        setOpen(false)
      }}
    >
      <DraggableDialog title='Debug Toolbar' isOpen={isOpen} onClose={onDialogClose}>
        {isOpen ? (
          <Suspense fallback={<Loader />}>
            <DebugToolbar chatDelay={chatDelay} setDebugDelay={setDebugDelay} />
          </Suspense>
        ) : null}
      </DraggableDialog>
    </ErrorBoundary>
  )
}

export const Loader = (): JSX.Element => (
  <Stack direction='row' alignItems='center' justifyContent='center' flexGrow={1}>
    <CircularProgress size={80} />
  </Stack>
)
export default DebugToolbarEntryPoint
