import React from 'react'
import { useParams } from 'react-router-dom'
import { Box, Collapse, Divider, IconButton, styled, Theme, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import useTranslations from '../../localisation/useTranslations'
import { useChatParams } from '@store/selectors'
import { useAppSelector } from '@store/index'
import { replaceApiPlaceholdersInHtml } from '@utils/apiPlaceholders'
import { ChatUrlParams } from '@constants/routes'
import { PactumInfo } from '@components/PactumInfo'
import { HTML } from '@components/HTML'

interface Props {
  onClose: () => void
}

const contentFontSize = '13px'
const lineHeight = '24px'

export const LiveSideBar = ({ onClose }: Props): JSX.Element => {
  const chatParams = useChatParams()
  const localise = useTranslations()
  const { sidebarHtml } = useAppSelector((state) => state.chat)
  const urlParams = useParams<ChatUrlParams>()

  let sidebarWithReplacedPlaceholders = sidebarHtml ?? ''
  if (chatParams) {
    sidebarWithReplacedPlaceholders = replaceApiPlaceholdersInHtml(
      sidebarWithReplacedPlaceholders,
      chatParams,
      urlParams.token,
    )
  }

  const description = chatParams?.description ?? ''
  const name = chatParams?.name ?? ''
  const showAboutSectionContent = name.trim().length > 0 && description.trim().length > 0

  return (
    <>
      <CloseButton size='medium' onClick={onClose}>
        <CloseIcon />
      </CloseButton>
      <Collapse in={!!sidebarHtml}>
        <DynamicContent p={3.75}>
          <HTML markup={sidebarWithReplacedPlaceholders} />
        </DynamicContent>
        {showAboutSectionContent && <Divider />}
      </Collapse>
      <AboutSectionContainer>
        {showAboutSectionContent && (
          <AboutSectionContent>
            <Typography variant='h6' component='h3' sx={(theme) => getTitleStyle(theme)}>
              {localise('about')} {name}
              {localise('aboutAsSuffix')}
            </Typography>
            <Typography
              variant='body2'
              component='p'
              sx={{ fontSize: contentFontSize, lineHeight: lineHeight }}
            >
              <HTML markup={description.replace(/\n/g, '<br />')} />
            </Typography>
          </AboutSectionContent>
        )}
        <PactumInfo clientName={name} />
      </AboutSectionContainer>
    </>
  )
}

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  marginTop: theme.spacing(0.5),
  right: 0,
  zIndex: 1,
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
}))

const getTitleStyle = (theme: Theme) => ({
  margin: 0,
  marginBottom: theme.spacing(2),
  fontSize: '15px',
  fontWeight: 'bold',
  color: theme.palette.pactum.black,
})

const getTableStyle = (theme: Theme) => ({
  borderCollapse: 'collapse',
  width: '100%',
  '& tr': {
    padding: 0,
  },
  '& td': {
    padding: 0,
    paddingBottom: theme.spacing(0.75),
  },
  '& td:last-child': {
    float: 'right',
    fontSize: '1.15385em',
  },
})

const DynamicContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(3.75),
  },
  fontSize: contentFontSize,
  lineHeight: lineHeight,
  color: theme.palette.pactum.darkGray,
  '& h3': getTitleStyle(theme),
  '& b': {
    color: theme.palette.pactum.black,
  },
  '& table:not(.pactum-table *)': getTableStyle(theme),
  '& .live-sidebar-table': getTableStyle(theme),
  '& .chat-dl': {
    '& dd, dt': {
      display: 'inline-block',
      paddingBottom: theme.spacing(0.75),
    },

    '& dd': {
      width: '58%',
      margin: 0,
      textAlign: 'right',
      color: theme.palette.pactum.black,
      fontWeight: 800,
      fontSize: '1.15385em',
    },
    '& dt': {
      width: '40%',
      clear: 'both',
    },
  },
}))

const AboutSectionContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 2),
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(0, 3.75),
  },
}))

const AboutSectionContent = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  [theme.breakpoints.up('lg')]: {
    paddingTop: theme.spacing(3.75),
  },
}))
