import * as React from 'react'
import { Paper, Table as MuiTable, TableBody, TableHead, TableRow } from '@mui/material'
import { Element } from 'domhandler'

import { ClipboardCopyIconButton } from '@components/ClipboardCopyIconButton'
import { decodeDataAttributes } from '../utils'
import { Version } from '../versions'
import { StyledTableCell, StyledTableContainer, StyledTableRow } from './styled'
import { HTML } from '@components/HTML'

interface Props {
  headers: string[]
  rows: string[][]
  tableOptions?: {
    copyContents?: boolean
  }
}

export const Table = ({ headers, rows, tableOptions }: Props) => {
  const isCopyContentsEnabled = tableOptions?.copyContents ?? false

  const getCopyContent = () => {
    const stringHeader = headers.join('\t') + '\n'
    const stringRows = rows.map((row) => row.join('\t')).join('\n')

    return stringHeader + stringRows
  }

  return (
    <StyledTableContainer component={Paper} className='pactum-table'>
      {isCopyContentsEnabled ? (
        <ClipboardCopyIconButton
          onCopy={getCopyContent}
          sx={{ position: 'absolute', right: 0, color: 'common.white' }}
        />
      ) : null}
      <MuiTable aria-label='chat table'>
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <StyledTableCell key={header} isCopyContentsEnabled={isCopyContentsEnabled}>
                <HTML markup={header} />
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, rowIndex) => (
            <StyledTableRow key={row[0] + '_row-' + rowIndex}>
              {row.map((value, cellIndex) => (
                <StyledTableCell
                  key={value + '_row-' + rowIndex + '_header-' + headers[cellIndex]}
                  align='right'
                  isCopyContentsEnabled={isCopyContentsEnabled}
                >
                  <HTML markup={value} />
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </MuiTable>
    </StyledTableContainer>
  )
}

const getValidatedParams = (
  headersString: string | null,
  rowsString: string | null,
  tableOptionsString: string | null,
  version: string | null,
): Props | null => {
  if (!headersString || !rowsString) {
    console.error('Headers and rows are mandatory parameters')
    return null
  }

  try {
    const decodeAttributes = version === Version.ONE ? decodeDataAttributes : decodeURIComponent
    const headers = JSON.parse(decodeAttributes(headersString)) as string[]
    const rows = JSON.parse(decodeAttributes(rowsString)) as string[][]
    const tableOptions = tableOptionsString
      ? (JSON.parse(decodeAttributes(tableOptionsString)) as Props['tableOptions'])
      : undefined

    if (Array.isArray(headers) && Array.isArray(rows) && headers.length > 0 && rows.length > 0) {
      checkRowsValidity(headers.length, rows)

      return { headers, rows, tableOptions }
    } else {
      throw new Error('Headers and rows parameters must be non-empty arrays')
    }
  } catch (e) {
    console.error(e)
    return null
  }
}

const checkRowsValidity = (headersCount: number, rows: string[][]) => {
  for (let i = 0; i < rows.length; i++) {
    const row = rows[i]
    if (row.length === headersCount) {
      continue
    }

    throw new Error('Rows must have same amount of values as headers')
  }
}

export const TableReplace = (element: Element) => {
  const headersString = element.attribs['data-headers']
  const rowsString = element.attribs['data-rows']
  const tableOptionsString = element.attribs['data-table-options']
  const version = element.attribs['data-version']
  const props = getValidatedParams(headersString, rowsString, tableOptionsString, version)

  if (!props) {
    throw new Error('There was an error when rendering Table component')
  }

  return <Table {...props} />
}

export { StyledTableCell }
