import { Element } from 'domhandler'

import { TooltipReplace } from './Tooltip'
import { ExpandableSectionReplace } from './ExpandableSection'
import { LocalizedDatetimeReplace } from './LocalizedDatetime'
import { ChatStepDownloadLinkReplace } from './ChatStepDownloadLinkComponent'
import { TableReplace } from './Table'
import { CountdownTimerReplace } from './CountdownTimer'

type ReplaceCallback = (element: Element) => JSX.Element | null
export const replaceables: { [key: string]: ReplaceCallback } = {
  'pactum-tooltip': TooltipReplace,
  'pactum-table': TableReplace,
  'pactum-expandable-section': ExpandableSectionReplace,
  'pactum-localized-datetime': LocalizedDatetimeReplace,
  'pactum-download': ChatStepDownloadLinkReplace,
  'pactum-countdown-timer': CountdownTimerReplace,
}
