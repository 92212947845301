import {
  MultiInputComponentSection,
  MultiInputComponentStep,
  MultiInputComponentValues,
} from '@typedef/chatSteps/MultiInputStep'
import { getUiVariablesFromText } from '@components/ChatMultiInputComponent/sectionParsing'

export const prepareSubmitValues = (
  inputsDefinition: MultiInputComponentStep['inputs'],
  values: MultiInputComponentValues,
  sections: MultiInputComponentSection[],
) => {
  const processedValues = sortAndFilterValuesBySectionsGuiVariables(values, sections)
  return nullifyEmptyValues(inputsDefinition, processedValues)
}

const sortAndFilterValuesBySectionsGuiVariables = (
  values: MultiInputComponentValues,
  sections: MultiInputComponentSection[],
) =>
  sections.reduce<MultiInputComponentValues>((acc, section) => {
    for (const inputKey of getUiVariablesFromText(section.text)) {
      // it does not check if value already exists, but it should be checked somewhere else already before...
      if (inputKey in values) {
        acc[inputKey] = values[inputKey]
      }
    }
    return acc
  }, {})

const nullifyEmptyValues = (
  inputsDefinition: MultiInputComponentStep['inputs'],
  values: MultiInputComponentValues,
) => {
  const acc: MultiInputComponentValues = {}

  for (const [variable, value] of Object.entries(values)) {
    if ((value === undefined || value === '') && inputsDefinition[variable].required === false) {
      acc[variable] = null
    } else {
      acc[variable] = value
    }
  }

  return acc
}
