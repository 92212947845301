import React, { ReactNode, createContext, useContext } from 'react'
import { useLocalStorageState } from '@hooks/useLocalStorageState'

interface ContextType {
  open: boolean
  setOpen: (value: boolean | ((value: boolean) => boolean)) => void
}

const DebugToolbarContext = createContext<ContextType | undefined>(undefined)

interface Props {
  children: ReactNode
}

export const localStorageKeyName = 'debugToolbarEnabled'

const DebugToolbarProvider = ({ children }: Props): JSX.Element => {
  const [open, setOpen] = useLocalStorageState(localStorageKeyName, false)

  return (
    <DebugToolbarContext.Provider value={{ open, setOpen }}>
      {children}
    </DebugToolbarContext.Provider>
  )
}

const useDebugToolbar = (): ContextType => {
  const context = useContext(DebugToolbarContext)
  if (context === undefined) {
    throw new Error('useDebugToolbar must be used within a DebugToolbarProvider')
  }

  return context
}

export { useDebugToolbar, DebugToolbarProvider }
