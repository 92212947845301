import { Theme } from '@mui/material'

export const getStyledChatDefinitionListClasses = (theme: Theme) => ({
  '& .chat-dl-container': {
    '& h1, h2, h3': {
      marginTop: 0,
    },

    '& .chat-dl': {
      margin: 0,

      '& dd, dt': {
        width: '50%',
        display: 'inline-block',
        paddingBottom: theme.spacing(0.75),
      },

      '& dd': {
        margin: 0,
        textAlign: 'right',
        color: theme.palette.pactum.black,
        fontWeight: 800,
      },

      '& dt': {
        clear: 'both',
      },
    },
  },
})

export const getStyledChatTableClasses = (theme: Theme) => ({
  '& .styled-chat-table': {
    borderRadius: '9px',
    borderCollapse: 'collapse',
    width: '100%',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,

    '& .chat-td, .chat-th': {
      textAlign: 'left',
      padding: '8px',
    },
    '& .chat-th': {
      color: theme.palette.common.white,
      paddingTop: '12px',
      background: theme.palette.pactum.customChatTableColor,
    },
    '& .chat-th:last-of-type': {
      paddingRight: '20px',
      borderRadius: '0 9px 0 0',
    },
    '& .chat-th:first-of-type': {
      paddingLeft: '20px',
      borderRadius: '9px 0 0 0',
    },
    '& .chat-td:first-of-type': {
      paddingLeft: '20px',
    },
    '& .chat-td:last-of-type': {
      paddingRight: '20px',
    },
    '& .chat-tr:not(:last-of-type)': {
      borderBottom: '1px solid #f2f2f2',
    },
    '& .chat-tr:last-of-type': {
      paddingBottom: '20px',
      '& .chat-td': {
        paddingBottom: '13px',
      },
    },
    '& .chat-tr:hover': {
      backgroundColor: theme.palette.pactum.tableRowHoverGray,
    },
  },
})
