import React from 'react'
import { Element } from 'domhandler'
import render from 'dom-serializer'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  accordionSummaryClasses,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { getAttributesDecoder } from './utils'
import { HTML } from '@components/HTML'

interface Props {
  title: string
  children: string
}

export const ExpandableSection = ({ title, children }: Props) => {
  return (
    <Accordion sx={{ boxShadow: 'none', backgroundColor: 'white' }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{ [`& .${accordionSummaryClasses.content}`]: { borderBottom: '1px solid #CCD2D3' } }}
      >
        <HTML markup={title} />
      </AccordionSummary>
      <AccordionDetails>
        <HTML markup={children} />
      </AccordionDetails>
    </Accordion>
  )
}

const getValidatedParams = ({
  sectionTitle,
  version,
}: {
  sectionTitle: string | null
  version: string | null
}) => {
  if (!sectionTitle) {
    console.error('Expandable section title has to be defined')
    return null
  }

  const decodeAttributes = getAttributesDecoder(version)

  return { title: decodeAttributes(sectionTitle) }
}
export const ExpandableSectionReplace = (element: Element) => {
  const children = element.attribs['data-children']
  const sectionTitle = element.attribs['data-title']
  const version = element.attribs['data-version']
  const props = getValidatedParams({ sectionTitle, version })
  if (!props) {
    throw new Error('There was an error when rendering ExpandableSection component')
  }
  const decodeAttributes = getAttributesDecoder(version)
  return (
    <ExpandableSection {...props}>
      {children ? decodeAttributes(children) : render(element.children)}
    </ExpandableSection>
  )
}
