import React from 'react'
import { Box, Stack, styled, TooltipProps as MuiTooltipProps } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import { Element } from 'domhandler'
import render from 'dom-serializer'
import { getAttributesDecoder } from './utils'
import { HTML } from '@components/HTML'
import { PactumTooltip } from '@components/PactumTooltip'

interface Props {
  tooltipContent: string
  placement: MuiTooltipProps['placement']
  disableIcon: boolean
  iconStyle: {
    type?: 'info' | 'warning'
    position?: 'top' | 'center'
    style?: React.CSSProperties
  }
  children: string
}

export const Tooltip = ({ children, tooltipContent, disableIcon, iconStyle, ...props }: Props) => {
  const Icon = iconStyle.type === 'warning' ? WarningAmberOutlinedIcon : InfoOutlinedIcon
  const alignItems = iconStyle.position === 'top' ? 'normal' : 'center'
  const iconSx = iconStyle.style ? { marginLeft: 0.5, ...iconStyle.style } : { marginLeft: 0.5 }

  return (
    <PactumTooltip
      title={
        <TooltipContent>
          <HTML markup={tooltipContent} />
        </TooltipContent>
      }
      enterDelay={50}
      {...props}
    >
      <Stack flexDirection='row' alignItems={alignItems}>
        <HTML markup={children} />
        {!disableIcon ? <Icon sx={iconSx} /> : null}
      </Stack>
    </PactumTooltip>
  )
}

const TooltipContent = styled(Box)({
  textAlign: 'center',
  fontFamily: 'Inter, sans-serif',
  fontSize: '13px',
  fontWeight: 400,
  textTransform: 'none',
})

const allowedPlacementValues = [
  'bottom-end',
  'bottom-start',
  'bottom',
  'left-end',
  'left-start',
  'left',
  'right-end',
  'right-start',
  'right',
  'top-end',
  'top-start',
  'top',
]

const getValidatedParams = ({
  tooltipContent,
  placement,
  iconStyle,
  version,
}: {
  tooltipContent: string | null
  placement: string | null
  iconStyle: string | null
  version: string | null
}) => {
  const decodeAttributes = getAttributesDecoder(version)

  if (!tooltipContent) {
    console.error('Tooltip content must be specified')
    return null
  }

  let placementProp = placement
  if (!placementProp || !allowedPlacementValues.includes(placementProp)) {
    console.warn('Wrong tooltip placement provided, defaulting to top')
    placementProp = 'top'
  }

  let iconStyleProp = {}
  if (iconStyle) {
    try {
      iconStyleProp = JSON.parse(decodeAttributes(iconStyle)) as Props['iconStyle']
    } catch (e) {
      console.warn(e)
    }
  }

  return {
    tooltipContent: decodeAttributes(tooltipContent),
    placement: placementProp as MuiTooltipProps['placement'],
    iconStyle: iconStyleProp,
  }
}
export const TooltipReplace = (element: Element) => {
  const children = element.attribs['data-children']
  const tooltipContent = element.attribs['data-tooltip-content'] ?? null
  const placement = element.attribs['data-placement']
  const disableIcon = element.attribs['data-disable-icon'] !== undefined
  const iconStyle = element.attribs['data-icon-style']
  const version = element.attribs['data-version']
  const props = getValidatedParams({ tooltipContent, placement, iconStyle, version })
  if (!props) {
    throw new Error('There was an error when rendering Tooltip component')
  }
  const decodeAttributes = getAttributesDecoder(version)
  return (
    <Tooltip {...props} disableIcon={disableIcon}>
      {children ? decodeAttributes(children) : render(element.children)}
    </Tooltip>
  )
}
