import React from 'react'
import { Element } from 'domhandler'
import { useChatContext } from '@pages/ChatContext'
import { format } from 'date-fns'

const UnknownValue = () => {
  return <span>N/A</span>
}

export const LocalizedDatetimeReplace = (element: Element) => {
  const datetime = element.attribs['data-datetime']
  const dateFormatDescriptor = element.attribs['data-date-format-descriptor']
  if (!datetime) {
    return <UnknownValue />
  }
  return <LocalizedDateTimeString datetime={datetime} formatDescriptor={dateFormatDescriptor} />
}

export const LocalizedDateTimeString = ({
  datetime,
  formatDescriptor,
}: {
  datetime: string
  formatDescriptor?: string | null
}) => {
  const chatContext = useChatContext()

  let formattedDate
  try {
    if (formatDescriptor) {
      formattedDate = format(new Date(datetime), formatDescriptor)
    } else {
      formattedDate = new Intl.DateTimeFormat(chatContext?.locale, {
        dateStyle: 'long',
        timeStyle: 'short',
      }).format(new Date(datetime))
    }
  } catch {
    return <UnknownValue />
  }

  return <span>{formattedDate}</span>
}
