import React, { useState } from 'react'
import { Alert, Box, Fade, Typography } from '@mui/material'

import ChatOptions from '../ChatOptions/ChatOptions'
import { ChatDataTestId, StepType } from '@typedef/chatSteps'
import {
  MultiInputComponentStep,
  MultiInputComponentSubmitRequest,
  MultiInputComponentValues,
} from '@typedef/chatSteps/MultiInputStep'
import { StepOption } from '@typedef/chatSteps/OptionsStep'
import { isSectionVisible } from './expressions'
import { Section } from './Section'
import useTranslations from '../../localisation/useTranslations'
import { SimulatorMultiInput } from '@utils/simulator/inputs'
import { prepareSubmitValues } from '@components/ChatMultiInputComponent/submitHelpers'

interface Props {
  data: MultiInputComponentStep
  values: MultiInputComponentValues
  setValue: (variable: string, value: string | number) => void
  resetValues: () => void
  onChipClick: (request: MultiInputComponentSubmitRequest, userMessage: SimulatorMultiInput) => void
  errorVariables: string[]
  errorMessage: string | null
}

const ChatMultiInputComponent = ({
  data,
  values,
  setValue,
  resetValues,
  errorVariables,
  onChipClick,
  errorMessage,
}: Props): JSX.Element => {
  const [firstSubmitDone, setFirstSubmitDone] = useState(false)
  const localise = useTranslations()
  const inputVariables = Object.keys(data.inputs)

  const buttonChoices: StepOption[] = [
    {
      label: data.submitLabel,
      value: 'SUBMIT',
      disabled: errorMessage !== null && firstSubmitDone,
      uiProperties: {
        variant: 'filled',
      },
    },
    ...(data.uiProperties?.resetButton
      ? [
          {
            label: data.uiProperties.resetButton.label ?? localise('resetValues'),
            value: 'RESET',
            disabled: false,
          },
        ]
      : []),
    ...(data.cancelLabel
      ? [
          {
            label: data.cancelLabel,
            value: 'CANCEL',
            disabled: false,
          },
        ]
      : []),
  ]

  const postValue = (request: MultiInputComponentSubmitRequest, choiceIndex: number) => {
    onChipClick(request, {
      type: StepType.MULTI_INPUT_STEP,
      value: request.values === null ? null : Object.values(request.values),
      submitButtonIndex: choiceIndex,
    })
  }

  const handleChipClick = (choice: StepOption, choiceIndex: number) => {
    if (choice.value === 'SUBMIT') {
      if (errorMessage !== null && !firstSubmitDone) {
        setFirstSubmitDone(true)
      } else {
        postValue(
          { action: 'SUBMIT', values: prepareSubmitValues(data.inputs, values, data.sections) },
          choiceIndex,
        )
      }
    } else if (choice.value === 'CANCEL') {
      postValue({ action: 'CANCEL', values: null }, choiceIndex)
    } else if (choice.value === 'RESET') {
      resetValues()
    }
  }

  return (
    <Box data-testid={ChatDataTestId.MULTI_INPUT_CONTAINER}>
      <Typography variant='body2' component='p' sx={{ fontWeight: 'bold', fontSize: '0.9375rem' }}>
        {data.contentTitle ?? localise('makeAChoice')}
      </Typography>
      {data.sections.map((section, sectionIndex) => {
        if (!isSectionVisible(section, values, inputVariables)) {
          return null
        }

        return (
          <Section
            key={sectionIndex}
            layout={data.layout}
            section={section}
            sectionKey={sectionIndex}
            inputs={data.inputs}
            values={values}
            setValue={setValue}
            firstSubmitDone={firstSubmitDone}
            errorVariables={errorVariables}
          />
        )
      })}
      {errorMessage && firstSubmitDone && <ErrorMessage errorMessage={errorMessage} />}
      <ChatOptions
        choices={buttonChoices}
        align='left'
        size='medium'
        onChipClick={handleChipClick}
      />
    </Box>
  )
}

const ErrorMessage = ({ errorMessage }: { errorMessage: string }) => (
  <Fade in={true}>
    <Alert data-testid={ChatDataTestId.ALERT} severity='error' sx={{ mt: 1, alignItems: 'center' }}>
      <Typography sx={{ whiteSpace: 'pre-line', fontSize: '0.875rem' }}>{errorMessage}</Typography>
    </Alert>
  </Fade>
)

export default ChatMultiInputComponent
