import React from 'react'

import { Box, Stack, Typography } from '@mui/material'
import { ChatLayoutVersion } from '@pactum/core-backend-types'

import ChatProgress from '../ChatProgress/ChatProgress'
import useTranslations from '../../localisation/useTranslations'
import { PrivacyPolicyText, TextLink } from '@components/PactumInfo'
import { HTML } from '@components/HTML'

interface Props {
  description: string
  name: string
}

export const SideBar = ({ description, name }: Props): JSX.Element => {
  const localise = useTranslations()

  return (
    <Stack p={4}>
      <ChatProgress layoutVersion={ChatLayoutVersion.WIDE} />
      <Box mt={3}>
        <Typography
          variant='h5'
          component='h2'
          paragraph
          sx={{ fontWeight: 'bold', fontSize: '1.1875em' }}
        >
          {localise('about')} {name}
          {localise('aboutAsSuffix')}
        </Typography>
        <Typography
          variant='body2'
          component='p'
          sx={{ fontSize: '0.875em', lineHeight: '1.75em' }}
        >
          <HTML markup={description.replace(/\n/g, '<br />')} />
        </Typography>
      </Box>
      <Typography variant='caption' component='p' sx={{ fontSize: '0.625em', opacity: 0.5, mt: 2 }}>
        <TextLink href='https://pactum.com' target='_blank'>
          {localise('poweredByPactum', {
            name,
          })}
        </TextLink>
        <br />
        <PrivacyPolicyText />
      </Typography>
    </Stack>
  )
}
