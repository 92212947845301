import React from 'react'
import { GridApiPro, GridRowId } from '@mui/x-data-grid-pro'
import { TabularDataRow } from '@pactum/core-backend-types'

export const getRowsForTableUpdate = (
  pastedValues: string[],
  apiRef: React.MutableRefObject<GridApiPro>,
  currentId: GridRowId,
  currentField: string,
  parseFunction: (v: string, row: TabularDataRow) => string | number | boolean | undefined,
  ignoreCurrentInput: boolean = false,
) => {
  const rows = apiRef.current.getSortedRows() as TabularDataRow[]
  const currentRowIdx = rows.findIndex((row) => row.id === currentId)
  const rowsToUpdate = rows.slice(currentRowIdx)
  const updatedRows: TabularDataRow[] = []

  for (let i = 0; i < pastedValues.length; i++) {
    const row = rowsToUpdate[i]
    if (!row) {
      break
    }

    const parsedValue = parseFunction(pastedValues[i], row)

    if (parsedValue === undefined) {
      continue
    }

    if (i === 0 && !ignoreCurrentInput) {
      apiRef.current.setEditCellValue({ id: currentId, field: currentField, value: parsedValue })
    }

    if (row.data[currentField].readonly) {
      continue
    }

    updatedRows.push({
      ...row,
      data: { ...row.data, [currentField]: { ...row.data[currentField], value: parsedValue } },
    })
  }

  return updatedRows
}

export const isCellUpdating = (
  updatingCell: { id: string; field: string } | null,
  id: string,
  field: string,
) => {
  return updatingCell?.id === id && updatingCell?.field === field
}
