import React from 'react'
import {
  alpha,
  Box,
  Button,
  ButtonProps,
  Stack,
  styled,
  Typography,
  buttonClasses,
} from '@mui/material'

interface ScoreSelectionProps {
  question: string
  onChange: (score: number) => void
  selectedScore: number | null
  lowestScoreLabel: string
  highestScoreLabel: string
  testId: string
  error?: boolean
  disabled?: boolean
}

const scores = Array.from({ length: 5 }, (_, i) => i + 1)

export const ScoreSelection = ({
  question,
  onChange,
  selectedScore,
  error,
  lowestScoreLabel,
  highestScoreLabel,
  testId,
  disabled,
}: ScoreSelectionProps) => {
  return (
    <Box pb={3}>
      <Typography variant='body1' component='p' sx={{ fontSize: '0.9375rem', lineHeight: 1.6 }}>
        {question}
      </Typography>
      <Stack direction='row' justifyContent='space-between' mt={1}>
        {scores.map((score) => (
          <StyledButton
            disabled={!!disabled}
            data-testid={testId}
            key={score}
            variant='outlined'
            onClick={() => onChange(score)}
            selected={score === selectedScore}
            error={!!error}
            aria-selected={score === selectedScore}
          >
            {score}
          </StyledButton>
        ))}
      </Stack>
      <Stack direction='row' mt={0.7}>
        <Typography
          variant='caption'
          mr='auto'
          sx={{ maxWidth: 'min-content', wordBreak: 'keep-all' }}
        >
          {lowestScoreLabel}
        </Typography>
        <Typography
          variant='caption'
          ml='auto'
          sx={{ maxWidth: 'min-content', wordBreak: 'keep-all', textAlign: 'end' }}
        >
          {highestScoreLabel}
        </Typography>
      </Stack>
    </Box>
  )
}

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'selected' && prop !== 'error',
})<ButtonProps & { selected: boolean; error: boolean }>(({ theme, selected, error }) => ({
  height: '32px',
  width: '32px',
  minWidth: '32px',
  border: error ? '1px solid rgba(211, 47, 47, 0.8)' : '1px solid #9D9D9D',
  color: '#000000',

  ...(selected
    ? {
        [`&.${buttonClasses.root}.${buttonClasses.disabled}`]: { color: 'white' },
        ':hover': {
          backgroundColor: alpha(theme.palette.primary.main, 0.8),
        },
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        borderColor: alpha(theme.palette.primary.main, 0.8),
      }
    : {}),
}))
