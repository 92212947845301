import React, { useEffect, useRef, useState } from 'react'
import {
  Checkbox,
  Paper,
  styled,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableContainerProps,
  TableRow,
} from '@mui/material'

import { TableHeader } from './TableHeader'
import { Column, RowItem } from '@typedef/chatSteps/MultiChoiceStep'
import { HTML } from '@components/HTML'

const descendingComparator = (a: RowItem, b: RowItem, columnIndex: number) => {
  if (b.row[columnIndex].rawValue < a.row[columnIndex].rawValue) {
    return -1
  }
  if (b.row[columnIndex].rawValue > a.row[columnIndex].rawValue) {
    return 1
  }
  return 0
}

const getComparator = ({ direction, columnIndex }: Order) => {
  return direction === 'desc'
    ? (a: RowItem, b: RowItem) => descendingComparator(a, b, columnIndex)
    : (a: RowItem, b: RowItem) => -descendingComparator(a, b, columnIndex)
}

export type Order = {
  direction: 'asc' | 'desc'
  columnIndex: number
}

interface Props {
  columns: Column[]
  choices: RowItem[]
  selectedValues: string[]
  setSelectedValues: React.Dispatch<React.SetStateAction<string[]>>
  chatBoxContainerRef: React.MutableRefObject<null | HTMLDivElement>
  showSelectAll: boolean
}

const CheckboxTable = ({
  columns,
  choices,
  selectedValues,
  setSelectedValues,
  chatBoxContainerRef,
  showSelectAll,
}: Props) => {
  const tableBodyRef = useRef<HTMLTableSectionElement>(null)
  const tableHeaderRef = useRef<HTMLTableSectionElement>(null)

  const [order, setOrder] = useState<Order | null>(null)
  const [tableMaxHeight, setTableMaxHeight] = useState(540)

  useEffect(() => {
    const updateTableHeight = () => {
      const chatBoxHeight = chatBoxContainerRef.current?.clientHeight
      const tableHeaderHeight = tableHeaderRef.current?.clientHeight
      const rowElements = tableBodyRef.current?.children

      if (chatBoxHeight && tableHeaderHeight && rowElements) {
        const heightEstimate = chatBoxHeight * 0.5
        let tableTotalHeight = tableHeaderHeight

        for (let row of rowElements) {
          if (tableTotalHeight + row.clientHeight > heightEstimate) {
            tableTotalHeight += row.clientHeight * 1.5
            break
          }

          tableTotalHeight += row.clientHeight
        }

        setTableMaxHeight(tableTotalHeight)
      }
    }

    updateTableHeight()
    window.addEventListener('resize', updateTableHeight)
    return () => window.removeEventListener('resize', updateTableHeight)
  }, [chatBoxContainerRef])

  const handleRowClick = (choiceValue: string) => {
    const currentValues = [...selectedValues]
    const index = selectedValues.indexOf(choiceValue)

    if (index === -1) {
      currentValues.push(choiceValue)
    } else {
      currentValues.splice(index, 1)
    }

    setSelectedValues(currentValues)
  }

  const onSelectAllClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      const allValues = choices.map((choice) => choice.value)
      setSelectedValues(allValues)
    } else {
      setSelectedValues([])
    }
  }

  const isSelected = (choiceValue: string) => selectedValues.indexOf(choiceValue) !== -1

  const handleRequestSort = (e: React.MouseEvent, columnIndex: number) => {
    const isAsc = order?.columnIndex === columnIndex && order?.direction === 'asc'
    setOrder({
      direction: isAsc ? 'desc' : 'asc',
      columnIndex,
    })
  }

  const getSortedChoices = () => (order ? choices.slice().sort(getComparator(order)) : choices)

  return (
    <StyledTableContainer tableMaxHeight={tableMaxHeight} component={Paper}>
      <MuiTable stickyHeader component='div' aria-label='chat table'>
        <TableHeader
          columns={columns}
          onSelectAllClick={onSelectAllClick}
          totalCount={choices.length}
          selectedCount={selectedValues.length}
          onRequestSort={handleRequestSort}
          order={order}
          showSelectAll={showSelectAll}
          ref={tableHeaderRef}
        />
        <TableBody component='div' ref={tableBodyRef}>
          {getSortedChoices().map(({ row, value }) => {
            const isRowSelected = isSelected(value)
            return (
              <TableRow
                selected={isRowSelected}
                onClick={() => handleRowClick(value)}
                role='checkbox'
                key={value}
                aria-checked={isSelected(value)}
                component='div'
              >
                <TableCell component='div' padding='checkbox'>
                  <Checkbox
                    color='primary'
                    checked={isRowSelected}
                    inputProps={{
                      'aria-labelledby': `table-checkbox-${value}`,
                    }}
                  />
                </TableCell>
                {row.map(({ displayValue }) => (
                  <StyledTableCell key={`${value}-${displayValue}`} component='div'>
                    <HTML markup={displayValue} />
                  </StyledTableCell>
                ))}
              </TableRow>
            )
          })}
        </TableBody>
      </MuiTable>
    </StyledTableContainer>
  )
}

const StyledTableContainer = styled(TableContainer, {
  shouldForwardProp: (prop) => prop !== 'tableMaxHeight',
})<TableContainerProps & { tableMaxHeight: number; component: React.ElementType }>(
  ({ tableMaxHeight }) => ({
    backgroundColor: '#FFF',
    maxHeight: tableMaxHeight,

    scrollbarWidth: 'thin',
    scrollbarColor: `#828282 #E7EAEB`,

    '&::-webkit-scrollbar': {
      height: '12px',
      width: '12px',
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '0px 4px 0px 4px',
      background: '#E7EAEB',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#828282',
      borderRadius: '9px',
      border: `3px solid #E7EAEB`,
    },
  }),
)

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontFamily: 'Inter, sans-serif',
  fontSize: 15,
  textAlign: 'left',
  padding: theme.spacing(1),

  '&.MuiTableCell-head': {
    backgroundColor: 'var(--custom-chat-table-color)',
    color: theme.palette.common.white,
    fontWeight: 700,
  },
})) as typeof TableCell

export default CheckboxTable
