import React from 'react'
import { ChatLayoutVersion } from '@pactum/core-backend-types'
import { WideChatWithLiveSideBar } from './WideChatWithLiveSideBar'
import { WideChat } from './WideChat'
import { exhaustiveSwitchGuard } from 'utils/tsUtils'

interface Props {
  layoutVersion: ChatLayoutVersion
  children: React.ReactNode
}

const ChatWrapperSelector = ({ layoutVersion, children }: Props): JSX.Element => {
  switch (layoutVersion) {
    case ChatLayoutVersion.WIDE_WITH_LIVE_SIDEBAR:
      return <WideChatWithLiveSideBar>{children}</WideChatWithLiveSideBar>
    case ChatLayoutVersion.WIDE:
      return <WideChat>{children}</WideChat>
    default:
      exhaustiveSwitchGuard(layoutVersion, {
        throw: false,
        err: `Unknown layout version ${layoutVersion}`,
      })
      return <WideChatWithLiveSideBar>{children}</WideChatWithLiveSideBar>
  }
}

export default ChatWrapperSelector
