import { Box, Button, styled, Typography } from '@mui/material'

export const ActionButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 'bold',
  padding: theme.spacing(1, 2),
  borderRadius: 3,

  '&.MuiButton-outlinedPrimary': {
    color: theme.palette.text.primary,
  },
}))

export const ActionButtonWrap = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2.5),
}))

export const Content = styled(Typography)({
  fontSize: '0.875em',
  lineHeight: '1.71em',
}) as typeof Typography
