import React from 'react'
import { Grid, GridProps, SvgIconProps, Typography, styled } from '@mui/material'
import { grey } from '@mui/material/colors'
import { CheckCircle } from '@mui/icons-material'

import { ChatProgressItemProps } from './ChatProgressItemSelector'

const ChatProgressItem = ({
  index,
  currentIndex,
  isLast = false,
  children,
}: ChatProgressItemProps): JSX.Element => {
  const isPreviousItem = index < currentIndex
  const isActiveItem = index === currentIndex

  return (
    <Grid container>
      <ProgressItem container item xs={12} lastItem={isLast} notActive={index >= currentIndex}>
        <StyledCheckCircle isChecked={isPreviousItem} isActive={isActiveItem} />
        <StageLabel
          sx={{ ...(isActiveItem ? { fontWeight: 'bold', color: 'primary.main' } : {}) }}
          variant='h5'
          component='h2'
          paragraph
        >
          {children}
        </StageLabel>
      </ProgressItem>
    </Grid>
  )
}

const ProgressItem = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'lastItem' && prop !== 'notActive',
})<GridProps & { lastItem: boolean; notActive: boolean }>(({ theme, lastItem, notActive }) => ({
  flexWrap: 'nowrap',
  position: 'relative',
  paddingBottom: theme.spacing(2.5),
  '&:before': {
    content: '""',
    width: 2,
    backgroundColor: theme.palette.common.black,
    position: 'absolute',
    left: 9,
    top: 22,
    bottom: 2,
    zIndex: 1,
    opacity: 0.12,
  },

  ...(lastItem
    ? {
        minHeight: 'auto',
        paddingBottom: 0,
        '&:before': {
          content: 'none',
        },
      }
    : {}),

  ...(notActive
    ? {
        '&:before': {
          content: 'none',
        },
      }
    : {}),
}))

const StyledCheckCircle = styled(CheckCircle, {
  shouldForwardProp: (prop) => prop !== 'isChecked' && prop !== 'isActive',
})<SvgIconProps & { isChecked: boolean; isActive: boolean }>(({ theme, isChecked, isActive }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.common.white,
  width: '20px',
  height: '20px',
  border: `1px solid ${grey[400]}`,
  borderRadius: '50%',
  zIndex: 1,

  ...(isChecked
    ? { color: theme.palette.primary.main, border: `1px solid ${theme.palette.primary.main}` }
    : {}),
  ...(isActive ? { border: `3px solid ${theme.palette.primary.main}` } : {}),
}))

const StageLabel = styled(Typography)({
  marginLeft: '10px',
  marginBottom: 0,
  fontSize: '0.875em',
  lineHeight: '20px',
}) as typeof Typography

export default ChatProgressItem
