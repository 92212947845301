import React, { useState } from 'react'
import { Element } from 'domhandler'
import { isValid, intlFormatDistance, parseISO } from 'date-fns'

import { getAttributesDecoder } from './utils'
import { useChatContext } from '@pages/ChatContext'
import { getLocalizedDateTimeWithTimezoneInfo } from '@utils/datetime'
import useInterval from '@hooks/useInterval'

export const CountdownTimerReplace = (element: Element) => {
  const datetime = element.attribs['data-datetime']
  const formatOptions = element.attribs['data-format-options']
  const version = element.attribs['data-version']

  if (!datetime || !isValid(new Date(datetime))) {
    return <UnknownValue />
  }

  const props = getValidatedParams({ formatOptions, version })

  return <CountdownTimer datetime={datetime} {...props} />
}

const UnknownValue = () => <span>N/A</span>

type IntlFormatDistanceOptions = Parameters<typeof intlFormatDistance>
interface Props {
  datetime: string
  formatOptions?: IntlFormatDistanceOptions[2]
  omitTimezoneInfo?: boolean
}

export const CountdownTimer = ({ datetime, formatOptions, omitTimezoneInfo }: Props) => {
  const chatContext = useChatContext()
  const [currentDate, setCurrentDate] = useState(() => new Date())

  useInterval(() => setCurrentDate(new Date()), 60_000)

  return (
    <b>
      {intlFormatDistance(parseISO(datetime), currentDate, {
        locale: chatContext?.locale,
        ...(formatOptions ?? {}),
      })}
      {!omitTimezoneInfo ? (
        <>
          {', '}
          {getLocalizedDateTimeWithTimezoneInfo(datetime)}
        </>
      ) : null}
    </b>
  )
}

const getValidatedParams = ({
  formatOptions,
  version,
}: {
  formatOptions: string
  version: string | null
}) => {
  const decodeAttributes = getAttributesDecoder(version)

  let formatOptionsProp = undefined
  if (formatOptions) {
    try {
      formatOptionsProp = JSON.parse(decodeAttributes(formatOptions)) as Props['formatOptions']
    } catch (e) {
      console.warn(e)
    }
  }

  return { formatOptions: formatOptionsProp }
}
