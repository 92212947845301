import React, { useCallback, useEffect, useRef } from 'react'
import { Provider, useStore } from 'react-redux'
import ReactDOM from 'react-dom'
import { getUiVariablesFromText } from '../sectionParsing'
import { Input } from '../Input'
import { SectionProps } from './Section'
import useMutationObserver from '@hooks/useMutationObserver'
import { useIsReadOnlyChatSession } from '@store/selectors'
import { HTML } from '@components/HTML'

const CustomLayoutSection = ({
  section,
  sectionKey,
  inputs,
  values,
  setValue,
  firstSubmitDone,
  errorVariables,
}: SectionProps): JSX.Element => {
  const store = useStore()
  const isReadOnly = useIsReadOnlyChatSession()
  let sectionHtml = section.text
  const sectionVariables = getUiVariablesFromText(section.text)
  const inputComponents: Record<string, React.ReactElement> = {}

  sectionVariables.forEach((variable) => {
    const hasError = firstSubmitDone && errorVariables.includes(variable)

    const inputId = `chat-multi-input-custom-wrapper-${sectionKey}-${variable}`
    // single quotes are used inside the span to not break double-quoted sectionHtml
    sectionHtml = sectionHtml.replace(`{${variable}.ui}`, `<span id='${inputId}'></span>`)
    inputComponents[inputId] = (
      <Provider store={store}>
        <Input
          key={inputId}
          variableKey={variable}
          inputProps={inputs[variable]}
          value={values[variable]}
          setValue={setValue}
          error={hasError}
          isReadOnly={isReadOnly}
        />
      </Provider>
    )
  })

  return <CustomLayoutWrapper html={sectionHtml} inputComponents={inputComponents} />
}

interface CustomLayoutWrapperProps {
  html: string
  inputComponents: Record<string, React.ReactElement>
}
const CustomLayoutWrapper = ({ html, inputComponents }: CustomLayoutWrapperProps): JSX.Element => {
  const ref = useRef(null)

  const injectInputs = useCallback(() => {
    Object.keys(inputComponents).forEach((inputId) => {
      const inputParent = document.getElementById(inputId)
      if (inputParent) {
        ReactDOM.render(inputComponents[inputId], inputParent)
      }
    })
  }, [inputComponents])

  useMutationObserver(ref, injectInputs, {
    childList: true,
    subtree: true,
  })

  useEffect(() => {
    injectInputs()
  }, [injectInputs])

  return (
    <span ref={ref}>
      <HTML markup={html} />
    </span>
  )
}

export default CustomLayoutSection
