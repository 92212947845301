import React, { PropsWithChildren } from 'react'
import { Redirect } from 'react-router-dom'
import { Stack, TextField, Typography } from '@mui/material'
import { useContactConfirmationContext } from '@pages/ContactConfirmation/ContactConfirmationPage'
import { PageTitle } from '@components/styled'
import useTranslations from '../../localisation/useTranslations'

export const ResultPage = () => {
  const localise = useTranslations()
  const { contactConfirmation, supplierName } = useContactConfirmationContext()
  const status = contactConfirmation.status

  const getTitle = () => {
    if (status === 'CLOSED') {
      return localise('contactConfirmationLinkExpired')
    } else if (
      status === 'SUBMITTED_CONFIRMATION' ||
      status === 'SUBMITTED_NEW_CONTACTS' ||
      status === 'SUBMITTED_REJECTION'
    ) {
      return localise('contactConfirmationConfirmed')
    }
  }

  const getDescription = () => {
    if (status === 'SUBMITTED_CONFIRMATION' || status === 'SUBMITTED_NEW_CONTACTS') {
      return (
        <>
          <DescriptionText>{localise('contactConfirmationNextSteps')}</DescriptionText>
          <Stack component='form' noValidate gap={2} mt={3}>
            <TextField
              id='contactName'
              name='contactName'
              label={localise('firstAndLastName')}
              value={contactConfirmation.submittedContactName ?? ''}
              disabled
            />
            <TextField
              id='contactEmail'
              name='contactEmail'
              label={localise('email')}
              value={contactConfirmation.submittedContactEmail ?? ''}
              disabled
            />
            {contactConfirmation.submittedContactPhone ? (
              <TextField
                id='contactPhone'
                name='contactPhone'
                label={localise('phone')}
                value={contactConfirmation.submittedContactPhone}
                disabled
              />
            ) : null}
          </Stack>
          <DescriptionText>{localise('contactConfirmationContactUs')}</DescriptionText>
        </>
      )
    } else if (status === 'SUBMITTED_REJECTION') {
      return (
        <>
          <DescriptionText>
            {localise('contactConfirmationContactRemoved', { supplierName })}
          </DescriptionText>
          <DescriptionText>{localise('contactConfirmationContactUs')}</DescriptionText>
        </>
      )
    } else if (status === 'CLOSED') {
      return null
    }
  }

  if (status === 'OPEN') {
    return <Redirect to='.' />
  }

  return (
    <>
      <PageTitle component='h1'>{getTitle()}</PageTitle>
      {getDescription()}
    </>
  )
}

export const DescriptionText = ({ children }: PropsWithChildren<{}>) => (
  <Typography variant='body2' mt={3}>
    {children}
  </Typography>
)
