// This regex matches {variable.ui} from text and returns variable.ui as a match
export const getUiVariablesFromText = (text: string): string[] => {
  const uiRegex = /{([^{}]*\.ui)}/g
  let variables = []
  let uiMatch

  while ((uiMatch = uiRegex.exec(text)) !== null) {
    variables.push(uiMatch[1].split('.ui')[0])
  }

  return variables
}
