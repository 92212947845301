import React, { useEffect, useState } from 'react'
import isNil from 'lodash/isNil'
import { styled, Slider, FormControl, sliderClasses } from '@mui/material'
import { ChatDataTestId } from '@typedef/chatSteps'
import { reportError } from '@utils/errorReporting'
import { SliderInputProps } from '@typedef/chatSteps/MultiInputStep'

interface Props {
  inputProps: SliderInputProps
  variableKey: string
  value?: number | string | null
  setValue: (variable: string, value: string | number) => void
  error?: boolean
  isReadOnly: boolean
}

export const SliderInput = ({
  value,
  setValue,
  variableKey,
  isReadOnly,
  inputProps,
}: Props): JSX.Element => {
  const stepSize = inputProps.stepSize && inputProps.stepSize > 0 ? inputProps.stepSize : 1
  const labels = getSliderLabels(inputProps.min, inputProps.max, stepSize, inputProps.labels)
  const [touched, setTouched] = useState(false)
  const [intermediateValue, setIntermediateValue] = useState<Props['value']>(value)
  const initialValue = inputProps.min

  // when default isn't specified, value is still visible as selected to user (minimum), need to set it
  useEffect(() => {
    if (!inputProps.default && !touched) {
      setValue(variableKey, initialValue)
    }
  }, [touched, initialValue, variableKey, setValue, inputProps.default])

  const onChangeCommitted = (_e: unknown, value: number | number[]) => {
    setValue(variableKey, value as number)
  }

  const onChange = (_e: unknown, value: number | number[]) => {
    setTouched(true)
    setIntermediateValue(value as number)
  }

  const getSliderMarks = () => {
    if (labels) {
      return [
        { value: inputProps.min, label: labels[0] },
        { value: inputProps.max, label: labels[labels.length - 1] },
      ]
    }

    return [
      {
        value: inputProps.min,
        label: `${inputProps.min}${inputProps.showValuePostfix ?? ''}`,
      },
      {
        value: inputProps.max,
        label: `${inputProps.max}${inputProps.showValuePostfix ?? ''}`,
      },
    ]
  }

  const getMaxDigitChars = () => {
    return String(inputProps.max + (inputProps.stepSize ?? 0)).length
  }

  return (
    <StyledFormControl data-testid={ChatDataTestId.SLIDER_INPUT}>
      {inputProps.showValue && (
        <ValueContainer maxDigits={getMaxDigitChars()} data-testid={ChatDataTestId.SLIDER_VALUE}>
          {intermediateValue ?? value}
          {inputProps.showValuePostfix ?? ''}
        </ValueContainer>
      )}
      <StyledSlider
        value={isNil(intermediateValue) ? initialValue : Number(intermediateValue)}
        disabled={isReadOnly}
        min={inputProps.min}
        max={inputProps.max}
        marks={getSliderMarks()}
        onChange={onChange}
        onChangeCommitted={onChangeCommitted}
        step={stepSize}
        aria-label='Slider input label'
        {...(labels
          ? {
              valueLabelDisplay: 'auto',
              valueLabelFormat: (value) => {
                const valuesRange = [...Array(inputProps.max).keys()].map((i) => i + inputProps.min)
                return labels[valuesRange.indexOf(value)]
              },
            }
          : {})}
      />
    </StyledFormControl>
  )
}

// only show labels if amount of labels matches amount of values
const getSliderLabels = (
  min: number,
  max: number,
  stepSize: number,
  labels?: SliderInputProps['labels'],
): SliderInputProps['labels'] | null => {
  if (!labels || !labels.length) {
    return null
  }

  const amountOfValues = (max - min + stepSize) / stepSize
  if (labels.length !== amountOfValues) {
    reportError('Slider labels specified but do not match amount of values')
    return null
  }

  return labels
}

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  flexDirection: 'row',
  margin: theme.spacing(1.2, 0),
}))

const ValueContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'maxDigits',
})<{ maxDigits: number }>(({ theme, maxDigits }) => ({
  backgroundColor: 'var(--primary-color)',
  color: theme.palette.primary.contrastText,
  fontWeight: 'bold',
  borderRadius: 8,
  padding: '0 6px',
  display: 'flex',
  alignItems: 'center',
  margin: theme.spacing(0, 1),
  justifyContent: 'center',
  minWidth: `${maxDigits}em`,
}))

const StyledSlider = styled(Slider)(({ theme }) => ({
  minWidth: 100,
  margin: theme.spacing(0, 1),
  color: 'var(--primary-color)',

  [`& .${sliderClasses.mark}`]: {
    display: 'none',
  },
}))
