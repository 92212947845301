import React from 'react'
import { Typography } from '@mui/material'
import { Input } from '../Input'
import { SectionProps } from './Section'
import { useIsReadOnlyChatSession } from '@store/selectors'
import { HTML } from '@components/HTML'

const DefaultLayoutSection = ({
  section,
  inputs,
  values,
  setValue,
  firstSubmitDone,
  errorVariables,
}: SectionProps): JSX.Element => {
  const isReadOnly = useIsReadOnlyChatSession()
  const text = section.text
  // Split string by {} so we could extract all different parts of the string and inject proper UI elements
  const splitString = text.split(/(?:{)|(?:})/g)
  const phrase: (React.ReactElement | string)[] = []

  // Iterate over all the split strings
  splitString.forEach((string) => {
    // Figure out which type of variable it is
    const uiMatch = string.match(/(.+?)\.ui/)
    const labelMatch = string.match(/(.+?)\.selection.label/)
    const valueMatch = string.match(/(.+?)\.selection.value/)
    if (uiMatch && uiMatch.length) {
      // If it is {var.ui} type, extract variable name out of the string and add proper UI element to phrase array
      const variable = uiMatch[1]
      const hasError = firstSubmitDone && errorVariables.includes(variable)

      phrase.push(
        <Input
          key={uiMatch[0]}
          variableKey={variable}
          inputProps={inputs[variable]}
          value={values[variable]}
          setValue={setValue}
          error={hasError}
          isReadOnly={isReadOnly}
        />,
      )
    } else if (labelMatch && labelMatch.length) {
      // If it is {var.selection.label} type, extract variable name out of the string and add proper UI element to phrase array
      const variable = labelMatch[1]
      const input = inputs[variable]
      if (input.type === 'dropdown') {
        const prefix = input.optionPrefix
        const suffix = input.optionSuffix
        const currentValue = values[variable]

        const option = input.options.find((opt) => {
          if (typeof opt === 'object' && opt !== null) {
            return opt.value === currentValue
          } else {
            return opt === currentValue
          }
        })
        if (typeof option === 'object' && option !== null) {
          phrase.push(<TextWrapper key={labelMatch[0]} text={option.label} />)
        } else {
          phrase.push(
            <TextWrapper
              key={labelMatch[0]}
              text={`${prefix ? prefix : ''}${(values[variable] || '').toString()}${
                suffix ? suffix : ''
              }`}
            />,
          )
        }
      }
    } else if (valueMatch && valueMatch.length) {
      // If it is {var.selection.value} type, extract variable name out of the string and add proper UI element to phrase array
      const variable = valueMatch[1]
      const input = inputs[variable]
      if (input.type === 'dropdown') {
        const prefix = input.optionPrefix
        const suffix = input.optionSuffix
        phrase.push(
          <TextWrapper
            key={valueMatch[0]}
            text={`${prefix ? prefix : ''}${(values[variable] || '').toString()}${
              suffix ? suffix : ''
            }`}
          />,
        )
      }
    } else {
      phrase.push(
        <Typography variant='body2' component='span' key={string} sx={{ fontSize: '0.9375rem' }}>
          <HTML markup={string} />
        </Typography>,
      )
    }
  })

  return <DefaultLayoutWrapper>{phrase}</DefaultLayoutWrapper>
}

const TextWrapper = ({ text }: { text: string | undefined }) => (
  <Typography variant='body2' component='span'>
    {text}
  </Typography>
)

const DefaultLayoutWrapper = ({ children }: { children: React.ReactNode }) => (
  <Typography
    variant='body2'
    component='div'
    sx={{
      flexDirection: 'row',
      alignItems: 'center',
      display: 'flex',
      padding: (theme) => theme.spacing(0.5, 0),
      fontSize: '0.9375rem',
    }}
  >
    {children}
  </Typography>
)

export default DefaultLayoutSection
