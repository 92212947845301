import React from 'react'
import CustomLayoutSection from './CustomLayoutSection'
import DefaultLayoutSection from './DefaultLayoutSection'
import {
  MultiInputComponentInput,
  MultiInputComponentLayout,
  MultiInputComponentSection,
  MultiInputComponentValues,
} from '@typedef/chatSteps/MultiInputStep'

export interface SectionProps {
  section: MultiInputComponentSection
  sectionKey: number
  inputs: Record<string, MultiInputComponentInput>
  values: MultiInputComponentValues
  setValue: (variable: string, value: string | number) => void
  firstSubmitDone: boolean
  errorVariables: string[]
}

interface Props extends SectionProps {
  layout: MultiInputComponentLayout | null
}

const Section = (props: Props): JSX.Element => {
  if (props.layout === MultiInputComponentLayout.CUSTOM) {
    return <CustomLayoutSection {...props} />
  }

  return <DefaultLayoutSection {...props} />
}

export default Section
